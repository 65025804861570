<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Property
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                exact
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link exact :to="{ name: 'property' }" class="m-nav__link"
                ><span class="m-nav__link-text">Property</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">
                Properties table
                <small> List of all properties </small>
              </h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <!--begin: Search Form -->
          <div
            class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
          >
            <div class="row align-items-center">
              <!-- <div class="col-xl-8 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-4">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid"
                                                   v-model="search"
                                                   placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                <span><i class="la la-search"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              <div class="col-xl-8 order-2 order-xl-1">
                <div class="form-group m-form__group row align-items-center">
                  <div class="col-md-4">
                    <div class="m-input-icon m-input-icon--left">
                      <input
                        type="text"
                        class="form-control m-input m-input--solid"
                        v-model="searchKeyword"
                        @keyup="searchData"
                        placeholder="Search..."
                        id="generalSearch"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                <button
                  v-b-modal="'newPropertyModal'"
                  class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                >
                  <span>
                    <i class="la la-plus"></i>
                    <span>New Property</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertCreated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertCreated = false"
            ></button>
            New property created successfully
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertUpdated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertUpdated = false"
            ></button>
            Property updated successfully
          </div>
          <!--end: Search Form -->
          <div class="table-responsive">
          <table class="table table-inverse" v-if="properties.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th>Thumbnail</th>
                <th>Name</th>
                <th v-if="isSuperAdmin">Organization</th>
                <th>Status</th>
                <th>Order Position</th>
                <th>Open To Hunting</th>
                <!--                            <th>Users</th>-->
                <th style="width: 120px">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(property, index) in filteredProperties" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <property-preview
                    v-bind:gallery="JSON.parse(property.thumbnail)"
                  />
                </td>
                <td>{{ property.name }}</td>
                <td v-if="isSuperAdmin" v-html="getOrganizationNames(property.user)" style="word-break: break-word;display: block;"></td>
                <td>
                  <span
                    class="m-badge m-badge--wide"
                    :class="[
                      property.active ? 'm-badge--success' : 'm-badge--danger',
                    ]"
                    >{{ property.active ? "Active" : "Inactive" }}
                  </span>
                </td>
                <td>{{ property.or }}</td>

                <td>
                  <span
                    class="m-badge m-badge--wide"
                    v-bind:class="[
                      property.open_hunt
                        ? 'm-badge--success'
                        : 'm-badge--danger',
                    ]"
                    >{{ property.open_hunt ? "Yes" : "No" }}
                  </span>
                </td>
                <!--                                <td>    -->
                <!--                             <router-link :to="{ name: 'userProperty', params: { property: property.id }}">-->
                <!--                                        List Users-->
                <!--                                    </router-link>-->
                <!--                                </td>-->

                <td>
                  <router-link
                    :to="{ name: 'listArea', params: { id: property.id } }"
                  >
                    <i class="la la-map"></i>
                  </router-link>

                  <button
                    title="Edit details"
                    @click="updateProperty(property.id)"
                    class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                  >
                    <!--@click="updateProperty(property.id)"-->
                    <i class="la la-edit"></i>
                  </button>
                  <button
                    title="Delete"
                    @click="deleteProperty(property.id, property.name)"
                    class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                  >
                    <i class="la la-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <b-pagination
            v-if="total > 0 && total > pageSize"
            align="right"
            size="md"
            :total-rows="total"
            v-model="page"
            :per-page="pageSize"
          >
          </b-pagination>
        </div>
      </div>
      <new-property
        @clear-id="clearId"
        @show-create-success-alert="showCreateSuccessAlert"
        @show-update-success-alert="showUpdateSuccessAlert"
        @clicked-reload="getList"
        v-bind:propertyId="propertyId"
      />
    </div>
  </div>
</template>

<script>
import propertyApi from "../../api/property";
import NewProperty from "./NewProperty";
import PropertyPreview from "./PropertyPreview";
import auth from "../../authentication";
import OrganizationRoleMixin from "../../mixins/OrganizationRoleMixin";

export default {
  mixins: [OrganizationRoleMixin],
  components: {
    PropertyPreview,
    NewProperty,
  },
  name: "property",
  data() {
    return {
      error: false,
      alertCreated: false,
      alertUpdated: false,
      search: "",
      message: "",
      searchKeyword: "",
      page: 1,
      total: 0,
      pageSize: 20,
      propertyId: null,
      properties: [],
    };
  },
  watch: {
    page(val) {
      this.getList();
    },
    pageSize(val) {
      this.getList();
    },
  },
  computed: {
    filteredProperties: function () {
      return this.properties.filter((property) => {
        if (
          property.name.match(this.search) ||
          property.description.match(this.search)
        ) {
          return property;
        }
      });
    },
  },
  beforeMount() {
    this.getList();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getList();
    },
    updateProperty(id) {
      this.propertyId = id;

      this.$bvModal.show("newPropertyModal");
      //this.$modal.show('newPropertyModal');
    },
    getList() {
      var data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.searchKeyword,
      };
      if (auth.user.profile.role == 5) {
        data.user_id = auth.user.profile.id;
      }

      propertyApi.getProperty(this, data, (context) => {});
    },
    updatePageSize(val) {
      this.pageSize = parseInt(val);
    },
    clearId() {
      this.propertyId = null;
      this.getList();
    },
    showCreateSuccessAlert() {
      this.alertCreated = true;
      this.alertUpdated = false;
    },
    showUpdateSuccessAlert() {
      this.alertCreated = false;
      this.alertUpdated = true;
    },
    deleteProperty(id, name) {
      let approve = confirm(
        `Do you want to remove property: ${name} and its related (trail, trail event, trail report) ?`
      );

      if (approve !== false) {
        propertyApi.removeProperty(this, id, (context) => {
          this.getList();
          this.alertCreated = false;
          this.alertUpdated = false;
        });
      }
    },
  },
};
</script>

<style scoped></style>
