export default {
    pageSize: {
        3: 3,
        6: 6,
        9: 9,
        12: 12,
        18: 18,
        24: 24,
    },
    Priority: {
        3: "High",
        2: "Medium",
        1: "Low",
        0: "None",
    },
    imageDropzoneUrl: 'https://httpbin.org/post',
}