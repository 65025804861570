<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newUserModal" v-model="showModal"
             :title="userId ? 'Update Organization' : 'Add new organization'"
             @hidden="close">
        <b-container>

            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter first name </span>
                            </div>
                        </div>
                        <!-- <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Last Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="surname" class="form-control m-input">
                                <span class="m-form__help"> Please enter last name </span>
                            </div>
                        </div> -->

                        <div class="form-group m-form__group row" v-if="!userId">
                            <label class="col-lg-2 col-form-label">
                                Email Login
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="email" class="form-control m-input">
                                <span class="m-form__help"> Please enter email login</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row" v-if="!userId">
                            <label class="col-lg-2 col-form-label">
                                Password
                            </label>
                            <div class="col-lg-9">
                                <input type="password" required="required" v-model="password" class="form-control m-input">
                                <span class="m-form__help"> Please enter password </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Email
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="emailDisplay" class="form-control m-input">
                                <span class="m-form__help"> Please enter email</span>
                            </div>
                        </div>
                        
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Phone Number
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="phone_number" class="form-control m-input"
                                v-mask="'###-###-####'">
                                <span class="m-form__help"> Please enter phone number </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                ZIP Code
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="zipcode" class="form-control m-input">
                                <span class="m-form__help"> Please enter ZIP Code </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Working time
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="workingTime" class="form-control m-input">
                                <span class="m-form__help"> Please enter working time </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Address
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="address"
                                          placeholder="..." maxlength="80"></textarea>
                                <span class="m-form__help">Please enter owner address</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Twitter URL
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="twitterUrl" class="form-control m-input">
                                <span class="m-form__help"> Please enter twitter URL</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Facebook URL
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="facebookUrl" class="form-control m-input">
                                <span class="m-form__help"> Please enter facebook URL</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Instagram URL
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="instagramUrl" class="form-control m-input">
                                <span class="m-form__help"> Please enter instagram URL </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Youtube URL
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="youtubeUrl" class="form-control m-input">
                                <span class="m-form__help"> Please enter youtube URL</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Membership URL
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="membershipUrl" class="form-control m-input">
                                <span class="m-form__help"> Please enter membership url</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Become Member Button
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="becomeButton" class="form-control m-input">
                                <span class="m-form__help"> Please enter become member button</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Color
                            </label>
                            <div class="col-lg-9">
                                <sketch-picker type="text" title="color-picker" v-model="color_picker"
                                               class="form-control m-input"></sketch-picker>
                                <span class="m-form__help"> Please enter Color (HEX Format) </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter owner description</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Logo
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="logo" class="m-dropzone m-dropzone--primary"
                                              ref="organizationDropzone"
                                              id="organizationDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-removed-file="afterDropzoneRemove"
                                              @vdropzone-error="dropzoneError">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>

                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyEvent">{{ userId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import userApi from '../../api/user';
    import vueDropzone from 'vue2-dropzone'
    import TrailSelect from '../trail/TrailSelect'
    import VueDatePicker from 'vuejs-datepicker';
    import Settings from '../../constant/settings';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { Sketch } from 'vue-color'
    import {mask} from 'vue-the-mask'

    export default {
        directives: {mask},
        components: {
            vueDropzone,
            TrailSelect,
            VueDatePicker,
            Loading,
            //vueDropzone,
            'sketch-picker': Sketch
        },
        name: "organization-modal",
        props: ['userId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.userId,
                name: null,
                email: null,
                password: null,
                zipcode: null,
                phone_number: null,
                created_at: null,
                role: 5,
                color_picker: '#FFF',
                logo: null,
                description: null,
                workingTime: null,
                address: null,
                twitterUrl: null,
                facebookUrl: null,
                instagramUrl: null,
                youtubeUrl: null,
                emailDisplay: null,
                membershipUrl: null,
                becomeButton: null, 

                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                }
            }
        },

        watch: {
            userId(val) {
                if (val) {
                    userApi.getUserDetail(this, val, (context) => {
                         this.$refs.organizationDropzone.removeAllFiles();
                        if (context.event) {
                            this.error = false;
                            this.email = context.event.email;
                            this.name = context.event.name;
                            this.zipcode = context.event.zipcode;
                            this.phone_number = context.event.phone_number;
                            this.created_at = context.event.created_at;
                            this.color_picker = context.event.color_picker ? context.event.color_picker : '#FFF';
                            this.description = context.event.description;
                            this.workingTime = context.event.working_time;
                            this.address = context.event.address;
                            this.twitterUrl = context.event.twitter_url;
                            this.facebookUrl = context.event.facebook_url;
                            this.instagramUrl = context.event.instagram_url;
                            this.youtubeUrl = context.event.youtube_url;
                            this.emailDisplay = context.event.email_display;
                            this.membershipUrl = context.event.membership_url;
                            this.becomeButton = context.event.become_button;
                           
                            if (context.event.logo) {
                                let logoInfo = JSON.parse(context.event.logo);
                                let url = apiUrl + '/assets' + logoInfo.path;
                                console.log(url);
                                this.$refs.organizationDropzone.manuallyAddFile(logoInfo, url);
                            }
                            this.logo = context.event.logo;
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyEvent() {
                this.isLoading = true;
                this.error = false;
                let data = {
                    name: this.name,
                    email: this.email,
                    zipcode: this.zipcode,
                    phone_number: this.phone_number,
                    color_picker: this.color_picker.hex,
                    description: this.description,
                    logo: this.logo,
                    address: this.address,
                    working_time: this.workingTime,
                    twitter_url: this.twitterUrl,
                    facebook_url: this.facebookUrl,
                    instagram_url: this.instagramUrl,
                    youtube_url: this.youtubeUrl,
                    email_display: this.emailDisplay,
                    membership_url: this.membershipUrl,
                    become_button: this.becomeButton,
                };

                if (this.userId) {
                    userApi.updateUser(this, this.userId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    data = {
                        ...data,
                        email: this.email,
                        password: this.password,
                        role: this.role,
                        created_at: new Date()
                    };
                    userApi.createUser(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.message = null;
                this.id = null;
                this.name = null;
                this.zipcode = null;
                this.phone_number = null;
                this.created_at = null;
                this.email = null;
                this.password = null;
                this.color_picker = '#FFF';
                this.description = null;
                this.logo = null;
                this.address = null;
                this.workingTime = null;
                this.twitterUrl = null;
                this.facebookUrl = null;
                this.instagramUrl = null;
                this.youtubeUrl = null;
                this.emailDisplay =  null;
                this.membershipUrl = null;
                this.becomeButton = null;
                // this.$refs.organizationDropzone.removeAllFiles();
            },
            close() {
                this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },
            afterDropzoneComplete(file) {
                if (typeof file !== 'undefined') {
                    this.logo = file.accepted ? file.dataURL : this.logo;
                    if (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true) {
                        this.logo = null;
                    }
                } else {
                    this.logo = null;
                }
            },
            afterDropzoneRemove(file) {
                if (this.logo === file.dataURL || (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    this.logo = null;
                }
            },
            dropzoneError(file, message, xhr) {
                alert(message);
                this.$refs.organizationDropzone.removeFile(file)
            },
        }
    }
</script>

<style scoped>

</style>