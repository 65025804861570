import auth from '../authentication';
import Vue from '../main';

export default {
    //Category Section
    getCategory(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/category/get-all', data)
            .then(response => {
                context.error = false;
                context.categories = response.data.data;
                context.total = response.data.total;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getCategorySelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/category/get-select')
            .then(response => {
                context.error = false;
                context.categories = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createCategory(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/category/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.category = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    getCategoryDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/category/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.category = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateCategory(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/category/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.category = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeCategory(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/category/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
    hideCategory(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/category/hide/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
};