<template>
  <div>
    <div>
      <div class="row">
        <div class="col col-12 col-md-2">
          <select
            title="platform-select"
            class="form-control"
            v-model="search.type"
          >
            <option value="year">Year</option>
            <option value="month">Month</option>
            <option value="day">Day</option>
          </select>
        </div>
        <div class="col col-12 col-md-4 pt-3 pt-md-0">
          <date-range-picker ref="picker" v-model="search.dateRange">
          </date-range-picker>
        </div>
        <div class="col col-12 col-md-6 m--align-right pt-3 pt-md-0">
          <button
            class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
            @click="exportPdf"
          >
            Export PDF
          </button>
          <download-excel
            class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
            :name="formatExportName"
            :fetch="fetchDataExcel"
            :fields="jsonFields"
            type="csv"
          >
            Export Excel
          </download-excel>
        </div>
      </div>
    </div>
    <div id="trail-list-tracking-chart">
      <LineChart
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Line as LineChart } from "vue-chartjs/legacy";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Moment from "moment";
import TrailTracking from "../../api/trail_tracking";
import DownloadExcel from "vue-json-excel";
import { downloadBlobFile } from "../../service/download";
import html2canvas from "html2canvas";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: "LineChartTracking",
  components: {
    LineChart,
    DateRangePicker,
    DownloadExcel,
  },
  props: {
    chartId: {
      type: String,
      default: "trail-tracking-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        tension: 0.5,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
            offset: true
          },
          y: {
            stacked: false,
          },
        },
      },
      search: {
        dateRange: {
          startDate: Moment().subtract(1, "months").toDate(),
          endDate: Moment().toDate(),
        },
        type: "day",
      },
      dataChart: {
        dataLabels: [],
        datasets: {
          list: [],
          map: [],
        },
      },
      jsonFields: {
        Date: "date",
        Map: "map",
        List: "list",
      },
    };
  },
  watch: {
    search: {
      handler(newValue, oldValue) {
        this.getDataChart();
      },
      deep: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.dataChart.dataLabels,
        datasets: [
          {
            label: "List",
            backgroundColor: "#117A65",
            borderColor: "#117A65",
            data: this.dataChart.datasets.list,
          },
          {
            label: "Map",
            backgroundColor: "#2ECC71",
            borderColor: "#2ECC71",
            data: this.dataChart.datasets.map,
          },
        ],
      };
    },
    formatExportName() {
      let start = Moment(this.search.dateRange.startDate).format("YYYY-MM-DD");
      let end = Moment(this.search.dateRange.endDate).format("YYYY-MM-DD");
      return `trail_tracking_${this.search.type}_${start}_${end}.xls`;
    },
  },
  created() {
    this.getDataChart();
  },
  methods: {
    getDataChart() {
      TrailTracking.getDashboardChart(
        this,
        {
          type: this.search.type,
          from: this.search.dateRange.startDate,
          to: this.search.dateRange.endDate,
        },
        (context) => {
          console.log(this.dataChart);
        }
      );
    },
    fetchDataExcel() {
      let excelData = [];
      this.dataChart.datasets.list.map((_item, index) => {
        let list = this.dataChart.datasets.list[index];
        let map = this.dataChart.datasets.map[index];
        excelData.push({
          date: this.dataChart.dataLabels[index],
          list: list,
          map: map,
        });
      });
      return excelData;
    },
    async exportPdf() {
      let self = this;
      html2canvas(document.getElementById("trail-list-tracking-chart")).then(
        function (canvas) {
          let image = canvas.toDataURL("image/png");
          TrailTracking.getExportPdf({
            type: self.search.type,
            from: self.search.dateRange.startDate,
            to: self.search.dateRange.endDate,
            image,
          }).then((response) => {
            const fileName = `trail-list-tracking-${Date.now()}.pdf`;
            downloadBlobFile(response, fileName);
          });
        }
      );
    },
  },
};
</script>

<style>
.vue-daterange-picker {
  width: 100%;
}
.vue-daterange-picker .reportrange-text {
  padding: 9px 10px;
}
</style>
