<template>
    <!-- page content -->
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title ">
                        Welcome to Nantucket Admin
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <!-- /page content -->
</template>

<script>
    export default {
        name: "home"
    }
</script>