import Vue from '../main';

export default {
    // Chart Dashboard
    getDashboardChart(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user-reports/chart', data)
            .then(response => {
                context.error = false;
                context.dataChart = response.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getExportPdf(data) {
        return Vue.http.post(Vue.apiUrl + '/user-reports/export-pdf', data, { responseType: 'blob' });
    },
}