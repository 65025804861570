import { render, staticRenderFns } from "./DifficultySelect.vue?vue&type=template&id=3f017832&scoped=true&"
import script from "./DifficultySelect.vue?vue&type=script&lang=js&"
export * from "./DifficultySelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f017832",
  null
  
)

export default component.exports