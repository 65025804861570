<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newImageModal" v-model="showModal"
             :title="imageId ? 'Update image' : 'Add new image'"
             @hidden="close">
        <b-container>
            <!--begin::Form-->
            <div id="map"></div>
            <form class="m-form m-form--label-align-right mt-2">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--image alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__image">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..." maxlength="250"></textarea>
                                <span class="m-form__help">Please enter Description (max 250 characters)</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Lat
                            </label>
                            <div class="col-lg-9">
                                <input id="image-lat" type="number" step="any" class="form-control m-input"
                                       v-model="lat"/>
                                <span class="m-form__help">Please enter lat</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Long
                            </label>
                            <div class="col-lg-9">
                                <input id="image-long" type="number" step="any" class="form-control m-input"
                                       v-model="long"/>
                                <span class="m-form__help">Please enter long</span>
                            </div>
                        </div>

                        <!--                        <property-select-->
                        <!--                                @selectProperty="updateProperty"-->
                        <!--                                v-bind:selectedProperty="currentProperty"-->
                        <!--                        />-->

                        <!--                        <trail-select-->
                        <!--                                @selectTrail="updateTrail"-->
                        <!--                                v-bind:selectedTrail="currentTrail"-->
                        <!--                        />-->
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Property
                            </label>
                            <div class="col-lg-9">
                                <select title="property-select" class="form-control" v-model="currentProperty"
                                        @change="updateProperty()"
                                        v-if="properties.length > 0">
                                    <option value="">Choose property</option>
                                    <option v-for="property in properties" :value="property.id">
                                        {{ property.name }}
                                    </option>
                                </select>
                                <span class="m-form__help">Please choose property</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Trail
                            </label>
                            <div class="col-lg-9">
                                <select title="trail-select" class="form-control" v-model="currentTrail"
                                        v-if="trails.length > 0">
                                    <option :class="{ 'hide' : trail.property_id != currentProperty}"
                                            v-for="trail in trails" :value="trail.id"
                                            :selected="{ fal : trail.property_id != currentProperty}"
                                    >
                                        {{ trail.name }}
                                    </option>
                                </select>
                                <span class="m-form__help">Please choose trail</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Is Active
                            </label>
                            <div class="col-lg-9">
                            <span class="m-switch m-switch&#45;&#45;outline m-switch&#45;&#45;icon m-switch&#45;&#45;brand">
                                <label>
                                    <input type="checkbox" v-model="isActive"
                                           :value="isActive">
                                    <span></span>
                                </label>
                            </span>
                            </div>
                        </div>


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Image Preview
                            </label>
                            <div class="col-lg-9">
                                <img :src="imagePre" :class="{ full: fullWidthImage, 'modal-img':true }"
                                     @click="fullWidthImage = !fullWidthImage" alt=""/>


                            </div>
                        </div>


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Image (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="image" class="m-dropzone m-dropzone--primary"
                                              ref="imageGalleryDropzone"
                                              id="imageGalleryDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-removed-file="afterDropzoneRemove"
                                              @vdropzone-error="dropzoneError">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>

                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyImage">{{ imageId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import ImageApi from '../../api/trail_image';
    import vueDropzone from 'vue2-dropzone'
    import Settings from '../../constant/settings';
    import TrailSelect from '../trail/TrailSelect';
    import FileHelper from '../../service/file';
    import PropertyPreview from '../property/PropertyPreview';
    import PropertySelect from "../property/PropertySelect";
    import ImagePreview from "./ImagePreview";
    import TrailApi from '../../api/trail';
    import PropertyApi from '../../api/property';

    export default {
        components: {
            PropertySelect,
            TrailSelect,
            vueDropzone,
            PropertyPreview,
            ImagePreview,
            TrailApi,
            PropertyApi
        },
        name: "image-modal",
        props: ['imageId'],
        data() {
            return {
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.imageId,
                description: null,
                information: null,
                lat: null,
                long: null,
                order: null,
                trailId: null,
                currentTrail: null,
                propertyId: null,
                oldPropertyId: null,
                currentProperty: null,
                gallery: [],
                updateGallery: [],
                image: null,
                previewIcon: null,
                fileHelper: FileHelper,
                notInRoute: false,
                isPOI: false,
                isActive: false,
                fullWidthImage: false,
                imagePre: null,
                trails: [],
                properties: [],
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                }
            }
        },
        watch: {
            currentProperty(val) {
                // if(this.currentProperty){
                //     this.currentTrail = '';
                // }
                this.$emit('selectProperty', val);
            },
            selectedProperty(val) {
                this.currentProperty = val;
                this.$emit('selectProperty', val);
            },
            selectedTrail(val) {
                if (val) {
                    this.currentTrail = val;
                    this.$emit('selectedTrail', val);
                }
            },
            currentTrail(val) {
                this.$emit('selectTrail', val);
            },


            imageId(val) {
                PropertyApi.getPropertySelect(this, (context) => {
                })
                TrailApi.getTrailSelect(this, (context) => {
                })

                console.log(val);
                if (val) {
                    ImageApi.getImageDetail(this, val, (context) => {
                        console.log(context);
                        if (context.image) {

                            mapboxgl.accessToken = 'pk.eyJ1IjoibWluaGRvODkiLCJhIjoiY2pvdmNpb3kwMWxraTNwcGludDk3amYycCJ9.SIaD_il5TcBB8YA9-vFV0Q';
                            var map = new mapboxgl.Map({
                                container: 'map',
                                style: 'mapbox://styles/mapbox/streets-v11',
                                center: [0, 0],
                                zoom: 15
                            });


                            var args = [];
                            var coords = [];
                            var coordinates = "coordinates=";

                            var point = [context.image.long, context.image.lat];

                            args.push(point);

                            map.flyTo({
                                center: args[0]
                            });

                            map.on('load', function () {

                                var marker = new mapboxgl.Marker({color: 'red'})
                                    .setLngLat(args[0])
                                    .addTo(map);

                                map.on('click', function (e) {


                                    document.getElementById("image-lat").value = e.lngLat.lat;
                                    document.getElementById("image-long").value = e.lngLat.lng;

                                    this.lat = e.lngLat.lat;
                                    this.long = e.lngLat.lng;


                                    marker.remove();
                                    marker = new mapboxgl.Marker({color: 'red'})
                                        .setLngLat(e.lngLat)
                                        .addTo(map);

                                    return false;

                                });


                            });
                            this.propertyId = context.image.property_id;
                            this.oldPropertyId = context.image.property_id;
                            this.currentProperty = context.image.property_id;
                            this.description = context.image.description;

                            this.lat = context.image.lat;
                            this.long = context.image.long;

                            this.trailId = context.image.trail_id;
                            this.currentTrail = context.image.trail_id;



                            this.isActive = !!context.image.active;

                            if (context.image.image) {
                                let thumbnailInfo = JSON.parse(context.image.image);
                                let url = apiUrl + '/assets' + thumbnailInfo.path;

                                this.imagePre = url;


                                console.log(thumbnailInfo);
                                console.log(url);
                                this.$refs.imageGalleryDropzone.manuallyAddFile(thumbnailInfo, url);
                            }
                            this.thumbnail = context.property.thumbnail;


                        }
                    })
                }
            },
        },
        methods: {
            modifyImage() {
                this.error = false;
                let status = this.isActive ? 1 : 0;
                let data = {
                    description: this.description,

                    lat: document.getElementById("image-lat").value,
                    long: document.getElementById("image-long").value,

                    trail_id: this.currentTrail,
                    property_id: this.currentProperty,
                    active: status,


                };

                if (this.imageId) {
                    ImageApi.updateImage(this, this.imageId, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    ImageApi.createImage(this, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.id = null;
                this.name = null;
                this.gallery = [];
                this.lat = null;
                this.long = null;
                this.order = null;
                this.trailId = null;
                this.currentTrail = null;
                this.propertyId = null;
                this.currentProperty = null;
                this.information = null;
                this.image = null;
                this.isActive = null;
                this.imagePre = null;

                this.$refs.imageGalleryDropzone.removeAllFiles();
            },
            close() {
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            afterDropzoneComplete(file) {
                if (this.gallery) {
                    this.gallery.push(file.dataURL);
                } else {

                    if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {

                    } else {
                        if (typeof file !== 'undefined') {
                            this.gallery = [];
                            this.gallery.push(file.dataURL);
                        }
                    }
                }
            },
            updateTrail(id) {
                this.trailId = id;
            },
            updateProperty() {
                if(this.propertyId != this.currentProperty){
                    this.currentTrail = '';
                    this.propertyId = this.currentProperty;
                }


            },

            handleFile(id) {
                let file = document.querySelector('#' + id);
                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'image') {
                                this.image = context.currentFile;

                            } else {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        }
                    });
                } else {
                    this.currentFile = null;
                }
            },
            afterDropzoneComplete(file) {
                if (typeof file !== 'undefined') {
                    this.thumbnail = file.dataURL;
                    if (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true) {
                        this.thumbnail = null;
                    }
                } else {
                    this.thumbnail = null;
                }
            },
            afterDropzoneRemove(file) {
                if (this.thumbnail === file.dataURL || (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    this.thumbnail = null;
                }
            },
            dropzoneError(file, message, xhr) {
                alert(message);
                this.$refs.imageGalleryDropzone.removeFile(file)
            },
        }
    }
</script>