<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Property
        </label>
        <div class="col-lg-9">
            <select title="property-select" class="form-control" v-model="currentProperty" v-if="properties.length > 0">
                <option value="">Choose property</option>
                <option v-for="property in properties" :value="property.id">
                    {{ property.name }}
                </option>
            </select>
            <span class="m-form__help">Please choose property</span>
        </div>
    </div>
</template>

<script>
    import propertyApi from '../../api/property';

    export default {
        name: "property-select",
        data() {
            return {
                currentProperty: null,
                page: 0,
                properties: []
            }
        },
        props: ['selectedProperty'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedProperty(val) {
                console.log(val);
                this.currentProperty = val;
                this.$emit('selectProperty', val);
            },
            currentProperty(val) {
                this.$emit('selectProperty', val);
            }
        },
        methods: {
            getList() {
                propertyApi.getPropertySelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>