import Vue from '../main';

export default {
    getSearchFilters(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/search-filter/get-all', data)
        .then(response => {
            context.error = false;
            context.searchFilters = response.data.data;
            context.total = response.data.total;
            context.message = response.data.message;
            callback(context);
        })
        .catch(response => {
            context.error = true;
            context.message = response.data.message;
            context.total = 0;
            callback(context);
        })
    },
    getKeywordExport(data) {
        return Vue.http.post(Vue.apiUrl + '/search-filter/export', data);
    },
    getExportPdf(data) {
        return Vue.http.post(Vue.apiUrl + '/search-filter/export-pdf', data, { responseType: 'blob' });
    },
}