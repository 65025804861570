import auth from '../authentication';
import Vue from '../main';

export default {
    //Type Section
    getDonationSetting(context, data, callback) {
        Vue.http.get(Vue.apiUrl + '/donation-setting/get-all', data)
            .then(response => {
                context.error = false;
                context.settings = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                // context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },

    updateDonationSetting(context, code, data, callback) {
        Vue.http.post(Vue.apiUrl + '/donation-setting/update-detail/' + code, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.setting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },

    getPoiImageInfo(context, data, callback) {
        Vue.http.get(Vue.apiUrl + '/location/get-images', data)
            .then(response => {
                context.error = false;
                context.settings = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                // context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },

    getAboutUsSetting(context, callback) {
        Vue.http.get(Vue.apiUrl + '/about-us/get')
            .then(response => {
                context.error = false;
                context.setting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.total = 0;
                callback(context);
            })
    },

    updateAboutUsSetting(context, data, callback) {
        console.log(data);
        Vue.http.post(Vue.apiUrl + '/about-us/update-detail', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.setting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },

};