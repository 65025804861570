<template>
  <!-- BEGIN: Left Aside -->
  <!--<button class="m-aside-left-close  m-aside-left-close&#45;&#45;skin-dark " id="m_aside_left_close_btn">-->
  <!--<i class="la la-close"></i>-->
  <!--</button>-->
  <div
    id="m_aside_left"
    class="m-grid__item	m-aside-left  m-aside-left--skin-dark "
    :class="{ 'm-aside-left--on': activeToggle }"
  >
    <!-- BEGIN: Aside Menu -->
    <div
      id="m_ver_menu"
      class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
    >
      <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
        <!--<li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'home' }">
                    <router-link class="m-menu__link" :to="{ name : 'home' }">
                        <i class="m-menu__link-icon flaticon-line-graph"></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text">Dashboard </span>
                            </span>
                        </span>
                    </router-link>
                </li>-->
        <li class="m-menu__section">
          <h4 class="m-menu__section-text">Manages</h4>
        </li>

        <template v-if="isSuperAdmin ||  isSuperAuth">
          <li class="m-menu__item analytic-trails">
            <a href="http://analytic.acktrails.com/" target="_blank">
              <i class="m-menu__link-icon flaticon-map"></i>
              <span>Trail & Property Statistic</span>
            </a>
          </li>
        </template>

        
        <li class="m-menu__item analytic-trails" v-if="isOrganization && !isSuperAuth">
          <a
            v-bind:href="url"
            target="_blank"
          >
            <i class="m-menu__link-icon flaticon-map"></i>
            <span>Trail & Property Statistic</span>
          </a>
        </li>
       

        <template v-if="isSuperAdmin || isOrganization">
          <!-- <li class="m-menu__item " :class="{ 'm-menu__item--active': currentRouteName === 'owner' }">
                    <router-link class="m-menu__link" exact :to="{ name : 'owner' }">

                        <i class="m-menu__link-icon flaticon-map"></i>
                        <span class="m-menu__link-text">Owner</span>
                    </router-link>
                </li> -->
          <li class="m-menu__item " :class="{ 'm-menu__item--active': currentRouteName === 'dashboard' }">
            <router-link class="m-menu__link" exact :to="{ name : 'dashboard' }">

              <i class="m-menu__link-icon flaticon-map"></i>
              <span class="m-menu__link-text">Dashboard</span>
            </router-link>
          </li>
          <li class="m-menu__item " :class="{ 'm-menu__item--active': currentRouteName === 'property' }">
              <router-link class="m-menu__link" exact :to="{ name : 'property' }">

                  <i class="m-menu__link-icon flaticon-map"></i>
                  <span class="m-menu__link-text">Property</span>
              </router-link>
          </li>
          <li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'trail' }">
              <router-link class="m-menu__link" exact :to="{ name: 'trail' }">
                  <i class="m-menu__link-icon flaticon-route"></i>
                  <span class="m-menu__link-text">Trail</span>
              </router-link>
          </li>
          <li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'park' }">
              <router-link class="m-menu__link" exact :to="{ name: 'park' }">
                  <i class="m-menu__link-icon flaticon-route"></i>
                  <span class="m-menu__link-text">Park</span>
              </router-link>
          </li>
          <li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'image' }">
              <router-link class="m-menu__link" exact :to="{ name: 'image' }">
                  <i class="m-menu__link-icon flaticon-route"></i>
                  <span class="m-menu__link-text">Images</span>
              </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'imageTop' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'imageTop' }">
              <i class="m-menu__link-icon flaticon-route"></i>
              <span class="m-menu__link-text">Liked Images</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'event' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'event' }">
              <i class="m-menu__link-icon flaticon-time-3"></i>
              <span class="m-menu__link-text">Event</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'bus' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'bus' }">
              <i class="m-menu__link-icon flaticon-time-3"></i>
              <span class="m-menu__link-text">Bus</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'information',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'information' }"
            >
              <i class="m-menu__link-icon flaticon-information"></i>
              <span class="m-menu__link-text">Information</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'user' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'user' }">
              <i class="m-menu__link-icon flaticon-user"></i>
              <span class="m-menu__link-text">User</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'user_deleted' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'user_deleted' }">
              <i class="m-menu__link-icon flaticon-delete"></i>
              <span class="m-menu__link-text">Past users</span>
            </router-link>
          </li>
        </template>

        <template v-if="isSuperAdmin">
          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'organization',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'organization' }"
            >
              <i class="m-menu__link-icon flaticon-user"></i>
              <span class="m-menu__link-text">Organization</span>
            </router-link>
          </li>
        </template>
        
        <template v-if="isSuperAdmin || isOrganization">
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'voting' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'voting' }">
              <i class="m-menu__link-icon flaticon-list-1"></i>
              <span class="m-menu__link-text">Voting</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'noti' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'noti' }">
              <i class="m-menu__link-icon flaticon-rocket"></i>
              <span class="m-menu__link-text">Notification</span>
            </router-link>
          </li>
        </template>

          <!-- <li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'notification' }">
                    <router-link class="m-menu__link" exact :to="{ name : 'notification' }">
                        <i class="m-menu__link-icon flaticon-rocket"></i>
                        <span class="m-menu__link-text">Notification Old Version</span>
                    </router-link>
                </li> -->
        <template v-if="isSuperAdmin">
          <li class="m-menu__section">
            <h4 class="m-menu__section-text">Users Submission</h4>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'feedback' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'feedback' }">
              <i class="m-menu__link-icon flaticon-folder-1"></i>
              <span class="m-menu__link-text">Feedback</span>
            </router-link>
          </li>
        </template>

        <template v-if="isSuperAdmin || isOrganization">
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'report' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'report' }">
              <i class="m-menu__link-icon flaticon-folder-2"></i>
              <span class="m-menu__link-text">Report</span>
            </router-link>
          </li>
        </template>

        <template v-if="isSuperAdmin">
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'donation' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'donation' }">
              <i class="m-menu__link-icon flaticon-coins"></i>
              <span class="m-menu__link-text">Donation History</span>
            </router-link>
          </li>
          <li class="m-menu__section">
            <h4 class="m-menu__section-text">Units Configuration</h4>
          </li>

          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'category' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'category' }">
              <i class="m-menu__link-icon flaticon-signs"></i>
              <span class="m-menu__link-text">Property Category</span>
            </router-link>
          </li>

          <!-- <li class="m-menu__item" :class="{ 'm-menu__item--active': currentRouteName === 'trail_category' }">
                    <router-link class="m-menu__link" exact :to="{ name : 'trail_category' }">
                        <i class="m-menu__link-icon flaticon-signs"></i>
                        <span class="m-menu__link-text">Trail Category</span>
                    </router-link>
                </li> -->

          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'activity' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'activity' }">
              <i class="m-menu__link-icon flaticon-network"></i>
              <span class="m-menu__link-text">Activity</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{ 'm-menu__item--active': currentRouteName === 'terrain' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'terrain' }">
              <i class="m-menu__link-icon flaticon-infinity"></i>
              <span class="m-menu__link-text">Terrain</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'difficulty',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'difficulty' }"
            >
              <i class="m-menu__link-icon flaticon-puzzle"></i>
              <span class="m-menu__link-text">Difficulty</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'report_type',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'report_type' }"
            >
              <i class="m-menu__link-icon flaticon-layers"></i>
              <span class="m-menu__link-text">Report Type</span>
            </router-link>
          </li>

           <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'reason_delete',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'reason_delete' }"
            >
              <i class="m-menu__link-icon flaticon-layers"></i>
              <span class="m-menu__link-text">Delete Reason</span>
            </router-link>
          </li>

          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'donation_reason',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'donation_reason' }"
            >
              <i class="m-menu__link-icon flaticon-list-1"></i>
              <span class="m-menu__link-text">Donation Reason</span>
            </router-link>
          </li>
          <li class="m-menu__section">
            <h4 class="m-menu__section-text">Static page content</h4>
          </li>
          <li
            class="m-menu__item "
            :class="{ 'm-menu__item--active': currentRouteName === 'about_us' }"
          >
            <router-link class="m-menu__link" exact :to="{ name: 'about_us' }">
              <i class="m-menu__link-icon flaticon-paper-plane"></i>
              <span class="m-menu__link-text">About Us Page</span>
            </router-link>
          </li>
          <li
            class="m-menu__item"
            :class="{
              'm-menu__item--active': currentRouteName === 'donation_setting',
            }"
          >
            <router-link
              class="m-menu__link"
              exact
              :to="{ name: 'donation_setting' }"
            >
              <i class="m-menu__link-icon flaticon-settings"></i>
              <span class="m-menu__link-text">Donation Setting</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <!-- END: Aside Menu -->
  </div>
  <!-- END: Left Aside -->
</template>

<script>
import Vue from "../../main";
import auth from '../../authentication';
export default {
  name: "nav-bar",
  data() {
    return {
      url: "",
      activeToggle: false,
    };
  },
  beforeMount() {
  
    this.url  = "http://analytic.acktrails.com/?owner_id=" + this.getUserId();
  },
 
  methods: {
    getUserId() {
      console.log(Vue.Auth);
      return Vue.Auth.id;
    },
  },

  computed: {
      
    
    currentRouteName() {
      return this.$route.name;
    },
    isSuperAuth() {
      return Vue.Auth.email == "administrator@nantucketlandbank.org" || Vue.Auth.email == 'acktrails@gmail.com';
    },
    isSuperAdmin() {
      return Vue.Auth.type == 1;
    },
    isOrganization() {
      return Vue.Auth.type == 5;
    },
  },
  
};
</script>

<style lang="scss" scoped>
.analytic-trails {
  margin-bottom: 10px !important;
  a {
    padding-left: 31px;
    color: #868aa8;
    span {
      padding-left: 10px;
    }
    &:hover {
      color: #868aa8 !important;
      text-decoration: none;
    }
  }
}
#m_aside_left {
  min-height: 100vh;
}
</style>
