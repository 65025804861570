import auth from '../authentication';
import Vue from '../main';

export default {
    
    getAndroidSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/version/get-android')
            .then(response => {
                context.error = false;
                context.versions = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getIosSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/version/get-ios')
            .then(response => {
                context.error = false;
                context.versions = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    
};