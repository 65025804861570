<template>

    <!--begin::Modal-->
    <b-modal size="lg" id="newParkModal" v-model="showModal"
            :title="parkId ? 'Update park' : 'Add new park'"
            @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right" autocomplete="off">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Title
                            </label>
                            <div class="col-lg-9">
                                <input type="text" title="park-title" required="required" v-model="form.title"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter park title </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="form.description"
                                          placeholder="..." maxlength="250"></textarea>
                                <span class="m-form__help">Please enter park description (max 250 characters)</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Size
                            </label>
                            <div class="col-lg-9">
                                <input type="text" title="park-title" required="required" v-model="form.size"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter park size </span>
                            </div>
                        </div>

                        <terrain-select
                                @selectTerrain="updateTerrain"
                                v-bind:selectedTerrain="form.terrain_id"
                        />

                        <template v-if="isSuperAdmin">
                            <organization-multi-select
                                @selectOrganization="updateOrganization"
                                v-bind:selectedOrganization="form.user_id"
                            />
                        </template>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Thumbnail (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="thumbnail" class="m-dropzone m-dropzone--primary"
                                              ref="propertyDropzone"
                                              id="propertyDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-removed-file="afterDropzoneRemove"
                                              @vdropzone-error="dropzoneError">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>

                                </vue-dropzone>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Accessible
                            </label>
                            <div class="col-lg-9">
                            <span class="m-switch m-switch--outline m-switch--icon m-switch--brand">
                                <label>
                                    <input type="checkbox" v-model="accessible"
                                           :value="accessible">
                                    <span></span>
                                </label>
                            </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Is active
                            </label>
                            <div class="col-lg-9">
                            <span class="m-switch m-switch--outline m-switch--icon m-switch--brand">
                                <label>
                                    <input type="checkbox" v-model="form.is_active"
                                           :value="form.is_active">
                                    <span></span>
                                </label>
                            </span>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyPark">{{ parkId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import vueDropzone from 'vue2-dropzone';
    import ParkApi from '../../api/park';
    import FileHelper from '../../service/file';
    import TerrainSelect from '../terrain/TerrainSelect';
    import Settings from '../../constant/settings';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { Sketch } from 'vue-color';
    import OrganizationMultiSelect from '../organization/OrganizationMultiSelect';
    import OrganizationRoleMixin from "../../mixins/OrganizationRoleMixin";

    export default {
        mixins: [OrganizationRoleMixin],
        components: {
            TerrainSelect,
            vueDropzone,
            Loading,
            OrganizationMultiSelect,
            'sketch-picker': Sketch,
        },
        name: "park-modal",
        props: ['parkId'],
        data() {
            return {
                form: {
                    is_active: 1,
                    terrain_id: []
                },

                oldGallery: null,
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.parkId,
                name: null,
                description: null,
                color: '#FFF',
                logo: null,

                propertyId: null,
                currentProperty: null,
                distance: null,
                activityId: [],
                currentActivity: [],
                terrainId: [],
                currentTerrain: [],
                difficultyId: [],
                currentDifficulty: [],
                currentDifficulId:null,
                thumbnail: null,
                mapHorizontal: null,
                mapVertical: null,
                gallery: [],
                categoryId: [],
                currentCategory: [],

                fileHelper: FileHelper,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 10,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                },
                isActive: false,
                accessible: false,
                dropOptionsLogo: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                },
            }
        },
        watch: {
            parkId(val) {

                if (val) {
                    ParkApi.getParkDetail(this, val, (context) => {
                        if (context.park) {
                            this.form = context.park
                             this.accessible = !!context.park.accessible;
                            
                            if (context.park.terrain) {
                                let terrain_id = []
                                context.park.terrain.forEach((val, key) => {
                                    terrain_id.push(val.id);
                                });
                                this.form.terrain_id = terrain_id;
                            } else {
                                this.form.terrain_id = []
                            }

                            if (context.park.user) {
                                let user_id = []
                                context.park.user.forEach((val, key) => {
                                    user_id.push(val.id);
                                });
                                this.form.user_id = user_id;
                            } else {
                                this.form.user_id = []
                            }

                            if (context.park.thumbnail) {
                                let thumbnailInfo = JSON.parse(context.park.thumbnail);
                                let url = apiUrl + '/assets' + thumbnailInfo.path;
                                this.$refs.propertyDropzone.manuallyAddFile(thumbnailInfo, url);
                            }
                            this.form.thumbnail = context.park.thumbnail;
                        }
                    });
                }else{
                    this.resetData();
                }
            }
        },
        methods: {
            modifyPark() {
                console.log(this.form);

                let accessible = this.accessible ? 1 : 0;
                this.form.accessible = accessible
                if (this.parkId) {
                    this.form.id = this.parkId

                    ParkApi.updatePark(this, this.parkId, this.form, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.isLoading = false;
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    ParkApi.createPark(this, this.form, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.isLoading = false;
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.form = {
                    is_active: 1,
                    accessible: 0
                }
                this.accessible = false;
                this.$refs.propertyDropzone.removeAllFiles();
            },
            close() {
                this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },
            updateTerrain(id) {
                this.form.terrain_id = id;
            },
            updateOrganization(id) {
                this.form.user_id = id;
            },
            afterDropzoneComplete(file) {
                if (typeof file !== 'undefined') {
                    this.form.thumbnail = file.dataURL;
                    if (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true) {
                        this.form.thumbnail = null;
                    }
                } else {
                    this.form.thumbnail = null;
                }
            },
            afterDropzoneRemove(file) {
                if (this.form.thumbnail === file.dataURL || (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    this.form.thumbnail = null;
                }
            },
            dropzoneError(file, message, xhr) {
                alert(message);
                this.$refs.propertyDropzone.removeFile(file)
            },
        }
    }
</script>

<style lang="css">
</style>