<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="difficultyModal" v-model="showModal"
             :title="difficultyId ? 'Update difficulty' : 'Add new difficulty'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter name </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group m-form__group row">
                        <label class="col-lg-2 col-form-label">
                            Icon (preferred size: 128x128 and .png transparent background)
                        </label>
                        <div class="col-lg-9">
                            <input type="file" id="icon" name="icon"
                                   v-on:change="handleFile('icon')"/>
                        </div>
                    </div>

                    <div class="form-group m-form__group row">
                        <label class="col-lg-2 col-form-label">
                            Active Icon (preferred size: 128x128 and .png transparent background)
                        </label>
                        <div class="col-lg-9">
                            <input type="file" id="active_icon" name="active-icon"
                                   v-on:change="handleFile('active_icon')"/>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyDifficulty">{{ difficultyId ? 'Update' :
                'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import DifficultyApi from '../../api/difficulty';
    import FileHelper from '../../service/file';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            Loading
        },
        name: "difficulty-modal",
        props: ['difficultyId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                icon: null,
                active_icon: null,
                id: this.difficultyId,
                name: null,
                fileHelper: FileHelper,
            }
        },
        watch: {
            difficultyId(val) {
                if (val) {
                    DifficultyApi.getDifficultyDetail(this, val, (context) => {
                        if (context.difficulty) {
                            this.name = context.difficulty.name;
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyDifficulty() {
                this.isLoading = true;
                this.error = false;
                let data = {
                    name: this.name,
                    icon: this.icon,
                    active_icon: this.active_icon
                };
                if (this.difficultyId) {
                    DifficultyApi.updateDifficulty(this, this.difficultyId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');

                        }
                    })
                } else {
                    DifficultyApi.createDifficulty(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.id = null;
                this.name = null;
                this.icon = null;
                this.active_icon = null;
                document.getElementById("icon").value = "";
                document.getElementById("active_icon").value = "";
            },
            close() {

                //this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            handleFile(id) {
                let file = document.querySelector('#' + id);
                console.log(file.files[0]);
                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'icon') {
                                this.icon = context.currentFile;
                            } else if (id === 'active_icon') {
                                this.active_icon = context.currentFile;
                            } else {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        } else {
                            let file = document.querySelector('#' + id);
                            file.value = null;
                        }
                    });
                } else {
                    this.icon = null;
                    this.active_icon = null;
                    this.currentFile = null;
                }
            },
        }
    }
</script>

<style scoped>

</style>