<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        {{pageName}}
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'about_us' }" class="m-nav__link"><span
                                    class="m-nav__link-text">{{pageName}}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                {{pageName}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button type="button" v-if="!isDisabled" class="btn btn-secondary"
                                        @click="disableInput()">Cancel
                                </button>
                            </li>
                            <li class="m-portlet__nav-item">
                                <button type="button" v-if="isDisabled" class="btn btn-brand" @click="enableInput()">
                                    Edit
                                </button>
                                <button type="button" v-if="!isDisabled" class="btn btn-brand" @click="saveInput()">
                                    Save
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <form class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__content">
                            <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                                 id="m_form_1_msg">
                                <div class="m-alert__icon">
                                    <i class="la la-warning"></i>
                                </div>
                                <div class="m-alert__text" v-html="message">
                                </div>
                                <div class="m-alert__close">
                                    <button type="button" class="close" @click="error=false"
                                            aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="title" class="col-lg-2 col-form-label">
                                    Title
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="title" type="text" required
                                                  v-model="title"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="subtitle" class="col-lg-2 col-form-label">
                                    Subtitle
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="subtitle" type="text" required
                                                  v-model="subtitle"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="description" class="col-lg-2 col-form-label">
                                    Description
                                </label>
                                <div class="col-lg-9">
                                    <b-form-textarea rows="4" :disabled="isDisabled === true" id="description" type=""
                                                     required
                                                     v-model="description"></b-form-textarea>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="contact_title" class="col-lg-2 col-form-label">
                                    Contact information Title
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="contact_title" type="text"
                                                  required
                                                  v-model="contactInfoTitle"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="phone" class="col-lg-2 col-form-label">
                                    Phone number
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" class="form-control m-input"
                                                  id="phone"
                                                  type="tel" required v-model="phoneNumber"
                                    >
                                    </b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="email" class="col-lg-2 col-form-label">
                                    Email
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="email" type="email" required
                                                  v-model="email"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="address" class="col-lg-2 col-form-label">
                                    Address
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="address" type="text" required
                                                  v-model="address"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">
                                    Working hours
                                </label>
                                <div class="col-lg-4">
                                    <b-form-input class="" :disabled="isDisabled === true" type="time" required
                                                  v-model="workingFrom"></b-form-input>
                                </div>
                                <label class="col-form-label">to</label>
                                <div class="col-lg-4">
                                    <b-form-input class="" :disabled="isDisabled === true" type="time" required
                                                  v-model="workingTo"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="twitter" class="col-lg-2 col-form-label">
                                    Twitter Url
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="twitter" type="url" required
                                                  v-model="twitter"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="facebook" class="col-lg-2 col-form-label">
                                    Facebook Url
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="facebook" type="url" required
                                                  v-model="facebook"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="youtube" class="col-lg-2 col-form-label">
                                    Youtube Url
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="youtube" type="url" required
                                                  v-model="youtube"></b-form-input>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="instagram" class="col-lg-2 col-form-label">
                                    Instagram Url
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="instagram" type="url" required
                                                  v-model="instagram"></b-form-input>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="instagram_id" class="col-lg-2 col-form-label">
                                    Instagram ID
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="instagram_id" type="url" required
                                                  v-model="instagram_id"></b-form-input>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="facebook_id" class="col-lg-2 col-form-label">
                                    Facebook ID
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="facebook_id" type="url" required
                                                  v-model="facebook_id"></b-form-input>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="twitter_id" class="col-lg-2 col-form-label">
                                    Twitter ID
                                </label>
                                <div class="col-lg-9">
                                    <b-form-input :disabled="isDisabled === true" id="twitter_id" type="url" required
                                                  v-model="twitter_id"></b-form-input>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import SettingApi from '../../api/setting';
    import Moment from 'moment';

    export default {
        components: {},
        name: "AboutUs",
        data() {
            return {
                pageName: 'About Us Content',
                error: false,
                isDisabled: true,
                title: null,
                subtitle: null,
                description: null,
                contactInfoTitle: null,
                twitter: null,
                facebook: null,
                instagram: null,
                twitter_id: null,
                facebook_id: null,
                instagram_id: null,
                youtube: null,
                phoneNumber: null,
                email: null,
                address: null,
                workingFrom: null,
                workingTo: null,
                message: null,
            }
        },
        created() {
            this.fetchData()
        },
        watch: {
            // '$route': 'fetchData'
        },
        methods: {
            fetchData() {
                SettingApi.getAboutUsSetting(this, (context) => {
                    if (context.setting) {
                        let data = JSON.parse(context.setting.setting_value);
                        if (data) {
                            this.title = data.title;
                            this.subtitle = data.subtitle;
                            this.description = data.description;
                            this.contactInfoTitle = data.contact_info_title;
                            this.facebook = data.facebook;
                            this.instagram = data.instagram;
                            this.youtube = data.youtube;
                            this.twitter = data.twitter;

                            this.instagram_id = data.instagram_id;
                            this.facebook_id = data.facebook_id;
                            this.twitter_id = data.twitter_id;

                            this.phoneNumber = data.phone_number;
                            this.email = data.email;
                            this.address = data.address;
                            let workingTime = data.working_time.split(" to ");
                            if (workingTime.length === 2) {
                                this.workingFrom = this.parseTime(workingTime[0]);
                                this.workingTo = this.parseTime(workingTime[1]);
                            }
                        }
                    }
                });
            },
            enableInput() {
                this.isDisabled = false;
            },
            disableInput() {
                this.isDisabled = true;
            },
            saveInput() {
                this.error = false;
                let data = {
                    setting_code: 'about_us',
                    setting_value: JSON.stringify({
                        phone_number: this.phoneNumber,
                        email: this.email,
                        address: this.address,
                        title: this.title,
                        subtitle: this.subtitle,
                        description: this.description,
                        contact_info_title: this.contactInfoTitle,
                        twitter: this.twitter,
                        facebook: this.facebook,
                        instagram: this.instagram,
                        twitter_id: this.twitter_id,
                        facebook_id: this.facebook_id,
                        instagram_id: this.instagram_id,
                        youtube: this.youtube,
                        working_time: this.formatTime(this.workingFrom) + ' to ' + this.formatTime(this.workingTo),
                    })
                };
                SettingApi.updateAboutUsSetting(this, data, (context) => {
                    if (context.setting) {
                        this.message = 'Update setting successful';
                    }
                });
                this.disableInput();
            },
            formatTime(time) {
                return Moment(time, ['h:ma', 'H:m']).format("h:mmA")
            },
            parseTime(time) {
                return Moment(time, ['h:ma', 'H:m']).format("HH:mm")
            }
        }
    }
</script>

<style scoped>

</style>