import auth from '../authentication';
import Vue from '../main';

export default {
    //Activity Section
    getActivity(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/get-all', data)
            .then(response => {
                context.error = false;
                context.activities = response.data.data;
                context.total = response.data.total;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getActivitySelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/get-select')
            .then(response => {
                context.error = false;
                context.activities = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createActivity(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.activity = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    getActivityDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.activity = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateActivity(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.activity = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeActivity(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
    hideActivity(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/activity/hide/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
};