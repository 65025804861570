<template>
    <vue-picture-swipe v-if="fetchGallery" :items="fetchGallery"></vue-picture-swipe>
</template>
<script>
    import VuePictureSwipe from 'vue-picture-swipe';

    export default {
        components: {
            VuePictureSwipe
        },
        props: ['gallery'],

        data() {
            return {
                items: []
            };
        },
        computed: {
            fetchGallery() {
                if (this.gallery) {
                    let galleryData = this.gallery;
                    let galleryItems = [];
                    // galleryData.images.forEach(function (image) {
                    let image = galleryData.images[0];
                    let previewImage = {
                        src: apiUrl + '/assets' + image.path,
                        thumbnail: apiUrl + '/assets' + image.thumbnail,
                        w: 1200,
                        h: 900
                    };

                    galleryItems.push(previewImage);
                    // });
                    return galleryItems;
                }
            }
        }
    }
</script>