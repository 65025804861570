<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Organization
        </label>
        <div class="col-lg-9">
            <multi-select :multiple="true" track-by="id" label="name"
                          v-model="value" :options="organizations"></multi-select>
            <span class="m-form__help">Please choose organization</span>
        </div>
    </div>
</template>

<script>
    import userApi from '../../api/user';
    import MultiSelect from 'vue-multiselect'

    export default {
        name: "organization-select",
        components: {
            MultiSelect
        },
        data() {
            return {
                currentOrganization: [],
                page: 0,
                organizations: []
            }
        },
        props: ['selectedOrganization'],
        computed: {
            value: {
                get() {
                    return this.organizations.filter(
                        terrain => this.currentOrganization.includes(terrain.id)
                    )
                },
                set(newselectedOrganizations) {
                    this.currentOrganization = newselectedOrganizations.map(terrain => terrain.id)
                }
            }
        },
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedOrganization(val) {
                if (val) {
                    this.currentOrganization = val;
                    this.$emit('selectOrganization', val);
                }
            },
            currentOrganization(val) {
                this.$emit('selectOrganization', val);
            }
        },
        methods: {
            getList() {
                userApi.getOrganizationSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>