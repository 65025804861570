<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            User
        </label>
        <div class="col-lg-6">
            <multi-select :multiple="true" track-by="name" label="name" :disabled="disabled"
                          v-model="value" :options="users"></multi-select>
            <span class="m-form__help">Remove "All User" option if sending to specifics.</span>
        </div>
    </div>
</template>

<script>
    import userApi from '../../api/user';
    import MultiSelect from 'vue-multiselect'

    export default {
        name: "UserSelect",
        components: {
            MultiSelect
        },
        data() {
            return {
                currentUser: ['all'],
                page: 0,
                users: [],
            }
        },
        props: ['selectedUser', 'disabled'],
        beforeMount() {
            this.getList();
            this.$emit('selectUser', this.currentUser);
        },
        computed: {
            value: {
                get() {
                    return this.users.filter(
                        user => this.currentUser.includes(user.id)
                    )
                },
                set(newSelectedUsers) {
                    this.currentUser = newSelectedUsers.map(user => user.id)
                }
            }
        },
        watch: {
            selectedUser(val) {
                if (val) {
                    this.currentUser = val;
                    this.$emit('selectUser', val);
                }
            },
            currentUser(val) {
                this.$emit('selectUser', val);
            },

        },
        methods: {
            getList() {
                userApi.getUserSelectToken(this, (context) => {
                    context.users = context.users.map(({id, email}) => ({
                        id: id, name: email,
                    }));
                    context.users.unshift({id: 'all', name: 'All Users',});
                });
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped></style>