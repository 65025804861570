<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newOwnerModal" v-model="showModal"
             :title="ownerId ? 'Update owner' : 'Add new owner'"
             @hidden="close">
        <b-container>
<!--            <loading :active.sync="isLoading"-->
<!--                     :z-index="99999"-->
<!--                     :can-cancel="false"-->
<!--                     :is-full-page="true"></loading>-->
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter owner name </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..." maxlength="80"></textarea>
                                <span class="m-form__help">Please enter owner description (max 80 characters)</span>
                            </div>
                        </div>
                        

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Order Position
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="or"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter Order Position </span>
                            </div>
                        </div>

                         <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Thumbnail (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="thumbnail" class="m-dropzone m-dropzone--primary"
                                              ref="ownerDropzone"
                                              id="ownerDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-removed-file="afterDropzoneRemove">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>

                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyOwner">{{ ownerId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import ownerApi from '../../api/owner';
    import vueDropzone from 'vue2-dropzone'
  
    import Settings from '../../constant/settings';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';



    export default {
        components: {
            vueDropzone,
          
        },
        name: "new-owner",
        props: ['ownerId'],
        data() {
            return { fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.ownerId,
                name: null,
                description: null,
              
                thumbnail: null,
                or: null,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                }
            };
        },

        watch: {
            ownerId(val) {
                if (val) {
                    ownerApi.getOwnerDetail(this, val, (context) => {
                        if (context.owner) {
                            this.error = false;
                            this.name = context.owner.name;
                            this.description = context.owner.description;

                         this.or = context.owner.or;
                            if (context.owner.thumbnail) {
                                let thumbnailInfo = JSON.parse(context.owner.thumbnail);
                                let url = apiUrl + '/assets' + thumbnailInfo.path;
                                this.$refs.ownerDropzone.manuallyAddFile(thumbnailInfo, url);
                            }
                            this.thumbnail = context.owner.thumbnail;
                         
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyOwner() {
                this.isLoading = true;
                this.error = false;
                let data = {
                    name: this.name,
                    description: this.description,
                    thumbnail: this.thumbnail,
                   
                    or: this.or,
                };
                if (this.ownerId) {
                    ownerApi.updateOwner(this, this.ownerId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    ownerApi.createOwner(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.message = null;
                this.id = null;
                this.name = null;
                this.description = null;
                this.thumbnail = null;
                this.$refs.ownerDropzone.removeAllFiles();
                
                this.or = null;
            },
            close() {
               // this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },
            afterDropzoneComplete(file) {
                if (typeof file !== 'undefined') {
                    this.thumbnail = file.dataURL;
                    if (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true) {
                        this.thumbnail = null;
                    }
                } else {
                    this.thumbnail = null;
                }
            },
            afterDropzoneRemove(file) {
                if (this.thumbnail === file.dataURL || (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    this.thumbnail = null;
                }
            },
           
        }
    }
</script>

<style scoped>

</style>