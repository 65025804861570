<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Feedback
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'feedback' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Feedback</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Feedback table
                                <small>
                                    List of all feedback
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col col-12 col-md-3">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="m-input-icon m-input-icon--left">
                                        <input type="text" class="form-control m-input m-input--solid"
                                                v-model="searchKeyword"
                                                @keyup="searchData"
                                                placeholder="Search..." id="generalSearch">
                                        <span class="m-input-icon__icon m-input-icon__icon--left">
                                            <span>
                                                <i class="la la-search"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-12 col-md-3 pt-3 pt-md-0">
                                <date-range-picker ref="picker" v-model="dateRange">
                                </date-range-picker>
                            </div>
                            <div class="col col-12 col-md-6 m--align-right pt-3 pt-md-0">
                                <download-excel
                                    class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                                    name="feedbacks.xls"
                                    :fetch="fetchDataExcel"
                                    :fields="jsonFields"
                                    type="csv"
                                >
                                Export Excel
                                </download-excel>
                            </div>
                        </div>
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse" v-if="feedback.length > 0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Rated</th>
                            <th>Created Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item,index in filteredFeedback" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{ item.user_name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.message }}</td>
                            <td>{{ item.rated }}</td>
                            <td>{{ item.created_at | moment}}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total"
                                  v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import FeedbackApi from '../../api/feedback';
    import DownloadExcel from "vue-json-excel";
    import DateRangePicker from "vue2-daterange-picker";
    import Moment from "moment";

    export default {
        components: {
            DownloadExcel,
            DateRangePicker
        },
        name: "feedback",
        data() {
            return {
                error: false,
                message: null,
                page: 1,
                total: 0,
                searchKeyword: '',
                pageSize: 15,
                feedbackId: null,
                feedback: [],
                jsonFields: {
                    'User Name': 'user_name',
                    'Email': 'email',
                    'Message': 'message',
                    'Rated': 'rated',
                    'Created Date': "created_date"
                },
                dateRange: {},
            }
        },
        computed: {
            filteredFeedback: function () {
                return this.feedback.filter((item) => {
                    //if (item.email.match(this.searchKeyword) || item.user_name.match(this.searchKeyword || item.message.match(this.searchKeyword)) {
                        return item;
                    //}
                });
            }
        },
        filters: {
            moment: function (date) {
                return Moment(date).format('MMMM Do YYYY');
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
                this.getList()
            },
            dateRange: {
                handler(newValue, oldValue) {
                    this.searchData();
                },
                deep: true
            }
        },
        beforeMount() {
            this.getList()
        },
        methods: {
            searchData() {
                this.page = 1;
                this.getList();
            },
            getList() {
                FeedbackApi.getFeedback(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    keyword: this.searchKeyword,
                    date_from: this.dateRange.startDate ? Moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    date_to: this.dateRange.endDate ? Moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                }, (context) => {
                })
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.getList();
            },
            async fetchDataExcel() {
                const { data } = await FeedbackApi.getAll({
                    keyword: this.searchKeyword,
                    date_from: this.dateRange.startDate ? Moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    date_to: this.dateRange.endDate ? Moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                });
                return data.data.map(item => {
                    return { ...item, created_date: Moment(item.created_at).format('MMMM Do YYYY')}
                })
            },
        }
    }
</script>

<style scoped>

</style>