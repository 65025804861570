<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Organization
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'organization' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Organization</span>
                            </router-link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Organization table
                                <small>
                                    List of all organization
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col-xl-12 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-3">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid"
                                                   v-model="search"
                                                   placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                <span><i class="la la-search"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <date-range-picker :time-picker	='true'
                                                           style="min-width: 500px;" ref="picker"
                                                           v-model="dateRange">
                                        </date-range-picker>
                                    </div>
                                    <div class="col-md-5 m--align-right">
                                        <download-excel
                                                class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                                                name="list_organizations.xls"
                                                :fetch="fetchData"
                                                :fields="json_fields"
                                                type="csv"
                                        >
                                            Export
                                        </download-excel>
                                        <button v-b-modal="'newUserModal'"
                                        class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                            <span>
                                                <i class="la la-plus"></i>
                                                <span>New</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button>
                        New organization created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button>
                        Organization updated successfully
                    </div>
                    <div class="alert alert-warning alert-dismissible" role="alert" v-if="alertExport">
                        <button type="button" class="close" aria-label="Close" @click="alertExport=false"></button>
                        There are no data in date range, please pick new range
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse " v-if="users.length > 0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Email Login</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Organization name</th>
                            <th>ZipCode</th>
                            <th>Created Date</th>
                            <th>Properties</th>
                            <th>Trails</th>
                            <th>POI</th>
                            <th style="width: 120px;">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="user,index in filteredUsers" :key="index">
                            <td> {{ index + 1 }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.email_display }}</td>
                            <td>{{ stripLongText(user.phone_number ? user.phone_number : '') }}</td>
                            <td>{{ user.name}} {{user.surname}}</td>
                            <td>{{ user.zipcode }}</td>
                            <td>{{ user.created_at | moment}}</td>
                            <td>{{ user.properties.length }}</td>
                            <td>

                                <router-link :to="{ name: 'trailUser', params: { joinUser: user.id }}">
                                    {{ getTotalTrail(user.properties) }}
                                </router-link>   

                            </td>
                            <td>{{ user.locations.length}}</td>
                            <td>

                                <button title="Edit password"
                                        class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                        v-b-modal="'newUserModal1'"
                                        @click="updatePassword(user.id)">
                                    <i class="la la-support"></i>

                                </button>

                                <button title="Edit details"
                                        class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                        v-b-modal="'newUserModal'"
                                        @click="updateUser(user.id)">
                                    <i class="la la-edit"></i>

                                </button>
                                <button title="Delete" @click="deleteUser(user.id, user.name)"
                                        class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                    <i class="la la-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total"
                                  v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>

            </div>
            <user-modal @clear-id="clearId"
                        @show-create-success-alert="showCreateSuccessAlert"
                        @show-update-success-alert="showUpdateSuccessAlert"
                        @clicked-reload="getList"
                        v-bind:userId="userId"
            />
            <user-modal1 @clear-id="clearId"
                        @show-create-success-alert="showCreateSuccessAlert"
                        @show-update-success-alert="showUpdateSuccessAlert"
                        @clicked-reload="getList"
                        v-bind:userId="userId"
            />
        </div>
    </div>
</template>

<script>
    import userApi from '../../api/user';
    import UserModal1 from './UserModal1';
    import UserModal from './UserModal';
    import Moment from 'moment';
    import DownloadExcel from "vue-json-excel";
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {
        components: {
            DateRangePicker,
            
            UserModal1,
            UserModal,
            DownloadExcel
        },
        name: "organization",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                alertExport: false,
                search: '',
                message: '',
                page: 1,
                total: 0,
                pageSize: 15,
                userId: null,
                users: [],
                trailID: '',
                dateRange: {
                    startDate: Moment().startOf("day").toDate(),
                    endDate: Moment().endOf("day").toDate(),
                },
                json_fields: {
                    'Full Name': 'full_name',
                    'Phone': 'phone_number',
                    'Email': 'email',
                    'Color': 'color_picker',
                    'Description': 'description',
                    'Image': 'logo_image'
                },
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
                this.getList()
            },
            search(val) {
                this.getList()
            },
           
        },
        filters: {
            moment: function (date) {
                return Moment(date).format('MMMM Do YYYY');
            }
        },
        computed: {
            filteredUsers: function () {
                return this.users.filter((user) => {
                    if ((user.name && user.name.match(this.search)) || user.email.match(this.search)) {
                        return user;
                    }
                });
            }
        },
        beforeMount() {
            var id = this.$route.params.id;
            this.trailID = id;
            this.getList();
        },
        methods: {
            updatePassword(id) {
                this.userId = id;
                this.$bvModal.show('newUserModal1');
            },
            updateUser(id) {
                this.userId = id;
                this.$bvModal.show('newUserModal');
            },
            getList() {
                var trail = this.$route.params.trail;
                var property = this.$route.params.property;
                userApi.getUser(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    trail_id : trail,
                    property_id : property,
                    search:this.search,
                    role: 5
                }, (context) => {
                })
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.userId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
                this.alertExport = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
                this.alertExport = false;
            },
            showExportAlert() {
                this.alertCreated = false;
                this.alertUpdated = false;
                this.alertExport = true;
            },
            stripLongText(text) {
                if (text.length > 100) {
                    return text.substring(0, 100) + '...';
                } else {
                    return text;
                }
            },
            deleteUser(id, name) {
                let approve = confirm(`Do you want to remove this user?`);
                if (approve !== false) {
                    userApi.removeUser(this, id, (context) => {
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            exportUser() {
                this.fetchData();
            },
            getTotalTrail(properties) {
                let total = 0;
                properties.map(property => {
                    total += property.trail.length
                })
                return total
            },
            async fetchData() {
                const {data} = await userApi.getUserExport(this, {
                    ...this.dateRange,
                    role: 5
                });
                data.map(item => {
                    item.created_at = Moment(item.created_at).format('MMMM Do YYYY');
                    if(item.logo && item.logo.includes('thumbnail')) {
                        const logo_image = JSON.parse(item.logo);
                        item.logo_image =  apiUrl + '/assets' + logo_image.logo
                    }
                    return item;
                });
                if(data.length == 0) {
                    this.showExportAlert()
                } else {
                    this.alertExport = false;
                }

                return data;
            },
        }
    }
</script>

<style>
    .daterangepicker.dropdown-menu {
        min-width: 700px !important;
    }
</style>