import auth from '../authentication';
import Vue from '../main';

export default {
    getInformation(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/information/get-all', data)
            .then(response => {
                context.error = false;
                context.informations = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getInformationSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/information/get-select')
            .then(response => {
                context.error = false;
                context.informations = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getInformationDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/information/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createInformation(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/information/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateInformation(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/information/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeInformation(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/information/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};