import auth from '../authentication';
import Vue from '../main';

export default {
    getPoiUser(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/poi', data)
            .then(response => {
                context.error = false;
                context.users = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getTrailUser(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/transaction', data)
            .then(response => {
                context.error = false;
                context.users = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getUser(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/get-all', data)
            .then(response => {
                context.error = false;
                context.users = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    async getUserExport(context, data, callback) {
        let result;
        await Vue.http.post(Vue.apiUrl + '/user/export', data)
            .then(response => {
                result = response.data;
            })
            .catch(response => {
                console.log(response);
            });
        return await result;
    },
    getUserSelectToken(context, callback) {
        Vue.http.post(Vue.apiUrl + '/user/get-select?device=1')
            .then(response => {
                context.error = false;
                context.users = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getUserSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/user/get-select')
            .then(response => {
                context.error = false;
                context.users = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getOrganizationSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/user/get-organization-select')
            .then(response => {
                context.error = false;
                context.organizations = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getUserDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/user/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createUser(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateUser(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeUser(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/user/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },

    notifyUser(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/notify/send', data)
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },

    notifyToken(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/user/notify/tokens', data)
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },


};