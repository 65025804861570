<template>
	<div class="m-grid__item m-grid__item--fluid m-wrapper">
		<div v-if="!auth.user.authenticated">
			<div
				class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
				id="m_login"
				style="background-image: url(/assets/images/bg/bg-3.jpg)"
			>
				<div class="m-grid__item m-grid__item--fluid m-login__wrapper">
					<div class="m-login__container">
						<div class="m-login__logo">
							<a href="#">
								<img
									src="assets/images/logos/nantucket_logo.png"
									style="
										border-radius: 100%;
										max-width: 150px;
									"
								/>
							</a>
						</div>
						<div class="m-login__signin">
							<div class="m-login__head">
								<h3 class="m-login__title">
									Sign In To Nantucket Admin Panel
								</h3>
							</div>
							<form class="m-login__form m-form" action="">
								<div
									class="m-alert m-alert--outline alert alert-danger alert-dismissible"
									role="alert"
									v-if="error"
								>
									<button
										type="button"
										class="close"
										data-dismiss="alert"
										aria-label="Close"
									></button>
									<span v-html="message"></span>
								</div>
								<div class="form-group m-form__group">
									<input
										class="form-control m-input"
										v-model="email"
										type="email"
										placeholder="Email"
										name="email"
										autocomplete="off"
									/>
								</div>
								<div class="form-group m-form__group">
									<input
										class="form-control m-input m-login__form-input--last"
										v-model="password"
										type="password"
										placeholder="Password"
										name="password"
									/>
								</div>
								<!--<div class="row m-login__form-sub">-->
								<!--<div class="col m&#45;&#45;align-left m-login__form-left">-->
								<!--<label class="m-checkbox  m-checkbox&#45;&#45;focus">-->
								<!--<input type="checkbox" name="remember">-->
								<!--Remember me-->
								<!--<span></span>-->
								<!--</label>-->
								<!--</div>-->
								<!--<div class="col m&#45;&#45;align-right m-login__form-right">-->
								<!--<a href="javascript:;" id="m_login_forget_password" class="m-link">-->
								<!--Forget Password ?-->
								<!--</a>-->
								<!--</div>-->
								<!--</div>-->
								<div class="m-login__form-action">
									<button
										id="m_login_signin_submit"
										type="button"
										@click="signIn()"
										class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
										v-bind:class="{
											'm-loader m-loader--right m-loader--light':
												loading,
										}"
									>
										Sign In
									</button>
								</div>
							</form>
						</div>
						<!--
                        <div class="m-login__signup">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                    Sign Up
                                </h3>
                                <div class="m-login__desc">
                                    Enter your details to create your account:
                                </div>
                            </div>
                            <form class="m-login__form m-form" action="">
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" placeholder="Fullname" name="fullname" >
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" placeholder="Email" name="email" autocomplete="off">
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="password" placeholder="Password" name="password">
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input m-login__form-input--last" type="password" placeholder="Confirm Password" name="rpassword">
                                </div>
                                <div class="row form-group m-form__group m-login__form-sub">
                                    <div class="col m--align-left">
                                        <label class="m-checkbox m-checkbox--focus">
                                            <input type="checkbox" name="agree">
                                            I Agree the
                                            <a href="#" class="m-link m-link--focus">
                                                terms and conditions
                                            </a>
                                            .
                                            <span></span>
                                        </label>
                                        <span class="m-form__help"></span>
                                    </div>
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signup_submit" class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn">
                                        Sign Up
                                    </button>
                                    &nbsp;&nbsp;
                                    <button id="m_login_signup_cancel" class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom  m-login__btn">
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="m-login__forget-password">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                    Forgotten Password ?
                                </h3>
                                <div class="m-login__desc">
                                    Enter your email to reset your password:
                                </div>
                            </div>
                            <form class="m-login__form m-form" action="">
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" placeholder="Email" name="email" id="m_email" autocomplete="off">
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_forget_password_submit" class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primaryr">
                                        Request
                                    </button>
                                    &nbsp;&nbsp;
                                    <button id="m_login_forget_password_cancel" class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom m-login__btn">
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="m-login__account">
                                <span class="m-login__account-msg">
                                    Don't have an account yet ?
                                </span>
                            &nbsp;&nbsp;
                            <a href="javascript:;" id="m_login_signup" class="m-link m-link--light m-login__account-link">
                                Sign Up
                            </a>
                        </div>
                        -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import auth from "../authentication";
	import Vue from "../main";

	export default {
		name: "login",

		data() {
			return {
				email: null,
				password: null,
				success: false,
				error: false,
				loading: false,
				message: null,
				color: "#4fc08d",
				height: "40px",
				width: "10px",
				auth: auth,
			};
		},
		beforeMount() {
			if (localStorage.getItem("token") !== null) {
				// this.loading = true;
				setTimeout(
					auth.check(this, (context) => {
						// context.loading = false;
						if (!context.message) {
							Vue.router.push({
								name: "home",
							});
						}
					}),
					1000
				);
			}
		},
		methods: {
			signIn() {
				this.loading = true;
				setTimeout(
					auth.signIn(this, this.email, this.password, (context) => {
						context.loading = false;
						if (!context.message) {
							Vue.router.push({
								name: "home",
							});
						}
					}),
					1000
				);
			},
		},
	};
</script>

<style lang="css" scoped></style>
