<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Category
        </label>
        <div class="col-lg-9">
            <multi-select :multiple="true" track-by="name" label="name"
                          v-model="value" :options="categories"></multi-select>
            <span class="m-form__help">Please choose category</span>
        </div>
    </div>
</template>

<script>
    import categoryApi from '../../api/category';
    import MultiSelect from 'vue-multiselect'

    export default {
        name: "category-select",
        components: {
            MultiSelect
        },
        data() {
            return {
                currentCategory: [],
                page: 0,
                categories: [],
            }
        },
        props: ['selectedCategory'],
        beforeMount() {
            this.getList();
        },
        computed: {
            value: {
                get() {
                    return this.categories.filter(
                        category => this.currentCategory.includes(category.id)
                    )
                },
                set(newSelectedCategories) {
                    this.currentCategory = newSelectedCategories.map(category => category.id)
                }
            }
        },
        watch: {
            selectedCategory(val) {
                if (val) {
                    this.currentCategory = val;
                    this.$emit('selectCategory', val);
                }
            },
            currentCategory(val) {
                this.$emit('selectCategory', val);
            },

        },
        methods: {
            getList() {
                categoryApi.getCategorySelect(this, (context) => {
                })
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>