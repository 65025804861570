import auth from '../authentication';
import Vue from '../main';

export default {
    getMapRedirect(context,data,callback){
        Vue.http.post(Vue.apiUrl + '/route/get-map', data, {
           
         }).then(response => {
            
            context.success = true;
            context.error = false;
             context.points = response.data.data;
            // context.total = response.data.total;
            // context.message = response.data.message;
            callback(context);
        })
        .catch(response => {
            
            // context.error = true;
            // context.message = response.data.message;
            // context.total = 0;
            // callback(context);
        })
    },
    addPointsByBus(context, data, callback){
        Vue.http.post(Vue.apiUrl + '/route/import', data)
        .then(response => {
            context.success = true;
            context.error = false;
            // context.locations = response.data.data;
            // context.total = response.data.total;
            // context.message = response.data.message;
            callback(context);
        })
        .catch(response => {
            context.error = true;
            context.message = response.data.message;
            context.total = 0;
            callback(context);
        })
    },
    getLocationByBus(context, data, callback) {

        Vue.http.post(Vue.apiUrl + '/route/get-by-bus', data)
            .then(response => {
                context.error = false;
                context.locations = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getLocation(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/route/get-all', data)
            .then(response => {
                context.error = false;
                context.locations = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getLocationDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/route/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.location = response.data.data;
                callback(context);
            })
            .catch(response => {
                console.log(response);
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getLocationSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/route/get-select')
            .then(response => {
                context.error = false;
                context.locations = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createLocation(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/route/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.location = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateLocation(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/route/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.location = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeLocation(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/route/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};