<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Trail Difficulty
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'difficulty' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Difficulty</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Categories table
                                <small>
                                    List of all difficulty
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col-xl-8 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-4">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid"
                                                   v-model="search"
                                                   placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                <span>
                                                    <i class="la la-search"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                                <button v-b-modal="'difficultyModal'"
                                        class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                    <span>
                                        <i class="la la-plus"></i>
                                        <span>New difficulty</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button>
                        New difficulty created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button>
                        Difficulty updated successfully
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse" v-if="difficulties.length > 0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Icon</th>
                            <th>Active Icon</th>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="difficulty,index in filteredDifficulties">
                            <td>{{index + 1}}</td>
                            <td>
                                <gallery-preview
                                        v-bind:gallery="JSON.parse(difficulty.icon)"
                                />
                            </td>
                            <td>
                                <gallery-preview
                                        v-bind:gallery="JSON.parse(difficulty.active_icon)"
                                />
                            </td>
                            <td>{{ difficulty.name }}</td>
                            <td style="width: 100px;">
                                <button title="Edit details"
                                        class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                        v-b-modal="'difficultyModal'"
                                        @click="updateDifficulty(difficulty.id)">
                                    <i class="la la-edit"></i>
                                </button>
                                <button title="Delete" @click="deleteItem(difficulty.id)"
                                        class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                    <i class="la la-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total"
                                  v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>

            </div>
            <difficulty-modal @clear-id="clearId"
                              @clicked-reload="getList"
                              @show-create-success-alert="showCreateSuccessAlert"
                              @show-update-success-alert="showUpdateSuccessAlert"
                              v-bind:difficultyId="difficultyId"
            />
        </div>
    </div>
</template>

<script>
    import DifficultyApi from '../../api/difficulty';
    import DifficultyModal from './DifficultyModal'
    import GalleryPreview from "../property/PropertyPreview";

    export default {
        components: {
            DifficultyModal,
            GalleryPreview
        },
        name: "difficulty",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 15,
                difficultyId: null,
                difficulties: [],
                createdAlert: "New difficulty created successfully",
                updatedAlert: "Difficulty updated successfully"
            }
        },
        computed: {
            filteredDifficulties: function () {
                return this.difficulties.filter((difficulty) => {
                    if (difficulty.name.match(this.search)) {
                        return difficulty;
                    }
                });
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
                this.getList()
            }
        },
        beforeMount() {
            this.getList()
        },
        methods: {
            updateDifficulty(id) {
                this.difficultyId = id;
                this.$bvModal.show('difficultyModal');
            },
            getList() {
                DifficultyApi.getDifficulty(this, {
                    page: this.page,
                    pageSize: this.pageSize
                }, (context) => {
                })
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.difficultyId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },
            deleteItem(id) {
                let approve = confirm(`Do you want to remove this difficulty?`);
                if (approve !== false) {
                    DifficultyApi.removeDifficulty(this, id, (context) => {
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>