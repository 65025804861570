import auth from '../authentication';
import Vue from '../main';

export default {
    //Difficulty Section
    getDifficulty(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/get-all', data)
            .then(response => {
                context.error = false;
                context.difficulties = response.data.data;
                context.total = response.data.total;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getDifficultySelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/get-select')
            .then(response => {
                context.error = false;
                context.difficulties = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createDifficulty(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.difficulty = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    getDifficultyDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.difficulty = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateDifficulty(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.difficulty = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeDifficulty(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/difficulty/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};