import auth from '../authentication';
import Vue from '../main';

export default {
    //Type Section
    getType(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/report-type//get-all', data)
            .then(response => {
                context.error = false;
                context.types = response.data.data;
                context.total = response.data.total;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },

    createType(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/report-type//add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.type = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    getTypeDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/report-type//get-detail/' + id)
            .then(response => {
                context.error = false;
                context.type = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateType(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/report-type//update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.type = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeType(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/report-type//remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};