<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="ReasonModal" v-model="showModal"
             :title="reasonId ? 'Update Reason' : 'Add new Reason'"
            @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Title
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="title" class="form-control m-input">
                                <span class="m-form__help"> Please enter title </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyReason">{{ reasonId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import ReasonApi from '../../api/reason_delete';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            Loading

        },
        name: "reason-modal",
        props: ['reasonId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.reasonId,
                title: null,
            }
        },
        watch: {
            reasonId(val) {
                if (val) {
                    ReasonApi.getReasonDetail(this, val, (context) => {
                        if (context.reason) {
                            this.title = context.reason.title;
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyReason() {
                this.error = false;
                this.isLoading = true;
                if (this.reasonId) {
                    ReasonApi.updateReason(this, this.reasonId, {
                        title: this.title,
                    }, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');

                        }
                    })
                } else {
                    ReasonApi.createReason(this, {
                        title: this.title,
                    }, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.id = null;
                this.title = null;
            },
            close() {
                //this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            }
        }
    }
</script>

<style scoped>

</style>