import Vue from '../main';

export default {
    getSearchHistories(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/search-history/get-all', data)
        .then(response => {
            context.error = false;
            context.searchHistories = response.data.data;
            context.total = response.data.total;
            context.message = response.data.message;
            callback(context);
        })
        .catch(response => {
            context.error = true;
            context.message = response.data.message;
            context.total = 0;
            callback(context);
        })
    },
    hideKeyword(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/search-history/hide', data)
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
    getKeywordExport(data) {
        return Vue.http.post(Vue.apiUrl + '/search-history/export', data);
    },
    getExportPdf(data) {
        return Vue.http.post(Vue.apiUrl + '/search-history/export-pdf', data, { responseType: 'blob' });
    },
}