var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-grid__item m-grid__item--fluid m-wrapper"},[_c('div',{staticClass:"m-subheader"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-auto"},[_c('h3',{staticClass:"m-subheader__title m-subheader__title--separator"},[_vm._v("\n          Dashboard\n        ")]),_vm._v(" "),_c('ul',{staticClass:"m-subheader__breadcrumbs m-nav m-nav--inline"},[_c('li',{staticClass:"m-nav__item m-nav__item--home"},[_c('router-link',{staticClass:"m-nav__link m-nav__link--icon",attrs:{"exact":"","to":{ name: 'home' }}},[_c('i',{staticClass:"m-nav__link-icon la la-home"})])],1),_vm._v(" "),_c('li',{staticClass:"m-nav__separator"},[_vm._v("-")]),_vm._v(" "),_c('li',{staticClass:"m-nav__item"},[_c('router-link',{staticClass:"m-nav__link",attrs:{"exact":"","to":{ name: 'dashboard' }}},[_c('span',{staticClass:"m-nav__link-text"},[_vm._v("Dashboard")])])],1)])])])]),_vm._v(" "),_c('div',{staticClass:"m-content"},[_c('div',{staticClass:"m-portlet m-portlet--mobile p-4"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12"},[_c('figure',[_c('div',{staticClass:"graphic"},[_c('h6',[_vm._v("\n                A total of "+_vm._s(_vm.dataDashboard.dashboard.total)+" users have seen the Dashboard Tour\n              ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"chart"},[_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.dashboard.finished,
                        _vm.dataDashboard.dashboard.total
                      ),
                    }),attrs:{"title":"Finished"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      "+_vm._s(_vm.dataDashboard.dashboard.finished)+" users finished the tour\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.dashboard.total -
                          _vm.dataDashboard.dashboard.finished,
                        _vm.dataDashboard.dashboard.total
                      ),
                    }),attrs:{"title":"Skipped"}},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dataDashboard.dashboard.total -
                        _vm.dataDashboard.dashboard.finished)+"\n                      users skipped the tour")])])])]),_vm._v(" "),_c('h6',[_vm._v("\n                A total of "+_vm._s(_vm.dataDashboard.trail.total)+" users have seen the Trail Tour\n              ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"chart"},[_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.trail.finished,
                        _vm.dataDashboard.trail.total
                      ),
                    }),attrs:{"title":"Finished"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      "+_vm._s(_vm.dataDashboard.trail.finished)+" users finished the tour\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.trail.total -
                          _vm.dataDashboard.trail.finished,
                        _vm.dataDashboard.trail.total
                      ),
                    }),attrs:{"title":"Skipped"}},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dataDashboard.trail.total -
                        _vm.dataDashboard.trail.finished)+"\n                      users skipped the tour")])])])]),_vm._v(" "),_c('h6',[_vm._v("\n                A total of "+_vm._s(_vm.dataDashboard.profile.total)+" users have seen the Profile Tour\n              ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"chart"},[_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.profile.finished,
                        _vm.dataDashboard.profile.total
                      ),
                    }),attrs:{"title":"Finished"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      "+_vm._s(_vm.dataDashboard.profile.finished)+" users finished the tour\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.profile.total -
                          _vm.dataDashboard.profile.finished,
                        _vm.dataDashboard.profile.total
                      ),
                    }),attrs:{"title":"Skipped"}},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dataDashboard.profile.total -
                        _vm.dataDashboard.profile.finished)+"\n                      users skipped the tour")])])])]),_vm._v(" "),_c('h6',[_vm._v("\n                A total of "+_vm._s(_vm.dataDashboard.signup.total)+" users were registered after finishing the Tour\n              ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"chart"},[_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.signup.dashboard,
                        _vm.dataDashboard.signup.total
                      ),
                    }),attrs:{"title":"Dashboard"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      Dashboard: "+_vm._s(_vm.dataDashboard.signup.dashboard)+"\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.signup.trail,
                        _vm.dataDashboard.signup.total
                      ),
                    }),attrs:{"title":"Trail"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      Trail: "+_vm._s(_vm.dataDashboard.signup.trail)+"\n                    ")])]),_vm._v(" "),_c('span',{staticClass:"block",style:({
                      width: _vm.getPercent(
                        _vm.dataDashboard.signup.profile,
                        _vm.dataDashboard.signup.total
                      ),
                    }),attrs:{"title":"Profile"}},[_c('span',{staticClass:"value"},[_vm._v("\n                      Profile: "+_vm._s(_vm.dataDashboard.signup.profile)+"\n                    ")])])])])])])])])])]),_vm._v(" "),_c('keyword-table'),_vm._v(" "),_c('filter-table'),_vm._v(" "),_c('div',{staticClass:"m-content pt-0"},[_c('div',{staticClass:"m-portlet m-portlet--mobile"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"m-portlet__body"},[_c('bar-chart')],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-portlet__head"},[_c('div',{staticClass:"m-portlet__head-caption"},[_c('div',{staticClass:"m-portlet__head-title"},[_c('h3',{staticClass:"m-portlet__head-text"},[_vm._v("User Registered")])])]),_vm._v(" "),_c('div',{staticClass:"m-portlet__head-tools"})])}]

export { render, staticRenderFns }