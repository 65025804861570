<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Event
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'event' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Event</span>
                            </router-link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Events table
                                <small>
                                    List of all events
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col-xl-8 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-4">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid"
                                                   v-model="search"
                                                   placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                <span><i class="la la-search"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                                <button v-b-modal="'newEventModal'"
                                        class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                    <span>
                                        <i class="la la-plus"></i>
                                        <span>New Event</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button>
                        New event created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button>
                        Event updated successfully
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse " v-if="events.length > 0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Image</th>
                            <!-- <th>Property</th> -->
                            <!--<th>Trail</th>-->
                            <!-- <th>Date</th> -->
                            <!-- <th>Registration Info</th> -->
                            <th>Highlighted</th>
                            <th>Order Position</th>
                            <th style="width: 120px;">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="event,index in filteredEvents">
                            <td> {{ index + 1 }}</td>
                            <td>{{ event.title }}</td>
                            <td>{{ stripLongText(event.description) }}</td>
                            <td>
                                <event-preview
                                        v-bind:gallery="JSON.parse(event.image)"
                                />
                            </td>
                             <!-- <td>{{ typeof event.property != 'undefined' ? event.property.name : '' }}</td> -->
                            <!--<td>{{ event.trail.name }}</td>-->
                            <!-- <td>{{ event.date}}</td> -->
                            <!-- <td>
                                <span><b>Description: </b>{{ event.registration_description }}</span><br>
                                <span><b>Link: </b><a
                                        v-bind:href="event.registration_url">{{ event.registration_url }}</a></span>
                            </td> -->
                            <td>{{ event.highlight ? 'Yes' : 'No' }}</td>
                            <td>{{ event.or  }}</td>
                            <td>
                                <button title="Edit details"
                                        class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                        v-b-modal="'newEventModal'"
                                        @click="updateEvent(event.id)">
                                    <i class="la la-edit"></i>

                                </button>
                                <button title="Delete" @click="deleteEvent(event.id, event.name)"
                                        class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                    <i class="la la-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total"
                                  v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>

            </div>
            <event-modal @clear-id="clearId"
                         @show-create-success-alert="showCreateSuccessAlert"
                         @show-update-success-alert="showUpdateSuccessAlert"
                         @clicked-reload="getList"
                         v-bind:eventId="eventId"
            />
        </div>
    </div>
</template>

<script>
    import eventApi from '../../api/event';
    import EventModal from './EventModal';
    import EventPreview from "../property/PropertyPreview";
    import auth from "../../authentication";
    import Moment from 'moment';

    export default {
        components: {
            EventPreview,
            EventModal,
        },
        name: "event",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                search: '',
                message: '',
                page: 1,
                total: 0,
                pageSize: 15,
                eventId: null,
                events: [],
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
                this.getList()
            }
        },
        filters: {
            moment: function (date) {
                return Moment(date).format('MMMM Do YYYY');
            }
        },
        computed: {
            filteredEvents: function () {
                return this.events.filter((event) => {
                    if (event.title.match(this.search) || event.description.match(this.search)) {
                        return event;
                    }
                });
            }
        },
        beforeMount() {
            this.getList()
        },
        methods: {
            updateEvent(id) {
                this.eventId = id;
                this.$bvModal.show('newEventModal');
            },
            getList() {
                let data = {
                    page: this.page,
                    pageSize: this.pageSize
                }
                if (auth.user.profile.role == 5) {
                    data.user_id = auth.user.profile.id;
                }
                eventApi.getEvent(this, data, (context) => {
                })
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.eventId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },
            stripLongText(text) {
                if (text.length > 100) {
                    return text.substring(0, 100) + '...';
                } else {
                    return text;
                }
            },
            deleteEvent(id, name) {
                let approve = confirm(`Do you want to remove this event?`);

                if (approve !== false) {
                    eventApi.removeEvent(this, id, (context) => {
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>