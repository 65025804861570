<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Trail
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                exact
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link exact :to="{ name: 'trail' }" class="m-nav__link"
                ><span class="m-nav__link-text">Trail</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">
                Trails table
                <small>
                  {{ message }}
                </small>
              </h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <!--begin: Search Form -->
          <div
            class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
          >
            <div class="row align-items-center">
              <div class="col-xl-8 order-2 order-xl-1">
                <div class="form-group m-form__group row align-items-center">
                  <div class="col-md-4">
                    <div class="m-input-icon m-input-icon--left">
                      <input
                        type="text"
                        class="form-control m-input m-input--solid"
                        v-model="searchKeyword"
                        @keyup="searchData"
                        placeholder="Search..."
                        id="generalSearch"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                <button
                  v-b-modal="'newTrailModal'"
                  class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                >
                  <span>
                    <i class="la la-plus"></i>
                    <span>New Trail</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertCreated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertCreated = false"
            ></button>
            New trail created successfully
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertUpdated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertUpdated = false"
            ></button>
            Trail updated successfully
          </div>
          <!--end: Search Form -->
          <div class="table-responsive">
            <table class="table table-inverse" v-if="trails.length > 0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th v-if="isSuperAdmin">Organization</th>
                  <th>Property</th>
                  <th>Status</th>
                  <th>Open To Hunting</th>
                  <th>Highlight</th>
                  <th>Downloaded</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(trail, index) in filteredTrails" :key="index">
                  <td>{{ index + 1 }}</td>

                  <td>{{ trail.name }}</td>
                  <td
                    v-if="isSuperAdmin"
                    v-html="getOrganizationNames(trail.property.user)"
                    style="word-break: break-word; display: block"
                  ></td>
                  <td>{{ trail.property.name }}</td>
                  <td>
                    <span
                      class="m-badge m-badge--wide"
                      v-bind:class="[
                        trail.active ? 'm-badge--success' : 'm-badge--danger',
                      ]"
                      >{{ trail.active ? "Active" : "Inactive" }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="m-badge m-badge--wide"
                      v-bind:class="[
                        trail.open_hunt
                          ? 'm-badge--success'
                          : 'm-badge--danger',
                      ]"
                      >{{ trail.open_hunt ? "Yes" : "No" }}
                    </span>
                  </td>

                  <td>
                    <span
                      class="m-badge m-badge--wide"
                      v-bind:class="[
                        trail.highlight
                          ? 'm-badge--success'
                          : 'm-badge--danger',
                      ]"
                      >{{ trail.highlight ? "Yes" : "No" }}
                    </span>
                  </td>

                  <td>{{ trail.download }}</td>
                  <td style="width: 100px">
                    <router-link
                      :to="{ name: 'listAll', params: { id: trail.id } }"
                    >
                      <i class="la la-map"></i>
                    </router-link>

                    <button
                      title="Edit details"
                      class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                      @click="updateTrail(trail.id)"
                    >
                      <i class="la la-edit"></i>
                      <!--v-b-modal="'newTrailModal'"-->
                    </button>
                    <button
                      title="Delete"
                      @click="deleteTrail(trail.id, trail.name)"
                      class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                    >
                      <i class="la la-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination
            v-if="total > 0 && total > pageSize"
            align="right"
            size="md"
            :total-rows="total"
            v-model="page"
            :per-page="pageSize"
          >
          </b-pagination>
        </div>
      </div>
      <trail-modal
        @clear-id="clearId"
        @clicked-reload="getList"
        @show-create-success-alert="showCreateSuccessAlert"
        @show-update-success-alert="showUpdateSuccessAlert"
        v-bind:trailId="trailId"
      />
    </div>
  </div>
</template>

<script>
import userApi from "../../api/user";
import TrailApi from "../../api/trail";
import TrailModal from "./TrailModal";
import GalleryPreview from "../section/GalleryPreview";
import vueDropzone from "vue2-dropzone";
import auth from "../../authentication";
import OrganizationRoleMixin from "../../mixins/OrganizationRoleMixin";

export default {
  mixins: [OrganizationRoleMixin],
  components: {
    vueDropzone,
    TrailModal,
    GalleryPreview,
  },
  name: "trail",
  data() {
    return {
      error: false,
      alertCreated: false,
      alertUpdated: false,
      message: null,
      page: 1,
      total: 0,
      searchKeyword: "",
      pageSize: 15,
      trailId: null,
      trails: [],
      createdAlert: "New trail created successfully",
      updatedAlert: "Trail updated successfully",
      message: "List of all trails",
    };
  },
  watch: {
    page(val) {
      this.getList();
    },
    pageSize(val) {
      this.getList();
    },
  },
  computed: {
    filteredTrails: function () {
      this.message = "List of all trails";
      var join = this.$route.params.joinUser;
      if (join) {
        this.getUser();
      }

      return this.trails.filter((trail) => {
        if (
          trail.name.match(this.search) ||
          trail.description.match(this.search)
        ) {
          return trail;
        }
      });
    },
  },
  beforeMount() {
    this.getList();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getList();
    },
    getUser() {
      userApi.getUserDetail(this, this.$route.params.joinUser, (context) => {
        console.log(context.event.email);
        if (context.event.email) {
          this.message = "List trails of " + context.event.email;
        }
      });
    },
    updateTrail(id) {
      this.trailId = id;
      this.$bvModal.show("newTrailModal");
    },
    deleteTrail(id, name) {
      let approve = confirm(
        `Do you want to remove trail: ${name} and its related (events, reports) ?`
      );

      if (approve !== false) {
        TrailApi.removeTrail(this, id, (context) => {
          this.getList();
          this.alertCreated = false;
          this.alertUpdated = false;
        });
      }
    },
    getList() {
      var join = this.$route.params.joinUser;

      var data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.searchKeyword,
      };

      if (join) {
        data.userJoin = join;
      }
      if (auth.user.profile.role == 5) {
        data.user_id = auth.user.profile.id;
      }
      TrailApi.getTrail(this, data, (context) => {});
    },
    updatePageSize(val) {
      this.pageSize = parseInt(val);
    },
    clearId() {
      this.trailId = null;
      this.getList();
    },
    showCreateSuccessAlert() {
      this.alertCreated = true;
      this.alertUpdated = false;
    },
    showUpdateSuccessAlert() {
      this.alertCreated = false;
      this.alertUpdated = true;
    },
  },
};
</script>

<style scoped></style>
