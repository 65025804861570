<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Activity
        </label>
        <div class="col-lg-9">
            <multi-select :multiple="true" track-by="name" label="name"
                          v-model="value" :options="activities"></multi-select>
            <span class="m-form__help">Please choose category</span>
        </div>
    </div>
</template>

<script>
    import activityApi from '../../api/activity';
    import MultiSelect from 'vue-multiselect'

    export default {
        name: "activity-select",
        components: {
            MultiSelect
        },
        data() {
            return {
                currentActivity: [],
                page: 0,
                activities: []
            }
        },
        props: ['selectedActivity'],
        beforeMount() {
            this.getList();
        },
        computed: {
            value: {
                get() {
                    return this.activities.filter(
                        activity => this.currentActivity.includes(activity.id)
                    )
                },
                set(newSelectedActivities) {
                    this.currentActivity = newSelectedActivities.map(activity => activity.id)
                }
            }
        },
        watch: {
            selectedActivity(val) {
                if (val) {
                    this.currentActivity = val;
                    this.$emit('selectActivity', val);
                }
            },
            currentActivity(val) {
                this.$emit('selectActivity', val);
            }
        },
        methods: {
            getList() {
                activityApi.getActivitySelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>