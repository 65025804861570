<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Donation Setting
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'donation_setting' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Donation Setting</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Donation settings
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button type="button" v-if="!isDisabled" class="btn btn-secondary"
                                        @click="disableInput()">Cancel
                                </button>
                            </li>
                            <li class="m-portlet__nav-item">
                                <button type="button" v-if="isDisabled" class="btn btn-brand" @click="enableInput()">
                                    Edit
                                </button>
                                <button type="button" v-if="!isDisabled" class="btn btn-brand" @click="saveInput()">
                                    Save
                                </button>

                            </li>
                        </ul>
                    </div>

                </div>
                <form class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__content">
                            <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                                 id="m_form_1_msg">
                                <div class="m-alert__icon">
                                    <i class="la la-warning"></i>
                                </div>
                                <div class="m-alert__text" v-html="message">
                                </div>
                                <div class="m-alert__close">
                                    <button type="button" class="close" @click="error=false"
                                            aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="donation-description" class="col-lg-2 col-form-label">
                                    Donation Description
                                </label>
                                <div class="col-lg-9">
                                    <textarea rows="5" :disabled="isDisabled === true" type="text" required="required"
                                              v-model="description"
                                              id="donation-description"
                                              class="form-control m-input"></textarea>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label for="donation-sub-description" class="col-lg-2 col-form-label">
                                    Donation Sub Description
                                </label>
                                <div class="col-lg-9">
                                    <textarea rows="5" :disabled="isDisabled === true" type="text" required="required"
                                              v-model="subDescription"
                                              id="donation-sub-description"
                                              class="form-control m-input"></textarea>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label for="donation-icon" class="col-lg-2 col-form-label">
                                    Donation Icon
                                </label>
                                <div class="col-lg-6">
                                    <input :disabled="isDisabled === true" type="file"
                                           v-on:change="handleFile('donation-icon')"
                                           id="donation-icon"
                                           class="form-control m-input">
                                </div>
                                <div class="col-lg-3">
                                    <img width="40" height="40" alt="donation-icon" v-bind:src="icon"></img>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">
                                    Donation Amounts
                                </label>
                                <div class="col-lg-2" v-for="amount,index in amounts">
                                    <input :id="'amount-slot-' + index" v-on:keyup="setAmount(index)"
                                           :disabled="isDisabled === true" type="number"
                                           class="form-control m-input"
                                           :value="amount">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import SettingApi from '../../api/setting';
    import FileHelper from '../../service/file';

    export default {
        components: {},
        name: "DonationSetting",
        data() {
            return {
                error: false,
                isDisabled: true,
                description: null,
                subDescription: null,
                icon: null,
                amounts: {},
                donationSetting: ['donation_amounts', 'donation_contents'],
                message: null,

            }
        },
        created() {
            this.fetchData()
        },
        watch: {
            '$route': 'fetchData'
        },
        methods: {
            fetchData() {
                SettingApi.getDonationSetting(this, {}, (context) => {
                    if (context.settings) {
                        context.settings.forEach(setting => {
                            if (setting.donation_amounts) {
                                this.amounts = setting.donation_amounts
                            } else if (setting.donation_contents) {
                                if (setting.donation_contents.icon && setting.donation_contents.icon.path) {
                                    this.icon = apiUrl + '/assets/uploads/' + setting.donation_contents.icon.path;
                                }
                                this.description = setting.donation_contents.description;
                                this.subDescription = setting.donation_contents.sub_description;
                            }
                        })
                    }
                });
            },
            handleFile(id) {
                let file = document.querySelector('#' + id);
                if (typeof file.files[0] != 'undefined') {
                    FileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'donation-icon') {
                                this.icon = context.currentFile;
                            } else {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        } else {
                            let file = document.querySelector('#' + id);
                            file.value = null;
                        }
                    });
                } else {
                    this.icon = null;
                    this.currentFile = null;
                }
            },
            enableInput() {
                this.isDisabled = false;
            },
            disableInput() {
                this.isDisabled = true;
            },
            saveInput() {
                this.error = false;
                if (this.donationSetting) {
                    this.donationSetting.forEach(setting => {
                        let data = {
                            'setting_code': setting,
                            'setting_value': '',
                        };
                        
                        if (setting === 'donation_amounts') {
                            data.setting_value = JSON.stringify(this.amounts);
                        } else if (setting === 'donation_contents') {
                            if (this.icon.includes('base64')){
                                data.setting_value = JSON.stringify({
                                    icon: this.icon,
                                    description: this.description,
                                    sub_description: this.subDescription
                                });
                            } else {
                                data.setting_value = JSON.stringify({
                                    icon: this.icon.replace(apiUrl + '/assets/uploads/',''),
                                    description: this.description,
                                    sub_description: this.subDescription
                                });
                            }
                        }
                        SettingApi.updateDonationSetting(this, setting, data, (context) => {
                            if (context.success) {
                                this.message = 'Update setting successful';
                            }
                        });
                    })

                }
                this.disableInput();
            },
            setAmount(id) {
                let input = document.querySelector('#amount-slot-' + id);
                if (typeof input != 'undefined') {
                    this.amounts[id] = input.value;
                }
            }
        }
    }
</script>

<style scoped>

</style>