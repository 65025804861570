import auth from '../authentication';
import Vue from '../main';

export default {
    //image Section
    getImage(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/get-all', data)
            .then(response => {
            context.error = false;
        context.images = response.data.data;
        context.total = response.data.total;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.message = response.data.message;
        context.total = 0;
        callback(context);
    })
    },
    getImageSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/get-select')
            .then(response => {
            context.error = false;
        context.images = response.data.data;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.message = response.data.message;
        callback(context);
    })
    },
    createImage(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/add-new', data)
            .then(response => {
            context.error = false;
        context.success = true;
        context.image = response.data.data;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.success = false;
        context.message = response.data.message;
        callback(context);
    })
    },
    getImageDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/get-detail/' + id)
            .then(response => {
            context.error = false;
        context.image = response.data.data;
        callback(context);
    })
    .catch(response => {
        console.log(response);return false;
            context.error = true;
        context.message = response.data.message;
        callback(context);
    })
    },
    updateImage(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/update-detail/' + id, data)
            .then(response => {
            context.error = false;
        context.success = true;
        context.image = response.data.data;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.success = false;
        context.message = response.data.message;
        callback(context);
    })
    },
    removeImage(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/remove/' + id, {})
            .then(response => {
            context.error = false;
        context.success = true;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.success = false;
        callback(context);
    })
    }
};