<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Trail
        </label>
        <div class="col-lg-9">
            <select title="trail-select" class="form-control" v-model="currentTrail" v-if="trails.length > 0">
                <option v-for="trail in trails" :value="trail.id">
                    {{ trail.name }}
                </option>
            </select>
            <span class="m-form__help">Please choose trail</span>
        </div>
    </div>

</template>

<script>
    import TrailApi from '../../api/trail';

    export default {
        name: "trail-select",
        data() {
            return {
                currentTrail: null,
                page: 0,
                trails: []
            }
        },
        props: ['selectedTrail'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedTrail(val) {
                if (val) {
                    this.currentTrail = val;
                    this.$emit('selectedTrail', val);
                }
            },
            currentTrail(val) {
                this.$emit('selectTrail', val);
            }
        },
        methods: {
            getList() {
                TrailApi.getTrailSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>