import auth from '../authentication';
import Vue from '../main';

export default {
    getVoting(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/voting/get-all', data)
            .then(response => {
                context.error = false;
                context.votings = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getVotingDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/voting/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.voting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createVoting(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/voting/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.voting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateVoting(context, id, data ,callback) {
        Vue.http.post(Vue.apiUrl + '/voting/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.voting = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeVoting(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/voting/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};