<template>

    <!--begin::Modal-->
    <b-modal size="lg" id="newBusModal" v-model="showModal"
             :title="busId ? 'Update bus' : 'Add new bus'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right" autocomplete="off">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" title="bus-name" required="required" v-model="name"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter bus name </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Distance
                            </label>
                            <div class="col-lg-9">
                                <input type="number" title="bus-distance" required="required" v-model="distance"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter bus distance (mi) </span>
                            </div>
                        </div>



                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..." maxlength="250"></textarea>
                                <span class="m-form__help">Please enter bus description (max 250 characters)</span>
                            </div>
                        </div>

                        <!--<div class="form-group m-form__group row">-->
                            <!--<label class="col-lg-2 col-form-label">-->
                                <!--Is active-->
                            <!--</label>-->
                            <!--<div class="col-lg-9">-->
                            <!--<span class="m-switch m-switch&#45;&#45;outline m-switch&#45;&#45;icon m-switch&#45;&#45;brand">-->
                                <!--<label>-->
                                    <!--<input type="checkbox" v-model="isActive"-->
                                           <!--:value="isActive">-->
                                    <!--<span></span>-->
                                <!--</label>-->
                            <!--</span>-->
                            <!--</div>-->
                        <!--</div>-->


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Color
                            </label>
                            <div class="col-lg-9">
                                <sketch-picker type="text" title="bus-color" required="required" v-model="color"
                                               class="form-control m-input"></sketch-picker>
                                <span class="m-form__help"> Please enter bus color </span>
                            </div>
                        </div>





                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Thumbnail (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-6">
                                <input type="file" id="thumbnail" name="thumbnail"
                                       v-on:change="handleFile('thumbnail')"/>
                            </div>
                            <div v-if="thumbnail" class="col-lg-3">
                                <img width="40" height="40" alt="thumbnail" v-bind:src="thumbnail"></img>
                            </div>
                        </div>

                        <!--<div class="form-group m-form__group row hide">-->
                            <!--<label class="col-lg-2 col-form-label">-->
                                <!--Gallery (preferred size: 1200x900)-->
                            <!--</label>-->
                            <!--<div class="col-lg-9">-->
                                <!--<vue-dropzone v-model="gallery" class="m-dropzone m-dropzone&#45;&#45;primary"-->
                                              <!--ref="galleryDropzone"-->
                                              <!--id="galleryDropzone"-->
                                              <!--:options="dropOptions" :useCustomSlot=true-->
                                              <!--@vdropzone-complete="afterDropzoneComplete"-->
                                              <!--@vdropzone-removed-file="afterDropzoneRemove">-->

                                    <!--<div class="m-dropzone__msg dz-message needsclick">-->
                                        <!--<h3 class="m-dropzone__msg-title">-->
                                            <!--Drop files here or click to upload.-->
                                        <!--</h3>-->
                                        <!--<span class="m-dropzone__msg-desc">-->
                                            <!--Only image files are allowed for upload-->
                                        <!--</span>-->
                                    <!--</div>-->
                                <!--</vue-dropzone>-->
                                <!--<span style="color: red" class="m-form__help">You have to re-upload all images if want to update gallery!</span>-->
                            <!--</div>-->
                        <!--</div>-->
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyBus">{{ busId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>

    // let defaultProps = {
    //     hex: '#000',
    //     // hsl: {
    //     //     h: 150,
    //     //     s: 0.5,
    //     //     l: 0.2,
    //     //     a: 0.9
    //     // },
    //     // hsv: {
    //     //     h: 150,
    //     //     s: 0.66,
    //     //     v: 0.30,
    //     //     a: 0.9
    //     // },
    //     rgba: {
    //         r: 255,
    //         g: 255,
    //         b: 255,
    //         a: 1
    //     },
    //     a: 1
    // }

    import vueDropzone from 'vue2-dropzone';
    import BusApi from '../../api/bus';
    import FileHelper from '../../service/file';

    import Settings from '../../constant/settings';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import Photoshop from 'vue-color';

    import sketch from '../../../node_modules/vue-color/src/components/Sketch';


    export default {
        components: {

            Photoshop,
            vueDropzone,
            Loading,
            'sketch-picker': sketch,

        },
        name: "bus-modal",
        props: ['busId'],
        data() {
            return {
                oldGallery: null,
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.busId,
                name: null,
                description: null,
                color:'#FFF',


                propertyId: null,
                currentProperty: null,
                distance: null,
                activityId: [],
                currentActivity: [],
                terrainId: [],
                currentTerrain: [],
                difficultyId: [],
                currentDifficulty: [],
                thumbnail: null,
                mapHorizontal: null,
                mapVertical: null,
                gallery: [],
                categoryId: [],
                currentCategory: [],
                fileHelper: FileHelper,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 10,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                },
                isActive: false,
                openHunt: false
            }
        },
        watch: {
            busId(val) {
                if (val) {
                    BusApi.getBusDetail(this, val, (context) => {
                        if (context.bus) {

                            console.log(context.bus.color)

                            this.name = context.bus.name;
                            this.description = context.bus.description;

                            if( context.bus.color){
                                this.color = context.bus.color;
                            }

                            else{
                                this.color = '#FFF';
                            }


                            this.distance = context.bus.distance;
                            this.oldGallery = context.bus.gallery;
                            this.isActive = !!context.bus.active;




                            if (context.bus.gallery) {
                                let gallery = JSON.parse(context.bus.gallery);
                                gallery.images.forEach((image) => {
                                    let url = apiUrl + '/assets' + image.path;
                                    this.$refs.galleryDropzone.manuallyAddFile(image, url);
                                });
                                this.gallery = [];

                            }
                            if (context.bus.thumbnail) {
                                this.thumbnail = apiUrl + '/assets' + JSON.parse(context.bus.thumbnail).path;
                            }




                        }
                    });
                }else{
                    this.resetData();
                }
            }
        },
        methods: {
            modifyBus() {
                this.isLoading = true;
                this.error = false;
                let status = this.isActive ? 1 : 0;
                let hunt = this.openHunt ? 1 : 0;
                let data = {
                    name: this.name,
                    description: this.description,
                    active: status,
                    color: this.color.hex,


                    distance: this.distance,
                    gallery: this.gallery,
                    thumbnail: this.thumbnail,


                };

                if (this.busId) {
                    Object.keys(data).forEach(item => {
                        if (!data[item] || (data[item] && typeof (data[item]) === 'string' && data[item].includes('uploads'))) {
                            if (item !== 'active')
                                delete (data[item]);
                        }
                    });
                    data.oldGallery = this.oldGallery;
                    BusApi.updateBus(this, this.busId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.isLoading = false;
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    BusApi.createBus(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.isLoading = false;
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.oldGallery = null;
                this.id = null;
                this.name = null;
                this.description = null;
                this.distance = null;

                this.thumbnail = null;
                this.gallery = [];


                //this.$refs.galleryDropzone.removeAllFiles();

            },
            close() {
                //this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },

            handleFile(id) {
                let file = document.querySelector('#' + id);
                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'thumbnail') {
                                this.thumbnail = context.currentFile;
                            }  else {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        } else {
                            let file = document.querySelector('#' + id);
                            file.value = null;
                        }
                    });
                } else {
                    this.photo[id] = null;
                    this.currentFile = null;
                }
            },



            afterDropzoneComplete(file) {
                if (this.gallery) {
                    this.gallery.push(file.dataURL);
                } else {
                    if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    } else {
                        if (typeof file !== 'undefined') {
                            this.gallery = [];
                            this.gallery.push(file.dataURL);
                        }
                    }
                }
            },

            afterDropzoneRemove(file) {
                if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {

                    let removeFileName = file.name;
                    if (this.oldGallery) {
                        let oldGallery = JSON.parse(this.oldGallery).images;

                        oldGallery.forEach((image, index) => {
                            if (image.name === removeFileName) {
                                oldGallery.splice(index, 1);
                            }
                        });
                        if (oldGallery.length === 0) {
                            this.oldGallery = null;
                        } else {
                            this.oldGallery = JSON.stringify({'images': oldGallery});
                        }
                    }
                } else {
                    let index = this.gallery.indexOf(file.dataURL);
                    if (index > -1) {
                        this.gallery.splice(index, 1);
                    }
                }

            },
        }
    }
</script>

<style lang="css">
</style>