<template>
    <div class="m-content pt-0">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">Search Filter</h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <!--begin: Search Form -->
          <div
            class="m-form m-form--label-align-right"
          >
            <div class="row align-items-center">
              <div class="col-12 order-2 order-xl-1">
                <div class="form-group m-form__group row align-items-center mb-4">
                  <div class="col col-12 col-md-3">
                    <div class="m-input-icon m-input-icon--left">
                      <input
                        type="text"
                        class="form-control m-input m-input--solid"
                        v-model="search"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col col-12 col-md-4 pt-3 pt-md-0">
                    <date-range-picker ref="picker" v-model="dateRange">
                    </date-range-picker>
                  </div>
                  <div class="col col-12 col-md-5 m--align-right pt-3 pt-md-0">
                    <button
                      class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                      @click="exportPdf"
                    >
                      Export PDF
                    </button>
                    <download-excel
                      class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
                      name="search_filters.xls"
                      :fetch="fetchDataExcel"
                      :fields="jsonFields"
                      type="csv"
                    >
                      Export Excel
                    </download-excel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Search Form -->
          <div class="table-responsive">
            <table
              class="table table-inverse"
              v-if="searchFilters.length > 0"
            >
              <thead>
                <tr>
                  <th>Search</th>
                  <th>Search Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in searchFilters" :key="index">
                  <td>{{ item.search }}</td>
                  <td>{{ item.total_count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination
            v-if="total > 0 && total > pageSize"
            align="right"
            size="md"
            :total-rows="total"
            v-model="page"
            :per-page="pageSize"
          >
          </b-pagination>
        </div>
      </div>
    </div>
</template>

<script>
import SearchFilterApi from "../../api/search_filter";
import { downloadBlobFile } from "../../service/download"
import DownloadExcel from "vue-json-excel";
import DateRangePicker from "vue2-daterange-picker";
import Moment from "moment";
import _ from "lodash";

export default {
    components: {
        DownloadExcel,
        DateRangePicker
    },
    data() {
        return {
            page: 1,
            total: 0,
            pageSize: 10,
            search: "",
            dateRange: {},
            searchFilters: [],
            jsonFields: {
                'Search': 'search',
                'Search Count': 'total_count'
            },
        }
    },
    watch: {
        page(val) {
            this.fetchDataSearchFilters();
        },
        search: _.debounce(function (newVal) {
            this.page = 1;
            this.fetchDataSearchFilters();
        }, 200),
        dateRange: {
          handler(newValue, oldValue) {
            this.page = 1;
            this.fetchDataSearchFilters();
          },
          deep: true
        }
    },
    created() {
        this.fetchDataSearchFilters();
    },
    methods: {
        fetchDataSearchFilters() {
            SearchFilterApi.getSearchFilters(this, {
                page: this.page,
                pageSize: this.pageSize,
                search: this.search,
                date_from: this.dateRange.startDate ? Moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                date_to: this.dateRange.endDate ? Moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
            }, (context) => {});
        },
        async fetchDataExcel() {
            const { data } = await SearchFilterApi.getKeywordExport({
              search: this.search,
              date_from: this.dateRange.startDate ? Moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
              date_to: this.dateRange.endDate ? Moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
            });
            return data.data
        },
        exportPdf () {
            SearchFilterApi.getExportPdf({ 
              search: this.search,
              date_from: this.dateRange.startDate ? Moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
              date_to: this.dateRange.endDate ? Moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
            })
            .then((response) => {
                const fileName = `filters-${Date.now()}.pdf`;
                downloadBlobFile(response, fileName);
            });
        }
    }
}
</script>