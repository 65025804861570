<template>
    <vue-picture-swipe class="item-thumbnail" v-if="fetchGallery" :items="fetchGallery"></vue-picture-swipe>
</template>
<script>
    import VuePictureSwipe from 'vue-picture-swipe';

    export default {
        components: {
            VuePictureSwipe
        },
        props: ['gallery'],
        data() {
            return {
                items: []
            };
        },
        computed: {
            fetchGallery() {
                if (this.gallery) {
                    return [{
                        src: apiUrl + '/assets' + this.gallery.path,
                        //thumbnail: apiUrl + '/assets' + this.gallery.thumbnail,
                        thumbnail: apiUrl + '/assets' + this.gallery.path,
                        w: 1200,
                        h: 900
                    }];

                }
            }
        }
    }
</script>

<style lang="css" scoped>

</style>

