<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Park
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                exact
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link exact :to="{ name: 'park' }" class="m-nav__link"
                ><span class="m-nav__link-text">Park</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">
                Park table
                <small>
                  {{ message }}
                </small>
              </h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <!--begin: Search Form -->
          <div
            class="
              m-form m-form--label-align-right
              m--margin-top-20 m--margin-bottom-30
            "
          >
            <div class="row align-items-center">
              <div class="col-xl-8 order-2 order-xl-1">
                <div class="form-group m-form__group row align-items-center">
                  <div class="col-md-4">
                    <div class="m-input-icon m-input-icon--left">
                      <input
                        type="text"
                        class="form-control m-input m-input--solid"
                        v-model="searchKeyword"
                        @keyup="searchData"
                        placeholder="Search..."
                        id="generalSearch"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                <button
                  v-b-modal="'newParkModal'"
                  class="
                    btn btn-brand
                    m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill
                  "
                >
                  <span>
                    <i class="la la-plus"></i>
                    <span>New Park</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertCreated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertCreated = false"
            ></button>
            New park created successfully
          </div>
          <div
            class="alert alert-success alert-dismissible"
            role="alert"
            v-if="alertUpdated"
          >
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="alertUpdated = false"
            ></button>
            Park updated successfully
          </div>
          <!--end: Search Form -->
          <div class="table-responsive">
          <table class="table table-inverse" v-if="parks.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <!-- <th>Gallery</th> -->
                <th>Title</th>
                <th v-if="isSuperAdmin">Organization</th>
                <th>Description</th>
                <th>Size</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(park, index) in filteredParks" :key="park.id">
                <td>{{ index + 1 }}</td>
                <td>{{ park.title }}</td>
                <td v-if="isSuperAdmin" v-html="getOrganizationNames(park.user)"></td>
                <td>{{ stripLongText(park.description) }}</td>
                <td>{{ park.size }}</td>
                <td>
                  <span
                    class="m-badge m-badge--wide"
                    v-bind:class="[
                      park.is_active ? 'm-badge--success' : 'm-badge--danger',
                    ]"
                    >{{ park.is_active ? "Active" : "Inactive" }}
                  </span>
                </td>

                <td style="width: 100px">
                  <router-link
                    :to="{ name: 'parkListPoint', params: { id: park.id } }"
                  >
                    <i class="la la-map"></i>
                  </router-link>

                  <button
                    title="Edit details"
                    class="
                      btn
                      m-btn
                      m-btn--hover-brand
                      m-btn--icon
                      m-btn--icon-only
                      m-btn--pill
                    "
                    @click="updatePark(park.id)"
                  >
                    <i class="la la-edit"></i>
                  </button>
                  <button
                    title="Delete"
                    @click="deletePark(park.id, park.name)"
                    class="
                      btn
                      m-btn
                      m-btn--hover-danger
                      m-btn--icon
                      m-btn--icon-only
                      m-btn--pill
                    "
                  >
                    <i class="la la-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <b-pagination
            v-if="total > 0 && total > pageSize"
            align="right"
            size="md"
            :total-rows="total"
            v-model="page"
            :per-page="pageSize"
          >
          </b-pagination>
        </div>
      </div>
      <park-modal
        @clear-id="clearId"
        @clicked-reload="getList"
        @show-create-success-alert="showCreateSuccessAlert"
        @show-update-success-alert="showUpdateSuccessAlert"
        v-bind:parkId="parkId"
      />
    </div>
  </div>
</template>

<script>
import userApi from "../../api/user";
import ParkApi from "../../api/park";
import ParkModal from "./ParkModal";
import GalleryPreview from "../section/GalleryPreview";
import vueDropzone from "vue2-dropzone";
import auth from "../../authentication";
import OrganizationRoleMixin from "../../mixins/OrganizationRoleMixin";

export default {
  mixins: [OrganizationRoleMixin],
  components: {
    vueDropzone,
    ParkModal,
    GalleryPreview,
  },
  name: "park",
  data() {
    return {
      error: false,
      alertCreated: false,
      alertUpdated: false,
      message: null,
      page: 1,
      total: 0,
      searchKeyword: "",
      pageSize: 15,
      parkId: null,
      parks: [],
      createdAlert: "New park created successfully",
      updatedAlert: "Park updated successfully",
      message: "List of all parks",
    };
  },
  watch: {
    page(val) {
      this.getList();
    },
    pageSize(val) {
      this.getList();
    },
  },
  computed: {
    filteredParks: function () {
      this.message = "List of all parks";
      var join = this.$route.params.joinUser;
      if (join) {
        this.getUser();
      }

      return this.parks.filter((park) => {
        if (
          park.title.match(this.search) ||
          park.description.match(this.search)
        ) {
          return park;
        }
      });
    },
  },
  beforeMount() {
    this.getList();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getList();
    },
    getUser() {
      userApi.getUserDetail(this, this.$route.params.joinUser, (context) => {
        console.log(context.event.email);
        if (context.event.email) {
          this.message = "List parks of " + context.event.email;
        }
      });
    },
    updatePark(id) {
      this.parkId = id;
      this.$bvModal.show("newParkModal");
    },
    deletePark(id, name) {
      let approve = confirm(
        `Do you want to remove park: ${name} and its related (events, reports) ?`
      );

      if (approve !== false) {
        ParkApi.removePark(this, id, (context) => {
          this.getList();
          this.alertCreated = false;
          this.alertUpdated = false;
        });
      }
    },
    getList() {
      var join = this.$route.params.joinUser;

      var data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.searchKeyword,
      };

      if (join) {
        data.userJoin = join;
      }
      if (auth.user.profile.role == 5) {
        data.user_id = auth.user.profile.id;
      }
      ParkApi.getPark(this, data, (context) => {});
    },
    updatePageSize(val) {
      this.pageSize = parseInt(val);
    },
    clearId() {
      this.parkId = null;
      this.getList();
    },
    showCreateSuccessAlert() {
      this.alertCreated = true;
      this.alertUpdated = false;
    },
    showUpdateSuccessAlert() {
      this.alertCreated = false;
      this.alertUpdated = true;
    },
    stripLongText(text) {
      if (text && text.length > 100) {
        return text.substring(0, 100) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
</style>