import auth from "../authentication";

export default {
    computed: {
        isSuperAdmin() {
            return auth.user.profile.role == 1
        }
    },
    methods: {
        getOrganizationNames(users) {
            let organizations = "";
            users.forEach((user, index) => {
              organizations += index != 0 ? ",<br>" : "";
              organizations += user.email + "<br>" + user.name;
            });
            return organizations;
        },
    },
}