<template>
    <!-- page content -->
    <iframe style="margin-top: 30px" width="100%" src="http://analytic.acktrails.com"></iframe>
    <!-- /page content -->
</template>

<script>
    export default {
        name: "home"
    }
</script>