import auth from '../authentication';
import Vue from '../main';

export default {
    //Terrain Section
    getTerrain(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/get-all', data)
            .then(response => {
                context.error = false;
                context.terrains = response.data.data;
                context.total = response.data.total;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getTerrainSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/get-select')
            .then(response => {
                context.error = false;
                context.terrains = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createTerrain(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.terrain = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    getTerrainDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.terrain = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateTerrain(context, id, data, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.terrain = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeTerrain(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
    hideTerrain(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/terrain/hide/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    },
};