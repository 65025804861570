<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newLocationModal" v-model="showModal"
             :title="'Add new Points'"
             @hidden="close">
        <b-container>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter name </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyPoint">{{ 'Add new Point Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import LocationApi from '../../api/location';
   

    export default {
        components: {},
        name: "point-modal",
        data() {
            return {
                success: false,
                error: false,
                message: null,
                showModal: false,
                icon: null,
                active_icon: null,
                name: null,
               
            }
        },
        watch: {
          
        },
        methods: {
            modifyPoint() {
                this.error = false;
                let data = {
                    name: this.name,
                    
                };
       
                    LocationApi.createCategory(this, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                
            },
            resetData() {
                this.id = null;
                this.name = null;
               
            },
            close() {
               
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            
        }
    }
</script>

<style scoped>

</style>