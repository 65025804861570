<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newImageModal" v-model="showModal"
             :title="'List User Liked Image'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <div class="table-responsive">
            <table class="table table-inverse" v-if="likes.length > 0">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Image</th>

<!--                    <th>User</th>-->
                    <th>Liked At</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="like,index in this.likes">
                    <td>{{index + 1}}</td>
                    <td>
                        <image-preview
                                v-bind:gallery="JSON.parse(like.image.image)"
                        />
                    </td>


<!--                    <td>{{ like.user.email }}</td>-->
                    <td>{{ like.created_at | moment}}</td>



                    <!--                            <td>-->
                    <!--                                <span class="m-switch m-switch-outline m-switch-icon m-switch-brand">-->
                    <!--                                <label>-->
                    <!--                                    <input type="checkbox"-->
                    <!--                                           :checked="image.active"-->
                    <!--                                           @change="changeImageStatus(image)"-->
                    <!--                                    >-->
                    <!--                                    <span></span>-->
                    <!--                                </label>-->
                    <!--                            </span>-->

                    <!--                                &lt;!&ndash;                                <span class="m-badge m-badge&#45;&#45;wide"&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                                      :class="[ image.active ? 'm-badge&#45;&#45;success' : 'm-badge&#45;&#45;danger' ]">{{ image.active ? "Active" : "Inactive" }}&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                                </span>&ndash;&gt;-->
                    <!--                            </td>-->


                </tr>
                </tbody>
            </table>
            </div>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>


        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import ImageLikeApi from '../../api/trail_image_like';

    import ImagePreview from "./ImagePreview";
    import GalleryPreview from "../section/GalleryPreview";
    import Moment from 'moment';
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        components: {
            ImageLikeApi,
            GalleryPreview,
            ImagePreview,
            Loading
        },
        name: "image-modal",
        props: ['imageId'],
        data() {
            return {
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.imageId,
                search:'',
                likes:[]
            }
        },
        filters: {
            moment: function (date) {
                return Moment(date).format('MM/DD/YYYY HH:mm:ss');
            }
        },
        watch: {
            imageId(val) {

                if (val) {
                    this.isLoading = true;
                    this.getListLike();
                }
            },
        },
        methods: {
            getListLike() {
                ImageLikeApi.getLike(this, {
                    image: this.imageId,
                    page: this.page,
                    pageSize: this.pageSize,
                    search: this.search
                }, (context) => {
                    this.isLoading = false;
                })
            },

            resetData() {
                this.id = null;

            },
            close() {
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },

        }
    }
</script>