<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader ">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Trail
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">
              -
            </li>
            <li class="m-nav__item">
              <router-link exact :to="{ name : 'trail' }" class="m-nav__link"><span class="m-nav__link-text">Trail</span>
              </router-link>
            </li>
          </ul>
        </div>
  
      </div>
    </div>
    <div class="m-content">
      <div id="map"></div>
  
      <!-- <MglMap id="map-container" :accessToken="accessToken" :mapStyle="mapStyle" :center="coordinates" @load="onMapLoaded" /> -->
    </div>
  </div>
</template>

<style>
  #map {
    height: 500px;
    width: 100%;
  }
</style>
 

<script>
  
  
  import LocationApi from '../../api/location';
  
  export default {
  
    components: {
  
    },
  
    mounted: function() {
    },
    name: "location",
    data() {
      return {
        error: false,
        alertCreated: false,
        alertUpdated: false,
        message: null,
        page: 1,
        total: 0,
        search: '',
        pageSize: 1500,
        locationId: null,
        locations: [],
        createdAlert: "New location created successfully",
        updatedAlert: "Location updated successfully"
      }
    },
    watch: {
  
      page(val) {
        var id = this.$route.params.id;
  
  
        this.getList(id)
      },
      pageSize(val) {
        var id = this.$route.params.id;
  
        this.getList(id)
      }
    },
    beforeMount() {
      var id = this.$route.params.id;
      this.getList(id)
    },
    methods: {
  
      getList(trail) {
        LocationApi.getLocationByTrail(this, {
          page: this.page,
          pageSize: this.pageSize,
          trail: trail
        }, (context) => {
  
          mapboxgl.accessToken = 'pk.eyJ1IjoibWluaGRvODkiLCJhIjoiY2pvdmNpb3kwMWxraTNwcGludDk3amYycCJ9.SIaD_il5TcBB8YA9-vFV0Q';
  
  
  
         
          var args = [];
          if (context.locations.length > 0) {
  
            context.locations.forEach(element => {
             
              var point = [element.long, element.lat];
  
              args.push(point);
  
            })
  
            var map = new mapboxgl.Map({
              container: 'map',
              style: 'mapbox://styles/mapbox/streets-v11',
              center: args[0],
              zoom: 15
            });
  
  
  
            var directions = new MapboxDirections({
              accessToken: mapboxgl.accessToken,
              unit: 'metric',
              profile: 'mapbox/walking'
            });
  
  
            console.log(directions);
  
            console.log(args);
            map.on('load', function() {
              directions.setOrigin(args[0]);
              args.forEach(function(k, v) {
                if ((v != 0) && (v != (args.length - 1))) {
                  console.log(k);
                  console.log(v);
                  directions.addWaypoint((v - 1), k);
                }
              });
  
              // directions2.setOrigin(args[1]);
              // directions2.setDestination(args[2]);
              // map.addControl(directions2);
  
              // directions.setOrigin(args[0]);
              //  directions.addWaypoint(0, args[2]);
  
              directions.setDestination(args[args.length - 1]);
  
              map.addControl(directions);
  
  
  
              // map.addLayer({
              //   "id": "route",
              //   "type": "line",
              //   "source": {
              //     "type": "geojson",
              //     "data": {
              //       "type": "Feature",
              //       "properties": {},
              //       "geometry": {
              //         "type": "LineString",
              //         "coordinates": args
              //       }
              //     }
              //   },
              //   "layout": {
              //     "line-join": "round",
              //     "line-cap": "round"
              //   },
              //   "paint": {
              //     "line-color": "#888",
              //     "line-width": 8
              //   }
              // });
            });
  
  
          }
  
          // L.marker([38.913184, -77.031952]).addTo(mymap);
          // L.marker([37.775408, -122.413682]).addTo(mymap);
  
        })
      },
    }
  }
  
  
  
  
  
  // console.log(Mapbox.Marker);
  
  // export default {
  //   components: {
  //     MglMap
  //   },
  //   data() {
  //     return {
  //       accessToken: "pk.eyJ1IjoibWluaGRvODkiLCJhIjoiY2pvdmNpb3kwMWxraTNwcGludDk3amYycCJ9.SIaD_il5TcBB8YA9-vFV0Q", // your access token. Needed if you using Mapbox maps
  //       mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
  //       coordinates: [-111.549668, 39.014]
  //     };
  //   },
  
  //   created() {
  //     // We need to set mapbox-gl library here in order to use it in template
  //     this.mapbox = MglMap;
  //   },
  //   methods: {
  //     async onMapLoaded(event) {
  
  //       // Here we cathing 'load' map event
  //       const asyncActions = event.component.actions
  
  //       const newParams = await asyncActions.flyTo({
  //         center: [37, 10],
  //         zoom: 9,
  //         speed: 1
  //       })
  
  
  
  //       console.log(newParams)
  
  //       var geojson = {
  //         type: 'FeatureCollection',
  //         features: [{
  //             type: 'Feature',
  //             geometry: {
  //               type: 'Point',
  //               coordinates: [-77.032, 38.913]
  //             },
  //             properties: {
  //               title: 'Mapbox',
  //               description: 'Washington, D.C.'
  //             }
  //           },
  //           {
  //             type: 'Feature',
  //             geometry: {
  //               type: 'Point',
  //               coordinates: [-122.414, 37.776]
  //             },
  //             properties: {
  //               title: 'Mapbox',
  //               description: 'San Francisco, California'
  //             }
  //           }
  //         ]
  //       };
  
  //       geojson.features.forEach(function(marker) {
  
  //         // create a HTML element for each feature
  //         var el = document.createElement('div');
  //         el.className = 'marker';
  
  //         // make a marker for each feature and add to the map
  //         new Mapbox.Marker(el)
  //           .setLngLat(marker.geometry.coordinates)
  //           .addTo(MglMap);
  //       });
  
  //       /* => {
  //               center: [30, 30],
  //               zoom: 9,
  //               bearing: 9,
  //               pitch: 7
  //             }
  //       */
  //     }
  //   }
  
  // };
</script>
 