<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Images
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'image' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Image</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Images table
                                <small>
                                    List of all Images
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col-xl-8 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-4">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid"
                                                   v-model="search"
                                                   placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                <span>
                                                    <i class="la la-search"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 order-1 order-xl-2 m--align-right hide">
                                <button v-b-modal="'newImageModal'"
                                        class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                    <span>
                                        <i class="la la-plus"></i>
                                        <span>New Images</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button>
                        New Image created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button>
                        Image updated successfully
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse" v-if="images.length > 0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Coordinates</th>
                            <th>Trail</th>
                            <th>Park</th>
                            <th>Property</th>
<!--                            <th>User</th>-->
                            <th>Upload Time</th>
                            <th>Status</th>
                            <th>Status Action</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="image,index in this.images">
                            <td>{{index + 1}}</td>
                            <td>
                                <image-preview
                                        v-bind:gallery="JSON.parse(image.image)"
                                />
                            </td>
                            <td>{{ image.description }}</td>

                            <td>lat: {{ image.lat}} <br>
                                long: {{ image.long }}
                            </td>

                            <td>{{ image.trail ? image.trail.name : '' }}</td>
                            <td>{{ image.park ? image.park.title : '' }}</td>
                            <td>{{ image.property.name }}</td>
                            <td>{{ uploadAt(image.created_at) }}</td>
<!--                            <td>{{ image.user.email }}</td>-->
                            <td>
                                                                <span class="m-badge m-badge--wide"
                                                                      :class="[ image.active ? 'm-badge--success' : 'm-badge--danger' ]">{{ image.active ? "Active" : "Inactive" }}
                                                                </span>
                            </td>

                            <td>
                                <span class="m-switch m-switch-outline m-switch-icon m-switch-brand">
                                <label>
                                    <input type="checkbox"
                                           :checked="image.active"
                                           @change="changeImageStatus(image)"
                                    >
                                    <span></span>
                                </label>
                            </span>

                                <!--                                <span class="m-badge m-badge&#45;&#45;wide"-->
                                <!--                                      :class="[ image.active ? 'm-badge&#45;&#45;success' : 'm-badge&#45;&#45;danger' ]">{{ image.active ? "Active" : "Inactive" }}-->
                                <!--                                </span>-->
                            </td>

                            <td style="width: 100px;">
                                <button title="Edit details"
                                        class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                        v-b-modal="'newImageModal'"
                                        @click="updateImage(image.id)">
                                    <i class="la la-edit"></i>
                                </button>
                                <button title="Delete" @click="deleteImage(image.id, image.id)"
                                        class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                    <i class="la la-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total"
                                  v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>

            </div>
            <image-modal @clear-id="clearId"
                         @clicked-reload="getList"
                         @show-create-success-alert="showCreateSuccessAlert"
                         @show-update-success-alert="showUpdateSuccessAlert"
                         v-bind:imageId="imageId"
            />
        </div>
    </div>
</template>

<script>
    import ImageApi from '../../api/trail_image';
    import ImageModal from './ImageModal';
    import ImagePreview from "./ImagePreview";
    import GalleryPreview from "../section/GalleryPreview";
    import auth from '../../authentication';
    import Moment from 'moment';

    export default {
        components: {
            ImageModal,
            GalleryPreview,
            ImagePreview
        },
        name: "image",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 15,
                imageId: null,
                images: [],
                createdAlert: "New image created successfully",
                updatedAlert: "LmageId updated successfully"
            }
        },
        computed: {
            filteredImages: function () {
                return this.images.filter((image) => {
                    if (image.description.match(this.search)
                        || image.trail.title.match(this.search)
                        || image.trail.property.title.match(this.search)) {
                        return image;
                    }
                });
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
              this.page = 1;
                this.getList()
            }, search(val) {
              this.page = 1;
                this.getList()
            },
        },
        beforeMount() {
            this.getList()
        },
        methods: {
            changeImageStatus(image) {
                 let data = {active : image.active ? 0 : 1,
                     description: image.description,

                     lat: image.lat,
                     long: image.long,

                     trail_id: image.trail_id,
                     property_id: image.property_id,

                 };
                image.active =  image.active ? 0 : 1;

                ImageApi.updateImage(this, image.id, data, (context) => {
                    if (context.success) {
                        //this.close();
                        this.$emit('show-update-success-alert');
                    }
                })
            },

            updateImage(id) {
                this.imageId = id;
            },
            getList() {
                let data = {
                    page: this.page,
                    pageSize: this.pageSize,
                    search: this.search
                };
                if(auth.user.profile.role == 5) {
                    data.user_id = auth.user.profile.id;
                }
                ImageApi.getImage(this, data, (context) => {
                })
            },
            deleteImage(id, name) {
                let approve = confirm(`Do you want to remove Image: ${name} ?`);

                if (approve !== false) {
                    ImageApi.removeImage(this, id, (context) => {
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.imageId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },
            stripLongText(text) {
                if (text) {
                    if (text.length > 100) {
                        return text.substring(0, 100) + '...';
                    } else {
                        return text;
                    }
                }
                return '';
            },
            uploadAt(date) {
                return Moment(date).format('MM/DD/YYYY HH:mm:ss');
            }
        }
    }
</script>

<style scoped>

</style>