<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Bus
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'bus' }" class="m-nav__link"><span class="m-nav__link-text">Bus</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
    
            </div>
        </div>
        <div class="m-content">
    
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Bus table
                                <small>
                                         {{ message }}
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <div class="col-xl-8 order-2 order-xl-1">
                                <div class="form-group m-form__group row align-items-center">
                                    <div class="col-md-4">
                                        <div class="m-input-icon m-input-icon--left">
                                            <input type="text" class="form-control m-input m-input--solid" v-model="search" placeholder="Search..." id="generalSearch">
                                            <span class="m-input-icon__icon m-input-icon__icon--left">
                                                    <span>
                                                        <i class="la la-search"></i>
                                                    </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                                <button v-b-modal="'newBusModal'" class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                        <span>
                                            <i class="la la-plus"></i>
                                            <span>New Bus</span>
                                        </span>
                                    </button>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button> New bus created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button> Bus updated successfully
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse" v-if="buss.length > 0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <!-- <th>Gallery</th> -->
                                <th>Name</th>
                                <th>Description</th>
                                <th>Distance</th>



                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bus,index in filteredBus">
                                <td>{{index + 1}}</td>

                                <td>{{ bus.name }}</td>
                                <td>{{ stripLongText(bus.description)}}</td>
                                <td>{{ bus.distance }} mi</td>




                                <td style="width: 100px;">
                                    <router-link :to="{ name: 'listBusAll', params: { id: bus.id }}">
                                        <i class="la la-map"></i>
                                    </router-link>
    
                                    <button title="Edit details" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" v-b-modal="'newBusModal'" @click="updateBus(bus.id)">
                                        <i class="la la-edit"></i>
    
                                    </button>
                                    <button title="Delete" @click="deleteBus(bus.id, bus.name)" class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                        <i class="la la-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total" v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>
    
            </div>
            <bus-modal @clear-id="clearId" @clicked-reload="getList" @show-create-success-alert="showCreateSuccessAlert" @show-update-success-alert="showUpdateSuccessAlert" v-bind:busId="busId" />
        </div>
    </div>
</template>

<script>
    import userApi from '../../api/user';   
    import BusApi from '../../api/bus';
    import BusModal from './BusModal';
    import GalleryPreview from "../section/GalleryPreview";
    import auth from "../../authentication";
    
    export default {
        components: {
            BusModal,
            GalleryPreview
        },
        name: "bus",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 15,
                busId: null,
                buss: [],
                createdAlert: "New bus created successfully",
                updatedAlert: "Bus updated successfully",
                message:'List of all buss',
            }
        },
        watch: {
            page(val) {
                this.getList()
            },
            pageSize(val) {
                this.getList()
            }
        },
        computed: {

            
            filteredBus: function() {
                this.message = 'List of all buss'; 
                var join = this.$route.params.joinUser;
                if(join){
                this.getUser();
            }
                
                return this.buss.filter((bus) => {
                    if (bus.name.match(this.search) || bus.description.match(this.search) ) {
                        return bus;
                    }
                });
            }
            
        },
        beforeMount() {
            
            
            
            
            this.getList();
            
        },
        methods: {
            getUser(){
                userApi.getUserDetail(this, this.$route.params.joinUser, (context) => {
                        console.log(context.event.email);
                        if(context.event.email){
                            this.message = 'List buss of ' + context.event.email;
                           
                        }
                    })
            },
            updateBus(id) {
                this.busId = id;
                this.$bvModal.show('newBusModal');
            },
            deleteBus(id, name) {
                let approve = confirm(`Do you want to remove bus: ${name} and its related (events, reports) ?`);
    
                if (approve !== false) {
                    BusApi.removeBus(this, id, (context) => {
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            getList() {
                var join = this.$route.params.joinUser;

                var data = {
                    page: this.page,
                    pageSize: this.pageSize
                };

                if(join){
                    data.userJoin = join;
                }
                if (auth.user.profile.role == 5) {
                    data.user_id = auth.user.profile.id;
                }
                BusApi.getBus(this, data, (context) => {})
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.busId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },
            stripLongText(text) {
                if (text && text.length > 100) {
                    return text.substring(0, 100) + '...';
                } else {
                    return text;
                }
            }
        }
    }
</script>

<style scoped>
    
</style>