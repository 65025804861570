import Vue from 'vue'
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import BootstrapVue from 'bootstrap-vue';
// register the plugin on vue
import Toasted from 'vue-toasted';


window.Vue = Vue;
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(BootstrapVue);
Vue.use(Toasted);

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

const apiUrl = process.env.NODE_ENV === 'production' ? 
//"http://demo.origin-agency.com:9998" : "http://demo.origin-agency.com:9998";
"https://api-acktrails.com" : "http://acktrails.com:9999";

//'http://demo.origin-agency.com:9998'
 //'http://localhost:9999';//'http://demo.origin-agency.com:9999' //'http://localhost:9999';
const imageUploadApi = apiUrl + '/image/upload';

window.apiUrl = apiUrl;
import auth from './authentication';
import Role from './constant/role';

//Components
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import NavBar from './components/section/NavBar';
import TopBar from './components/section/TopBar';
//import Users from './components/user/Users';
import Owner from './components/owner/Owner';
import Property from './components/property/Property';
import ListArea from './components/property/ListArea';
import ListBusRoute from './components/bus/ListRoute';
import ListBusAll from './components/bus/ListAll';
import ListBusPoint from './components/bus/ListStop';
import Trail from './components/trail/Trail';

import Bus from './components/bus/Bus';

import TrailPoint from './components/trail/Point';
import ListPoint from './components/trail/ListPoint';
import ListPoi from './components/trail/ListPoi';
import ListNotRoute from './components/trail/ListNotRoute';
import ListAll from './components/trail/ListAll';
import ListBus from './components/trail/ListBus';
import Location from './components/location/Location';
import Image from './components/image/Image';
import ImageTop from './components/image/ImageTop';
import Event from './components/event/Event';
import Information from './components/information/Information';
import User from './components/user/User';
import UserDeleted from './components/user/UserDeleted';
import TrailUser from './components/user/TrailUser';
import PoiUser from './components/user/PoiUser';
import Organization from './components/organization/Organization';
import Voting from './components/voting/Voting';

import Notification from './components/user/Notification';
import NotiToken from './components/user/NotifiToken';


import AboutUs from './components/static_content/AboutUs';

import TrailCategory from './components/trail/TrailCategory';

import Category from './components/category/Category';
import Activity from './components/activity/Activity';
import Terrain from './components/terrain/Terrain';
import Difficulty from './components/difficulty/Difficulty';

import Feedback from './components/feedback/Feedback';
import Report from './components/report/Report';
import Donation from './components/donation/Donation';
import FailDonation from './components/donation/DonationFail';
import ReportType from "./components/report_type/ReportType";
import ReasonDelete from "./components/delete_reason/DeletedReason";

import DonationReason from "./components/donation_reason/DonationReason";
import DonationSetting from "./components/static_content/DonationSetting";
import LocationImageUpload from "./components/static_content/LocationImageUpload";

import TrailPropertyStatistic from './components/trail-property-statistic/TrailPropertyStatistic';

import Park from './components/park/Park';
import ParkListPoi from './components/park/ListPoi';
import ParkListPoint from './components/park/ListPoint';
import Dashboard from './components/dashboard/Dashboard';

Vue.component('NavBar', NavBar);
Vue.component('TopBar', TopBar);
Vue.http.options.credentials = true;

let Auth = {
    logged: false,
    name: null,
    type: null
};

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/trail-property-statistic', name: 'trail-property-statistic', component: TrailPropertyStatistic, meta: {requiresAuth: true}},

        {path: '/dashboard', name: 'dashboard', component: Dashboard, meta: {requiresAuth: true}},

        {path: '/login', name: 'login', component: Login},
        {path: '/forgot-password', name: 'forgot_password', component: ForgotPassword},
        {path: '/forgot-password/', name: 'forgot_password1', component: ForgotPassword},
        {path: '/', name: 'home', redirect: {name: 'property'}},
        {path: '/property', name: 'property', component: Property, meta: {requiresAuth: true}},
        {path: '/property/:id/area', name: 'listArea', component: ListArea, meta: {requiresAuth: true}},
        {path: '/bus/:id/route', name: 'listBusRoute', component: ListBusRoute, meta: {requiresAuth: true}},
        {path: '/bus/:id/stop', name: 'listBusPoint', component: ListBusPoint, meta: {requiresAuth: true}},
        {path: '/bus/:id/all', name: 'listBusAll', component: ListBusAll, meta: {requiresAuth: true}},

        {path: '/owner', name: 'owner', component: Owner, meta: {requiresAuth: true}},
        {path: '/trail', name: 'trail', component: Trail, meta: {requiresAuth: true}},
        {path: '/bus', name: 'bus', component: Bus, meta: {requiresAuth: true}},
        // {path: '/trail/:joinUser', name: 'trailUser', component: Trail, meta: {requiresAuth: true}},
        {path: '/trail/:id/not-route', name: 'listNotRoute', component: ListNotRoute, meta: {requiresAuth: true}},
        {path: '/trail/:id/points', name: 'listPoint', component: ListPoint, meta: {requiresAuth: true}},
        {path: '/trail/:id/poi', name: 'listPoi', component: ListPoi, meta: {requiresAuth: true}},
        {path: '/trail/:id/bus', name: 'listBus', component: ListBus, meta: {requiresAuth: true}},
        {path: '/trail/:id/all', name: 'listAll', component: ListAll, meta: {requiresAuth: true}},
        {path: '/location', name: 'location', component: Location, meta: {requiresAuth: true}},
        {path: '/image', name: 'image', component: Image, meta: {requiresAuth: true}},
        {path: '/top/image', name: 'imageTop', component: ImageTop, meta: {requiresAuth: true}},
        {path: '/event', name: 'event', component: Event, meta: {requiresAuth: true}},

        {path: '/report', name: 'report', component: Report, meta: {requiresAuth: true}},
        {path: '/feedback', name: 'feedback', component: Feedback, meta: {requiresAuth: true}},
        {path: '/donation', name: 'donation', component: Donation, meta: {requiresAuth: true}},
        {path: '/donation/fail', name: 'failDonation', component: FailDonation, meta: {requiresAuth: true}},

        {path: '/trail_category', name: 'trail_category', component: TrailCategory, meta: {requiresAuth: true}},
        {path: '/category', name: 'category', component: Category, meta: {requiresAuth: true}},
        {path: '/activity', name: 'activity', component: Activity, meta: {requiresAuth: true}},
        {path: '/terrain', name: 'terrain', component: Terrain, meta: {requiresAuth: true}},
        {path: '/difficulty', name: 'difficulty', component: Difficulty, meta: {requiresAuth: true}},
        {path: '/report-type', name: 'report_type', component: ReportType, meta: {requiresAuth: true}},
        {path: '/reason-delete', name: 'reason_delete', component: ReasonDelete, meta: {requiresAuth: true}},

        {path: '/donation-reason', name: 'donation_reason', component: DonationReason, meta: {requiresAuth: true}},
        {path: '/donation-setting', name: 'donation_setting', component: DonationSetting, meta: {requiresAuth: true}},
        {path: '/about-us', name: 'about_us', component: AboutUs, meta: {requiresAuth: true}},

        {path: '/information', name: 'information', component: Information, meta: {requiresAuth: true}},
        {path: '/user', name: 'user', component: User, meta: {requiresAuth: true}},
        {path: '/user_deleted', name: 'user_deleted', component: UserDeleted, meta: {requiresAuth: true}},
        {path: '/trail/:trail/user', name: 'userTrail', component: TrailUser, meta: {requiresAuth: true}},
        {path: '/poi/:location/user', name: 'userPoi', component: PoiUser, meta: {requiresAuth: true}},
        {path: '/property/:property/user', name: 'userProperty', component: TrailUser, meta: {requiresAuth: true}},
        {path: '/notification', name: 'notification', component: Notification, meta: {requiresAuth: true}},
        {path: '/noti', name: 'noti', component: NotiToken, meta: {requiresAuth: true}},

        {path: '/organization', name: 'organization', component: Organization, meta: {requiresAuth: true}},
        {path: '/voting', name: 'voting', component: Voting, meta: {requiresAuth: true}},
        {
            path: '/location-image-uploader',
            name: 'location_image_uploader',
            component: LocationImageUpload,
            meta: {requiresAuth: true}
        },
        
        {path: '/park', name: 'park', component: Park, meta: {requiresAuth: true}},
        {path: '/park/:id/points', name: 'parkListPoint', component: ParkListPoint, meta: {requiresAuth: true}},
        {path: '/park/:id/poi', name: 'parkListPoi', component: ParkListPoi, meta: {requiresAuth: true}},
    ],
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    root: '/'
});


router.beforeEach(function (to, from, next) {
    if (to.matched.some(record => record.meta.requiresAuth) && !Auth.logged) {
        next({path: '/login'});
    } else {
        if (to.matched.some(record => record.meta.superAdmin) && (Auth.type === Role.SuperAdmin || Auth.type === Role.Admin)) {
            next({path: '/'});
        } else {
            next();
        }
    }
});

router.mode = 'html5';
Vue.router = router;
Vue.apiUrl = apiUrl;
Vue.Auth = Auth;

export default Vue;

const app = new Vue({
    el: '#app',
    data() {
        return {
            apiUrl: apiUrl,
            Auth: Auth
        }
    },
    router
});