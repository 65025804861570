import auth from '../authentication';
import Vue from '../main';

export default {
    //image Section
    getLike(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail_image/likes', data)
            .then(response => {
            context.error = false;
        context.likes = response.data.data;
        context.total = response.data.total;
        callback(context);
    })
    .catch(response => {
            context.error = true;
        context.message = response.data.message;
        context.total = 0;
        callback(context);
    })
    },

};