<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div >
            <div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
                 id="m_login" style="background-image: url(/assets/images/bg/bg-3.jpg);">
                <div class="m-grid__item m-grid__item--fluid	m-login__wrapper">
                    <div class="m-login__container">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="assets/images/logos/nantucket_logo.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                    Forgot Password
                                </h3>
                            </div>
                            <form class="m-login__form m-form" action="">
                                <div class="m-alert m-alert--outline alert alert-danger alert-dismissible"
                                     role="alert" v-if="error">
                                    <button type="button" class="close" data-dismiss="alert"
                                            aria-label="Close"></button>
                                    <span v-html="message"></span>
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" v-model="newpassword" type="password" placeholder="New Password"
                                           name="newpassword" autocomplete="off">
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input m-login__form-input--last" v-model="repassword"
                                           type="password" placeholder="Confirm Password" name="repassword">
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signin_submit" type="button" @click="forgotPass()"
                                            class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                                            v-bind:class="{ 'm-loader m-loader--right m-loader--light' : loading}">
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="alert alert-danger alert-dismissible" role="alert" v-if="alertNotMatch">
                            <button type="button" class="close" aria-label="Close" @click="alertNotMatch=false"></button>
                            Password is not matched.
                        </div>
                        <div class="alert alert-danger alert-dismissible" role="alert" v-if="alertShort">
                            <button type="button" class="close" aria-label="Close" @click="alertShort=false"></button>
                            Password is not short than 6 characters.
                        </div>
                        <div class="alert alert-success alert-dismissible" role="alert" v-if="alertSuccess">
                            <button type="button" class="close" aria-label="Close" @click="alertSuccess=false"></button>
                            Password is changed
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../authentication';
    import Vue from '../main';

    export default {
        name: "forgot_password",
        data() {
            return {
                newpassword: null,
                repassword: null,
                alertNotMatch: false ,
                alertShort: false ,
                alertSuccess: false ,
                success: false,
                error: false,
                loading: false,
                message: null,
                color: '#4fc08d',
                height: '40px',
                width: '10px',
                auth: auth
            }
        },
        beforeMount() {
            console.log('check id',this.$route.query.id);
        },
        methods: {
            forgotPass() {
                this.loading = true;
                console.log('forgot-password',this.newpassword,this.repassword,this.$route.query.id);
                if(this.newpassword != this.repassword)
                {   
                    this.alertNotMatch = true ;
                } else if(this.newpassword != null && this.newpassword.length < 6){
                    this.alertShort = true ;
                } else {
                    // console.log('reset password success');
                    // this.alertSuccess = true;
                    this.loading = false ;
                    setTimeout(
                    auth.updatePassword(this, this.$route.query.id, this.newpassword, (context) => {
                        context.loading = false;
                        context.alertSuccess = true;
                    })
                    , 1000);
                }
                

            }
        }
    }
</script>

<style lang="css" scoped>

</style>