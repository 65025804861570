<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Event
        </label>
        <div class="col-lg-9">
            <select title="event-select" class="form-control" v-model="currentEvent" v-if="events.length > 0">
                <option value="">Choose event</option>
                <option v-for="item in events" :value="item.id" :key="item.id">
                    {{ item.title }}
                </option>
            </select>
            <span class="m-form__help">Please choose event</span>
        </div>
    </div>
</template>

<script>
    import eventApi from '../../api/event';

    export default {
        name: "event-select",
        data() {
            return {
                currentEvent: null,
                page: 0,
                events: []
            }
        },
        props: ['selectedEvent'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedEvent(val) {
                this.currentEvent = val;
                this.$emit('change', val);
            },
            currentEvent(val) {
                this.$emit('change', val);
            }
        },
        methods: {
            getList() {
                eventApi.getEventSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>