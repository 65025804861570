<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newVotingModal" v-model="showModal"
             :title="votingId ? 'Update voting' : 'Add new voting'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Campaign name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="campaignName" class="form-control m-input">
                                <span class="m-form__help"> Please enter campaign name </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Date from
                            </label>
                            <div class="col-lg-9">
                                <vue-datepicker v-model="dateFrom" format="yyyy-MM-dd"/>
                                <span class="m-form__help"> Please enter date from</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Date to
                            </label>
                            <div class="col-lg-9">
                                <vue-datepicker v-model="dateTo" format="yyyy-MM-dd" :disabled-dates="disabledDates"/>
                                <span class="m-form__help"> Please enter date to</span>
                            </div>
                        </div>

                        <event-select
                            @change="updateEvent"
                            v-bind:selectedEvent="eventId"
                        />

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter voting description </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyVoting">{{ votingId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>



import votingApi from '../../api/voting';
// import TrailSelect from '../trail/TrailSelect'
import Settings from '../../constant/settings';
import VueDatepicker from 'vuejs-datepicker';
import moment from 'moment';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';    
import EventSelect from '../event/EventSelect';

export default {
    components: {
        VueDatepicker,
        Loading,
        EventSelect
    },
    name: "voting-modal",
    props: ['votingId'],
    data() {
        return {
            fullPage: true,
            isLoading: false,
            success: false,
            error: false,
            message: null,
            showModal: false,
            id: this.votingId,
            campaignName: null,
            dateFrom: null,
            dateTo: null,
            eventId: null,
            description: null,
            disabledDates: {}
        }
    },

    watch: {
        votingId(val) {
            if (val) {
                votingApi.getVotingDetail(this, val, (context) => {
                    if (context.voting) {
                        this.error = false;
                        this.campaignName = context.voting.campaign_name;
                        this.dateFrom = context.voting.date_from;
                        this.dateTo = context.voting.date_to;
                        this.eventId = context.voting.event_id;
                        this.description = context.voting.description;
                    }
                })
            }
        },
        dateFrom(val) {
            if(moment(this.dateTo) < moment(this.dateFrom)) {
                this.dateTo = this.dateFrom
            }
            this.disabledDates = {
                customPredictor: function(date) {
                    if(moment(date) < moment(val)){
                        return true
                    }
                }
            }
        }
    },
    methods: {
        modifyVoting() {
            this.isLoading = true;
            this.error = false;
            let data = {
                campaign_name: this.campaignName,
                date_from: this.dateFrom ? moment(this.dateFrom).format("yyyy-MM-DD") : '',
                date_to: this.dateTo ? moment(this.dateTo).format("yyyy-MM-DD") : '',
                event_id: this.eventId,
                description: this.description
            };
            if (this.votingId) {
                votingApi.updateVoting(this, this.votingId, data, (context) => {
                    this.isLoading = false;
                    if (context.success) {
                        this.close();
                        this.$emit('show-update-success-alert');
                    }
                })
            } else {
                votingApi.createVoting(this, data, (context) => {
                    this.isLoading = false;
                    if (context.success) {
                        this.close();
                        this.$emit('show-create-success-alert');
                    }
                })
            }
        },
        resetData() {
            this.campaignName = null;
            this.dateFrom = null;
            this.dateTo = null;
            this.eventId = null;
            this.description = null;
        },
        close() {
            this.resetData();
            this.showModal = false;
            this.error = false;
            this.$emit('clear-id');
        },
        updateEvent(id) {
            this.eventId = id;
        }
    }
}
</script>

<style scope>
.vdp-datepicker input {
    width: 100%;
    line-height: 1.25;
    padding: 0.65rem 1.25rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    color: #575962;
}
.vdp-datepicker input:focus {
    outline: none;
    border-color: #716aca;
    color: #575962;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
</style>