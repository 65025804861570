<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Difficulty
        </label>
        <div class="col-lg-9">
            <select title="difficulty-select" class="form-control" v-model="currentDifficulty" v-if="difficulties.length > 0">
                <option v-for="difficulty in difficulties" :value="difficulty.id">
                    {{ difficulty.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import difficultyApi from '../../api/difficulty';

    export default {
        name: "difficulty-select",
        data() {
            return {
                currentDifficulty: [],
                page: 0,
                difficulties: []
            }
        },
        props: ['selectedDifficulty'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedDifficulty(val) {
                if (val) {
                    this.currentDifficulty = val;
                    this.$emit('selectDifficulty', val);
                }
            },
            currentDifficulty(val) {
                this.$emit('selectDifficulty', val);
            }
        },
        methods: {
            getList() {
                difficultyApi.getDifficultySelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>