<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newEventModal" v-model="showModal"
             :title="eventId ? 'Update event' : 'Add new event'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Title
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="title" class="form-control m-input">
                                <span class="m-form__help"> Please enter event title </span>
                            </div>
                        </div>

                        <property-select
                                @selectProperty="updateProperty"
                                v-bind:selectedProperty="currentProperty"
                        />

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter event description </span>
                            </div>
                        </div>


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Location
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="location"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter event location </span>
                            </div>
                        </div>
                        <!--<trail-select-->
                                <!--@selectTrail="updateTrail"-->
                                <!--v-bind:selectedTrail="currentTrail"-->
                        <!--/>-->
                        <!-- <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Date
                            </label>
                            <div class="col-lg-9">
                               
                                <vue-date-picker :bootstrap-styling="true" v-model="date">
                                </vue-date-picker>
                                <span class="m-form__help">Please enter event date </span>

                            </div>
                        </div> -->

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Date
                            </label>
                            <div class="col-lg-9">
                                <textarea type="text" required="required" v-model="date" class="form-control m-input"></textarea>
                                <span class="m-form__help"> Please enter event date </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Time
                            </label>
                            <div class="col-lg-9">
                                <textarea type="text" required="required" v-model="time" class="form-control m-input"></textarea>
                                <span class="m-form__help"> Please enter event time </span>
                            </div>
                        </div>


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Image (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="image" class="m-dropzone m-dropzone--primary"
                                              ref="eventDropzone"
                                              id="eventDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-file-added="addedFile"
                                              @vdropzone-removed-file="afterDropzoneRemove"
                                              @vdropzone-error="dropzoneError">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>

                                </vue-dropzone>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Highlight
                            </label>
                            <div class="col-lg-9">
                                <input type="checkbox" id="highlight" v-model="highlight">
                                <br>
                                <span class="m-form__help">Check this to show event on dashboard </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Registration Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="registrationDescription"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter registration description </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Registration Link
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="registrationUrl"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter registration url </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Order Position
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="or"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter Order Position </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyEvent">{{ eventId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>



import eventApi from '../../api/event';
    import vueDropzone from 'vue2-dropzone'
   // import TrailSelect from '../trail/TrailSelect'
    import Settings from '../../constant/settings';
    import VueDatePicker from 'vuejs-datepicker';
    import Moment from 'moment';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import PropertySelect from '../property/PropertySelect';
    


    export default {
        components: {
            vueDropzone,
         //   TrailSelect,
            VueDatePicker,
            Loading,
            PropertySelect,
        },
        name: "event-modal",
        props: ['eventId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.eventId,
                title: null,
                time: null,
                description: null,
                location:null,
                registrationDescription: null,
                registrationUrl: null,
                highlight: null,
                date: null,
                or: null,
                propertyId: null,
                currentProperty: null,
                propertyId: null,
               // trailId: null,
                //currentTrail: null,
                image: null,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*"
                }
            }
        },

        watch: {
            eventId(val) {
                if (val) {
                    eventApi.getEventDetail(this, val, (context) => {
                        if (context.event) {
                            this.error = false;
                            this.title = context.event.title;
                            this.time = context.event.time;
                            this.description = context.event.description;
                            this.location = context.event.location;
                            this.registrationDescription = context.event.registration_description;
                            this.registrationUrl = context.event.registration_url;
                            this.date = context.event.date;
                            this.highlight = context.event.highlight;
                            this.currentProperty = context.event.property_id;
                            this.propertyId = context.event.property_id;
                        this.or = context.event.or;
                            if (context.event.image) {
                                let imageInfo = JSON.parse(context.event.image);
                                let url = apiUrl + '/assets' + imageInfo.path;
                                this.$refs.eventDropzone.manuallyAddFile(imageInfo, url);
                            }
                            this.image = context.event.image;
                            // this.trailId = context.event.trail_id;
                            // this.currentTrail = context.event.trail_id;
                        }
                    })
                }
            },
        },
        methods: {
            modifyEvent() {
                this.isLoading = true;
                this.error = false;
                let data = {
                        title: this.title,
                        time: this.time,
                        description: this.description,
                        location: this.location,
                        date: this.date,//Moment(this.date).format("YYYY-MM-DD HH:mm:ss"),
                        image: this.image,
                        property_id: this.propertyId,
                       // trail_id: this.trailId,
                        highlight: this.highlight,
                        or: this.or,
                        registration_description: this.registrationDescription,
                        registration_url: this.registrationUrl
                    }
                ;
                if (this.eventId) {
                    eventApi.updateEvent(this, this.eventId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    eventApi.createEvent(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.message = null;
                this.id = null;
                this.title = null;
                this.time = null;
                this.description = null;
                this.location = null;
                this.date = null;
                this.image = null;
                this.or = null;
                this.highlight = null;
                this.registrationDescription = null;
                this.registrationUrl = null;
                this.currentProperty = '';
                this.propertyId = null;
                this.$refs.eventDropzone.removeAllFiles();
                // this.trailId = null;
                // this.currentTrail = null;
            },
            close() {
                this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },
            updateProperty(id) {
                this.propertyId = id;
            },
            afterDropzoneComplete(file) {
                if (typeof file !== 'undefined') {
                    this.image = file.dataURL;
                    if (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true) {
                        this.image = null;
                    }
                } else {
                    this.image = null;
                }
            },
            afterDropzoneRemove(file) {
                if (this.image === file.dataURL || (typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    this.image = null;
                }
            },
            updateTrail(id) {
                this.trailId = id;
            },
            addedFile(file) {
                let files = this.$refs.eventDropzone.dropzone.files
                if (files.length > 1) {
                    this.$refs.eventDropzone.removeFile(files[0]);
                }
            },
            dropzoneError(file, message, xhr) {
                alert(message);
                this.$refs.eventDropzone.removeFile(file)
            },
        }
    }
</script>

<style scoped>

</style>