<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        User
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'user' }" class="m-nav__link"><span class="m-nav__link-text">User</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Users table
                                <small>
                                    List users
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button>
                        New user created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button>
                        User updated successfully
                    </div>
                    <!--end: Search Form -->
                    <div class="table-responsive">
                    <table class="table table-inverse " v-if="users.length > 0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>Full Name</th>
                                <th>Start At</th>
                                <th>End At</th>
                                <!-- <th>Trail</th> -->
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="trailuser,index in filteredUsers">
                                <td> {{ index + 1 }}</td>
                                <td>{{ trailuser.user.email }}</td>
                                <td>{{ trailuser.user.full_name}}</td>
                                <td>{{ trailuser.start_at | unixtodate}}</td>
                                <td>{{ trailuser.end_at |unixtodate}}</td>
                                <!-- <td>{{ trailuser.trail.name}}</td> -->
                                <td>{{ trailuser.total |secondtotime }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total" v-model="page" :per-page="pageSize">
                    </b-pagination>
                </div>
            </div>
            <user-modal @clear-id="clearId" @show-create-success-alert="showCreateSuccessAlert" @show-update-success-alert="showUpdateSuccessAlert" @clicked-reload="getList" v-bind:userId="userId" />
        </div>
    </div>
</template>
<script>
import userApi from '../../api/user';
import UserModal from './UserModal';
import Moment from 'moment';
import DownloadExcel from "vue-json-excel";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: {
        DateRangePicker,
        UserModal,
        DownloadExcel
    },
    name: "user",
    data() {
        return {
            error: false,
            alertCreated: false,
            alertUpdated: false,
            search: '',
            message: '',
            page: 1,
            total: 0,
            pageSize: 15,
            userId: null,
            users: [],
            trailID: '',
            dateRange: {
                startDate: Moment().startOf("day").toDate(),
                endDate: Moment().endOf("day").toDate(),
            },
            json_fields: {
                'Email': 'email',
                'Phone': 'phone_number',
                'Full Name': 'full_name',
                'Created Date': 'created_at',
            },
        }
    },
    watch: {
        page(val) {
            this.getList()
        },
        pageSize(val) {
            this.getList()
        },
    },
    filters: {

        secondtotime: function(total){
                                        var sec_num = parseInt(total); // don't forget the second param
                        var hours = Math.floor(sec_num / 3600);
                        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                        var seconds = sec_num - (hours * 3600) - (minutes * 60);

                        if (hours < 10) { hours = "0" + hours; }
                        if (minutes < 10) { minutes = "0" + minutes; }
                        if (seconds < 10) { seconds = "0" + seconds; }

                        return hours + ':' + minutes + ':' + seconds;

        },
        unixtodate: function(date) {
            date = parseInt(date);
            let dateTime = new Date(date)
            console.log(dateTime);
            return Moment(dateTime).format('MM/DD/YYYY HH:mm:ss');
        },

        moment: function(date) {
            return Moment(date).format('MM/DD/YYYY hh:mm');
        }
    },
    computed: {
        filteredUsers: function() {
            return this.users.filter((event) => {
                return event;
                if (event.user.name.match(this.search) || event.user.email.match(this.search)) {
                    return event;
                }
            });
        }
    },
    beforeMount() {
        var id = this.$route.params.id;
        this.trailID = id;
        this.getList()
    },
    methods: {
        updateUser(id) {
            this.userId = id;
            this.$bvModal.show('newUserModal');
        },
        getList() {
            var trail = this.$route.params.trail;
            var property = this.$route.params.property;
            userApi.getTrailUser(this, {
                page: this.page,
                pageSize: this.pageSize,
                trail_id: trail,
                property_id: property
            }, (context) => {})
        },
        updatePageSize(val) {
            this.pageSize = parseInt(val);
        },
        clearId() {
            this.userId = null;
            this.getList();
        },
        showCreateSuccessAlert() {
            this.alertCreated = true;
            this.alertUpdated = false;
        },
        showUpdateSuccessAlert() {
            this.alertCreated = false;
            this.alertUpdated = true;
        },
        stripLongText(text) {
            if (text.length > 100) {
                return text.substring(0, 100) + '...';
            } else {
                return text;
            }
        },
        deleteUser(id, name) {
            let approve = confirm(`Do you want to remove this user?`);
            if (approve !== false) {
                userApi.removeUser(this, id, (context) => {
                    this.getList();
                    this.alertCreated = false;
                    this.alertUpdated = false;
                });
            }
        },
        exportUser() {
            console.log('export user');
            this.fetchData();
        },
        async fetchData() {
            const { data } = await userApi.getUserExport(this, this.dateRange);
            data.map(item => {
                item.created_at = Moment(item.created_at).format('MMMM Do YYYY');
                return item;
            });

            return data;
        },
    }
}
</script>
<style>
.daterangepicker.dropdown-menu {
    min-width: 700px !important;
}
</style>