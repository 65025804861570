<template>
  <!--begin::Modal-->
  <b-modal
    size="lg"
    id="newTrailModal"
    v-model="showModal"
    :title="trailId ? 'Update trail' : 'Add new trail'"
    @hidden="close"
  >
    <b-container>
      <loading
        :active.sync="isLoading"
        :z-index="99999"
        :can-cancel="false"
        :is-full-page="true"
      ></loading>
      <!--begin::Form-->
      <form class="m-form m-form--label-align-right" autocomplete="off">
        <div class="m-portlet__body">
          <div class="m-form__content">
            <div
              class="m-alert m-alert--icon alert alert-danger"
              role="alert"
              v-if="error"
              id="m_form_1_msg"
            >
              <div class="m-alert__icon">
                <i class="la la-warning"></i>
              </div>
              <div class="m-alert__text" v-html="message"></div>
              <div class="m-alert__close">
                <button
                  type="button"
                  class="close"
                  @click="error = false"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div class="m-form__section m-form__section--first">
            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Name </label>
              <div class="col-lg-9">
                <input
                  type="text"
                  title="trail-name"
                  required="required"
                  v-model="name"
                  class="form-control m-input"
                />
                <span class="m-form__help"> Please enter trail name </span>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Distance </label>
              <div class="col-lg-9">
                <input
                  type="number"
                  title="trail-distance"
                  required="required"
                  v-model="distance"
                  class="form-control m-input"
                />
                <span class="m-form__help">
                  Please enter trail distance (mi)
                </span>
              </div>
            </div>

            <category-select
              @selectCategory="updateCategory"
              v-bind:selectedCategory="currentCategory"
            />

            <property-select
              @selectProperty="updateProperty"
              v-bind:selectedProperty="currentProperty"
            />

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Description </label>
              <div class="col-lg-9">
                <textarea
                  class="form-control m-input"
                  v-model="description"
                  placeholder="..."
                  maxlength="250"
                ></textarea>
                <span class="m-form__help"
                  >Please enter trail description (max 250 characters)</span
                >
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Is active </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="isActive"
                      :value="isActive"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Highlight </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="highLight"
                      :value="highLight"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Open to Hunting </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="openHunt"
                      :value="openHunt"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Hunting message </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="isHuntingMessage"
                      :value="isHuntingMessage"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <terrain-select
              @selectTerrain="updateTerrain"
              v-bind:selectedTerrain="currentTerrain"
            />

            <activity-select
              @selectActivity="updateActivity"
              v-bind:selectedActivity="currentActivity"
            />

            <difficulty-select
              @selectDifficulty="updateDifficulty"
              v-bind:selectedDifficulty="currentDifficulId"
            />

            <div class="form-group m-form__group row hide">
              <label class="col-lg-2 col-form-label">
                Map Image upload (preferred size: 1200x900)
              </label>
              <div class="col-lg-6">
                <input
                  type="file"
                  id="map_vertical"
                  name="map_vertical"
                  v-on:change="handleFile('map_vertical')"
                />
              </div>
              <div v-if="mapVertical" class="col-lg-3">
                <img
                  width="40"
                  height="40"
                  alt="map"
                  v-bind:src="mapVertical"
                />
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label">
                Thumbnail (preferred size: 1200x900)
              </label>
              <div class="col-lg-6">
                <input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  v-on:change="handleFile('thumbnail')"
                />
              </div>
              <div v-if="thumbnail" class="col-lg-3">
                <img
                  width="40"
                  height="40"
                  alt="thumbnail"
                  v-bind:src="thumbnail"
                />
              </div>
            </div>

            <div class="form-group m-form__group row hide">
              <label class="col-lg-2 col-form-label">
                Gallery (preferred size: 1200x900)
              </label>
              <div class="col-lg-9">
                <vue-dropzone
                  v-model="gallery"
                  class="m-dropzone m-dropzone--primary"
                  ref="galleryDropzone"
                  id="galleryDropzone"
                  :options="dropOptions"
                  :useCustomSlot="true"
                  @vdropzone-complete="afterDropzoneComplete"
                  @vdropzone-removed-file="afterDropzoneRemove"
                >
                  <div class="m-dropzone__msg dz-message needsclick">
                    <h3 class="m-dropzone__msg-title">
                      Drop files here or click to upload.
                    </h3>
                    <span class="m-dropzone__msg-desc">
                      Only image files are allowed for upload
                    </span>
                  </div>
                </vue-dropzone>
                <span style="color: red" class="m-form__help"
                  >You have to re-upload all images if want to update
                  gallery!</span
                >
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Color </label>
              <div class="col-lg-9">
                <sketch-picker
                  type="text"
                  title="color-picker"
                  v-model="color"
                  class="form-control m-input"
                ></sketch-picker>
                <span class="m-form__help">
                  Please enter Color (HEX Format)
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Logo </label>
              <div class="col-lg-9">
                <vue-dropzone
                  v-model="logo"
                  class="m-dropzone m-dropzone--primary"
                  ref="logoDropzone"
                  id="logoDropzone"
                  :options="dropOptionsLogo"
                  :useCustomSlot="true"
                  @vdropzone-complete="afterDropzoneCompleteLogo"
                  @vdropzone-removed-file="afterDropzoneRemoveLogo"
                  @vdropzone-error="dropzoneError"
                >
                  <div class="m-dropzone__msg dz-message needsclick">
                    <h3 class="m-dropzone__msg-title">
                      Drop file here or click to upload.
                    </h3>
                    <span class="m-dropzone__msg-desc">
                      Only image files are allowed for upload
                    </span>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </b-container>
    <div slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="showModal = false"
      >
        Close
      </button>
      <button type="button" class="btn btn-brand" @click="modifyTrail">
        {{ trailId ? "Update" : "Create" }}
      </button>
    </div>
  </b-modal>
  <!--end::Modal-->
</template>

<script>
import vueDropzone from "vue2-dropzone";
import TrailApi from "../../api/trail";
import FileHelper from "../../service/file";
import PropertySelect from "../property/PropertySelect";

import ActivitySelect from "../activity/ActivitySelect";
import TerrainSelect from "../terrain/TerrainSelect";
import DifficultySelect from "../difficulty/DifficultySelect";
import Settings from "../../constant/settings";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import CategorySelect from "./CategorySelect";
import { Sketch } from "vue-color";

export default {
  components: {
    PropertySelect,
    ActivitySelect,
    TerrainSelect,
    DifficultySelect,
    vueDropzone,
    Loading,
    CategorySelect,
    "sketch-picker": Sketch,
  },
  name: "trail-modal",
  props: ["trailId"],
  data() {
    return {
      oldGallery: null,
      fullPage: true,
      isLoading: false,
      success: false,
      error: false,
      message: null,
      showModal: false,
      id: this.trailId,
      name: null,
      description: null,
      color: "#0F2A7A",
      logo: null,

      propertyId: null,
      currentProperty: null,
      distance: null,
      activityId: [],
      currentActivity: [],
      terrainId: [],
      currentTerrain: [],
      difficultyId: [],
      currentDifficulty: [],
      currentDifficulId: null,
      thumbnail: null,
      mapHorizontal: null,
      mapVertical: null,
      gallery: [],
      categoryId: [],
      currentCategory: [],

      fileHelper: FileHelper,
      dropOptions: {
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        url: Settings.imageDropzoneUrl,
        maxFilesize: 2, // MB
        maxFiles: 10,
        addRemoveLinks: true,
        acceptedFiles: "image/*",
      },
      isActive: false,
      openHunt: false,
      highLight: false,
      isHuntingMessage: false,
      dropOptionsLogo: {
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        url: Settings.imageDropzoneUrl,
        maxFilesize: 2, // MB
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "image/*",
      },
    };
  },
  watch: {
    trailId(val) {
      if (val) {
        TrailApi.getTrailDetail(this, val, (context) => {
          if (context.trail) {
            this.name = context.trail.name;
            this.description = context.trail.description;

            this.propertyId = context.trail.property_id;
            this.currentProperty = context.trail.property_id;
            this.distance = context.trail.distance;
            this.oldGallery = context.trail.gallery;
            this.isActive = !!context.trail.active;
            this.highLight = !!context.trail.highlight;
            this.openHunt = !!context.trail.open_hunt;
            //isHuntingMessage
            this.isHuntingMessage = !!context.trail.is_hunting_message;
            this.color = context.trail.color ? context.trail.color : "#0F2A7A";

            if (context.trail.logo) {
              let logoInfo = JSON.parse(context.trail.logo);
              let url = apiUrl + "/assets" + logoInfo.path;
              this.$refs.logoDropzone.manuallyAddFile(logoInfo, url);
            }
            this.logo = context.trail.logo;

            if (context.trail.category) {
              context.trail.category.forEach((val, key) => {
                this.categoryId.push(val.id);
              });
              this.currentCategory = context.categoryId;
            }

            if (context.trail.gallery) {
              let gallery = JSON.parse(context.trail.gallery);
              gallery.images.forEach((image) => {
                let url = apiUrl + "/assets" + image.path;
                this.$refs.galleryDropzone.manuallyAddFile(image, url);
              });
              this.gallery = [];
            }
            if (context.trail.thumbnail) {
              this.thumbnail =
                apiUrl + "/assets" + JSON.parse(context.trail.thumbnail).path;
            }

            if (context.trail.map_vertical) {
              this.mapVertical =
                apiUrl +
                "/assets" +
                JSON.parse(context.trail.map_vertical).path;
            }

            if (context.trail.terrain) {
              context.trail.terrain.forEach((val, key) => {
                console.log(val);
                this.terrainId.push(val.id);
              });
              this.currentTerrain = context.terrainId;
            }

            if (context.trail.activity) {
              context.trail.activity.forEach((val, key) => {
                this.activityId.push(val.id);
              });
              this.currentActivity = context.activityId;
            }

            if (context.trail.difficulty) {
              context.trail.difficulty.forEach((val, key) => {
                this.difficultyId.push(val.id);
                this.currentDifficulId = val.id;
              });
              this.currentDifficulty = context.difficultyId;
            }
          }
        });
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    modifyTrail() {
      this.isLoading = true;
      this.error = false;
      let status = this.isActive ? 1 : 0;
      let hunt = this.openHunt ? 1 : 0;
      let is_hunting_message = this.isHuntingMessage ? 1 : 0;
      let highlight = this.highLight ? 1 : 0;
      //isHuntingMessage
      let data = {
        name: this.name,
        description: this.description,
        active: status,
        highlight: highlight,

        property_id: this.propertyId,
        distance: this.distance,
        gallery: this.gallery,
        thumbnail: this.thumbnail,
        map_horizontal: this.mapHorizontal,
        map_vertical: this.mapVertical,
        activity_id: this.activityId,
        difficulty_id: this.difficultyId,
        terrain_id: this.terrainId,
        open_hunt: hunt,
        is_hunting_message: is_hunting_message,
        category_id: this.categoryId,
        color: this.color.hex ? this.color.hex : this.color,
        logo: this.logo,
      };

      if (this.trailId) {
        Object.keys(data).forEach((item, index) => {
          if (
            !data[item] ||
            (data[item] &&
              typeof data[item] === "string" &&
              data[item].includes("uploads"))
          ) {
            if (
              item !== "highlight" &&
              item !== "active" &&
              item !== "open_hunt" &&
              item !== "is_hunting_message"
            )
              delete data[item];
          }
        });
        data.logo = this.logo;
        data.oldGallery = this.oldGallery;
        TrailApi.updateTrail(this, this.trailId, data, (context) => {
          this.isLoading = false;
          if (context.success) {
            this.isLoading = false;
            this.close();
            this.$emit("show-update-success-alert");
          }
        });
      } else {
        TrailApi.createTrail(this, data, (context) => {
          this.isLoading = false;
          if (context.success) {
            this.isLoading = false;
            this.close();
            this.$emit("show-create-success-alert");
          }
        });
      }
    },
    resetData() {
      this.trailID = null;
      this.oldGallery = null;
      this.id = null;
      this.name = null;
      this.description = null;
      this.distance = null;
      this.mapVertical = null;
      this.mapHorizontal = null;
      this.thumbnail = null;
      this.gallery = [];
      this.highLight = false;
      this.propertyId = "";
      this.currentProperty = "";
      this.activityId = [];
      this.currentActivity = [];
      this.terrainId = [];
      this.currentTerrain = [];
      this.difficultyId = [];
      this.currentDifficulty = [];
      this.currentDifficulId = null;
      this.isActive = false;
      this.openHunt = false;
      this.isHuntingHessage = false;
      this.categoryId = [];
      this.currentCategory = [];
      this.color = "#0F2A7A";
      this.$refs.galleryDropzone.removeAllFiles();
      this.$refs.logoDropzone.removeAllFiles();

      document.getElementById("map_vertical").value = "";
      document.getElementById("thumbnail").value = "";
    },
    close() {
      //this.resetData();
      this.showModal = false;
      this.error = false;
      this.$emit("clear-id");
    },

    handleFile(id) {
      let file = document.querySelector("#" + id);
      if (typeof file.files[0] != "undefined") {
        this.fileHelper.getBase64(file.files[0], this, (context) => {
          if (context) {
            if (id === "thumbnail") {
              this.thumbnail = context.currentFile;
            } else if (id === "map_vertical") {
              this.mapVertical = context.currentFile;
              this.mapHorizontal = context.currentFile;
            } else {
              let file = document.querySelector("#" + id);
              file.value = null;
            }
          } else {
            let file = document.querySelector("#" + id);
            file.value = null;
          }
        });
      } else {
        this.photo[id] = null;
        this.currentFile = null;
      }
    },

    updateProperty(id) {
      this.propertyId = id;
    },
    updateActivity(id) {
      this.activityId = id;
    },
    updateTerrain(id) {
      this.terrainId = id;
    },
    updateDifficulty(id) {
      this.difficultyId = id;
    },
    afterDropzoneCompleteLogo(file) {
      if (typeof file !== "undefined") {
        this.logo = file.accepted ? file.dataURL : this.logo;
        if (
          typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true
        ) {
          this.logo = null;
        }
      } else {
        this.logo = null;
      }
    },
    afterDropzoneRemoveLogo(file) {
      if (
        this.logo === file.dataURL ||
        (typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true)
      ) {
        this.logo = null;
      }
    },

    afterDropzoneComplete(file) {
      if (this.gallery) {
        this.gallery.push(file.dataURL);
      } else {
        if (
          typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true
        ) {
        } else {
          if (typeof file !== "undefined") {
            this.gallery = [];
            this.gallery.push(file.dataURL);
          }
        }
      }
    },
    updateCategory(id) {
      this.categoryId = id;
    },
    afterDropzoneRemove(file) {
      if (
        typeof file.manuallyAdded !== "undefined" &&
        file.manuallyAdded === true
      ) {
        let removeFileName = file.name;
        if (this.oldGallery) {
          let oldGallery = JSON.parse(this.oldGallery).images;

          oldGallery.forEach((image, index) => {
            if (image.name === removeFileName) {
              oldGallery.splice(index, 1);
            }
          });
          if (oldGallery.length === 0) {
            this.oldGallery = null;
          } else {
            this.oldGallery = JSON.stringify({ images: oldGallery });
          }
        }
      } else {
        let index = this.gallery.indexOf(file.dataURL);
        if (index > -1) {
          this.gallery.splice(index, 1);
        }
      }
    },
    dropzoneError(file, message, xhr) {
      alert(message);
      this.$refs.logoDropzone.removeFile(file);
    },
  },
};
</script>

<style lang="css"></style>
