<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Dashboard
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                exact
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link exact :to="{ name: 'dashboard' }" class="m-nav__link"
                ><span class="m-nav__link-text">Dashboard</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m-portlet m-portlet--mobile p-4">
        <div class="row m-0">
          <div class="col-12">
            <figure>
              <div class="graphic">
                <h6>
                  A total of {{ dataDashboard.dashboard.total }} users have seen the Dashboard Tour
                </h6>
                <div class="row">
                  <div class="chart">
                    <span
                      class="block"
                      title="Finished"
                      :style="{
                        width: getPercent(
                          dataDashboard.dashboard.finished,
                          dataDashboard.dashboard.total
                        ),
                      }"
                    >
                      <span class="value">
                        {{ dataDashboard.dashboard.finished }} users finished the tour
                      </span>
                    </span>
                    <span
                      class="block"
                      title="Skipped"
                      :style="{
                        width: getPercent(
                          dataDashboard.dashboard.total -
                            dataDashboard.dashboard.finished,
                          dataDashboard.dashboard.total
                        ),
                      }"
                    >
                      <span class="value"
                        >{{
                          dataDashboard.dashboard.total -
                          dataDashboard.dashboard.finished
                        }}
                        users skipped the tour</span
                      >
                    </span>
                  </div>
                </div>
                <h6>
                  A total of {{ dataDashboard.trail.total }} users have seen the Trail Tour
                </h6>
                <div class="row">
                  <div class="chart">
                    <span
                      class="block"
                      title="Finished"
                      :style="{
                        width: getPercent(
                          dataDashboard.trail.finished,
                          dataDashboard.trail.total
                        ),
                      }"
                    >
                      <span class="value">
                        {{ dataDashboard.trail.finished }} users finished the tour
                      </span>
                    </span>
                    <span
                      class="block"
                      title="Skipped"
                      :style="{
                        width: getPercent(
                          dataDashboard.trail.total -
                            dataDashboard.trail.finished,
                          dataDashboard.trail.total
                        ),
                      }"
                    >
                      <span class="value"
                        >{{
                          dataDashboard.trail.total -
                          dataDashboard.trail.finished
                        }}
                        users skipped the tour</span
                      >
                    </span>
                  </div>
                </div>

                <h6>
                  A total of {{ dataDashboard.profile.total }} users have seen the Profile Tour
                </h6>
                <div class="row">
                  <div class="chart">
                    <span
                      class="block"
                      title="Finished"
                      :style="{
                        width: getPercent(
                          dataDashboard.profile.finished,
                          dataDashboard.profile.total
                        ),
                      }"
                    >
                      <span class="value">
                        {{ dataDashboard.profile.finished }} users finished the tour
                      </span>
                    </span>
                    <span
                      class="block"
                      title="Skipped"
                      :style="{
                        width: getPercent(
                          dataDashboard.profile.total -
                            dataDashboard.profile.finished,
                          dataDashboard.profile.total
                        ),
                      }"
                    >
                      <span class="value"
                        >{{
                          dataDashboard.profile.total -
                          dataDashboard.profile.finished
                        }}
                        users skipped the tour</span
                      >
                    </span>
                  </div>
                </div>

                <h6>
                  A total of {{ dataDashboard.signup.total }} users were registered after finishing the Tour
                </h6>
                <div class="row">
                  <div class="chart">
                    <span
                      class="block"
                      title="Dashboard"
                      :style="{
                        width: getPercent(
                          dataDashboard.signup.dashboard,
                          dataDashboard.signup.total
                        ),
                      }"
                    >
                      <span class="value">
                        Dashboard: {{ dataDashboard.signup.dashboard }}
                      </span>
                    </span>
                    <span
                      class="block"
                      title="Trail"
                      :style="{
                        width: getPercent(
                          dataDashboard.signup.trail,
                          dataDashboard.signup.total
                        ),
                      }"
                    >
                      <span class="value">
                        Trail: {{ dataDashboard.signup.trail }}
                      </span>
                    </span>
                    <span
                      class="block"
                      title="Profile"
                      :style="{
                        width: getPercent(
                          dataDashboard.signup.profile,
                          dataDashboard.signup.total
                        ),
                      }"
                    >
                      <span class="value">
                        Profile: {{ dataDashboard.signup.profile }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <keyword-table />
    <filter-table />
    <div class="m-content pt-0">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">User Registered</h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <bar-chart />
        </div>
      </div>
    </div>
    <!-- <div class="m-content pt-0">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">Trail List Tracking</h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <trail-list-tracking />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import TourApi from "../../api/tour";
import BarChart from "./BarChart";
import KeywordTable from "./KeywordTable";
import FilterTable from "./FilterTable";
import TrailListTracking from "./TrailListTracking";

export default {
  components: {
    BarChart,
    KeywordTable,
    FilterTable,
    TrailListTracking
  },
  name: "dashboard",
  data() {
    return {
      error: false,
      dataDashboard: {
        total: 0,
        dashboard: {
          total: 0,
          finished: 0,
        },
        trail: {
          total: 0,
          finished: 0,
        },
        profile: {
          total: 0,
          finished: 0,
        },
        signup: {
          dashboard: 0,
          trail: 0,
          profile: 0,
          total: 0
        },
        tour_profile: {
          total: 0,
          clicks: 0,
        },
      },
    };
  },
  
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    fetchData() {
      TourApi.getDashboard(this, (context) => {});
    },
    getPercent(value, total) {
      return total != 0
        ? parseFloat((value / total) * 100).toFixed(2) + "%"
        : 0;
    },
  },
};
</script>

<style scoped>
figure {
  margin: 0 auto;
  max-width: 1100px;
  position: relative;
}
.row {
  margin-bottom: 1.5em;
}
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  @keyframes expand {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
}
.chart {
  overflow: hidden;
  width: 0%;
  animation: expand 1.5s ease forwards;
}
.row + .row .chart {
  animation-delay: 0.2s;
}
.row + .row + .row .chart {
  animation-delay: 0.4s;
}
.block {
  display: block;
  height: 50px;
  color: #fff;
  font-size: 0.75em;
  float: left;
  background-color: #16a34a;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
}
.block:nth-of-type(2),
.legend li:nth-of-type(2):before {
  background-color: #edbf33;
}
.block:nth-of-type(3),
.legend li:nth-of-type(3):before {
  background-color: #dc2626;
}
.block:hover {
  opacity: 0.8;
}
.value {
  display: block;
  text-align: center;
  line-height: 50px;
}
.x-axis {
  text-align: center;
  padding: 0.5em 0 2em;
}
.y-axis {
  height: 20px;
  transform: translate(-32px, 170px) rotate(270deg);
  position: absolute;
  left: 0;
}
.legend {
  margin: 0 auto;
  padding: 0;
  font-size: 0.9em;
}
.legend li {
  display: inline-block;
  padding: 0.25em 1em;
  line-height: 1em;
}
.legend li:before {
  content: "";
  margin-right: 0.5em;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #334d5c;
}
@media screen and (min-width: 768px) {
  .block {
    font-size: 1em;
  }
  .legend {
    width: 50%;
  }
}
.graphic h6 {
  margin-left: -15px;
}
.graphic h5 {
  color: #16a34a;
  margin-left: -15px;
}
</style>