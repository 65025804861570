import auth from '../authentication';
import Vue from '../main';

export default {
    getPark(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/park/get-all', data)
            .then(response => {
                context.error = false;
                context.parks = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getParkDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/park/get-detail/' + id)
            .then(response => {
                console.log(response);
                context.error = false;
                //context.message = response.data.message;
                context.park = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getParkSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/park/get-select')
            .then(response => {
                context.error = false;
                context.trails = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createPark(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/park/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.trail = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updatePark(context, id, data ,callback) {
        console.log(data);
        Vue.http.post(Vue.apiUrl + '/park/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.trail = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removePark(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/park/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};