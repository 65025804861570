<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Trail
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'trail' }" class="m-nav__link"><span class="m-nav__link-text">Trail</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
    
            </div>
        </div>
    
    
        <div class="m-content">
    
            <div class="kt-portlet kt-portlet--height-fluid">
                <div class="kt-portlet__head">
    
                    <div class="kt-portlet__head-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                            <li class="nav-item">
                                <router-link :to="{ name: 'listAll', params: { id: trailID }}" class="nav-link active">
                                    All
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listPoint', params: { id: trailID }}" class="nav-link ">
                                    Route
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listPoi', params: { id: trailID }}" class="nav-link ">
                                    POI in-route
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listNotRoute', params: { id: trailID }}" class="nav-link ">
                                    POI Not in Route
                                </router-link>
                            </li>

                             <li class="nav-item">
                                <router-link :to="{ name: 'listBus', params: { id: trailID }}" class="nav-link ">
                                    BUS
                                </router-link>
                            </li>


                        </ul>
                    </div>
                </div>
    
            </div>
    
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                All
                                <small>
                                                                                                            Map of All
                                                                                                        </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
    
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button> New trail created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button> Trail updated successfully
                    </div>
                    <div id="map"></div>
    
    
                </div>
    
    
    
    
            </div>
    
        </div>
    </div>
</template>

<style>
    #map {
        height: 500px;
        width: 100%;
    }
</style>
 

<script>
    import LocationApi from '../../api/location';
    
    
    export default {
    
    
        mounted: function() {},
        name: "location",
        data() {
            return {
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 10000,
                locationId: null,
                locations: [],
                poits: [],
                pois: [],
                notroutes: [],
                json: '',
                trailID: '',
                createdAlert: "New location created successfully",
                updatedAlert: "Location updated successfully"
            }
        },
        watch: {
    
    
        },
        beforeMount() {
            var id = this.$route.params.id;
            this.trailID = id;
            this.getList()
        },
        methods: {
    
    
            getList() {

               
                var trail = this.$route.params.id;
                LocationApi.getLocationByTrail(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    trail: trail,
                    type: 'poi'
                    
                }, (context) => {

                     mapboxgl.accessToken = 'pk.eyJ1IjoibWluaGRvODkiLCJhIjoiY2pvdmNpb3kwMWxraTNwcGludDk3amYycCJ9.SIaD_il5TcBB8YA9-vFV0Q';
                    var map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: [0, 0],
                        zoom: 15
                    });
                    
    
                     var poi_args = [];
                    var pois = context.locations;
                    if (pois.length > 0) {
                        pois.forEach(element => {
                            var point = [element.long, element.lat];
                            poi_args.push(point);
                        })
    
    
    
                    }
    
                    LocationApi.getLocationByTrail(this, {
                        page: this.page,
                        pageSize: this.pageSize,
                        trail: trail,
                        type: 'points'
                    }, (context) => {
                        var args = [];
                        var coords = [];
                        var coordinates = "coordinates=";
    
                        if (context.locations.length > 0) {
    
                            context.locations.forEach(function(element, k) {
                                if (k != context.locations.length - 1)
                                    coordinates = coordinates + element.long + '%2C' + element.lat + '%3B';
                                else {
                                    coordinates = coordinates + element.long + '%2C' + element.lat;
                                }
                                var point = [element.long, element.lat];
    
                                args.push(point);
    
                            })
    
                            map.flyTo({
                                
                                center: args[0]
                            });
                           
    
    
    
    
                            map.on('load', function() {

                             args.forEach(function(k, v) {
                              
                                  
    
                                    new mapboxgl.Marker()
                                        .setLngLat(k)
                                        .addTo(map);
                                
                            });


                          

                                 map.addLayer({
                                "id": "route",
                                "type": "line",
                                "source": {
                                    "type": "geojson",
                                    "data": {
                                        "type": "Feature",
                                        "properties": {},
                                        "geometry": {
                                            "type": "LineString",
                                            "coordinates": args
                                        }
                                    }
                                },
                                "layout": {
                                    "line-join": "round",
                                    "line-cap": "round"
                                },
                                "paint": {
                                    "line-color": "#888",
                                    "line-width": 5
                                }
                            });
    
    
                           
                         });
    
    
    
    
    
                        }
    
                        // if (context.locations.length > 0) {
                        //     context.locations.forEach(element => {
                        //         var point = [element.long, element.lat];
                        //         args.push(point);
                        //     })
    
                        //     map.flyTo({
                        //         center: args[0]
                        //     });
    
                        //     var directions = new MapboxDirections({
                        //         accessToken: mapboxgl.accessToken,
                        //         unit: 'metric',
                        //         profile: 'mapbox/walking'
                        //     });
    
                        //     map.on('load', function() {
                        //         directions.setOrigin(args[0]);
                        //         args.forEach(function(k, v) {
                        //             if ((v != 0) && (v != (args.length - 1))) {
                        //                 directions.addWaypoint((v - 1), k);
    
                        //                 new mapboxgl.Marker()
                        //                     .setLngLat(k)
                        //                     .addTo(map);
                        //             }
                        //         });
                        //         directions.setDestination(args[args.length - 1]);
    
                        //         map.addControl(directions);
    
                        //     });
    
                           
    
                        
    
    
    
                    })
    
                    LocationApi.getLocationByTrail(this, {
                        page: this.page,
                        pageSize: this.pageSize,
                        trail: trail,
                        type: 'not_route'
                    }, (context) => {
    
    
                        var args = [];
                        if (context.locations.length > 0) {
    
                            context.locations.forEach(element => {
    
                                var point = [element.long, element.lat];
    
                                args.push(point);
    
                            })
                           map.on('load', function() {
                            args.forEach(function(k, v) {
                                var _data = context.locations[v];
                                if (_data.icon) {
                                     let iconInfo = JSON.parse(_data.icon);
                                var previewIcon = apiUrl + '/assets' + iconInfo.path;
                                    var el = document.createElement('div');
                                    el.className = 'marker';
                                    el.style.backgroundImage = 'url(' + previewIcon + ')';
                                    el.style.width = "30px";
                                    el.style.height = "30px";
                                    el.style.backgroundSize = "contain";
                                    new mapboxgl.Marker(el)
                                        .setLngLat(k)
                                        .addTo(map);
                                } else {
                                    new mapboxgl.Marker({color: 'red'})
                                        .setLngLat(k)
                                        .addTo(map);
                                }
                            });
                        });
    
                        }
    
                    });



 LocationApi.getLocationByTrail(this, {
                        page: this.page,
                        pageSize: this.pageSize,
                        trail: trail,
                        type: 'bus'
                    }, (context) => {
    
    
                        var args = [];
                        if (context.locations.length > 0) {
    
                            context.locations.forEach(element => {
    
                                var point = [element.long, element.lat];
    
                                args.push(point);
    
                            })
                           map.on('load', function() {
                            args.forEach(function(k, v) {
                                var _data = context.locations[v];
                                if (_data.icon) {
                                     let iconInfo = JSON.parse(_data.icon);
                                var previewIcon = apiUrl + '/assets' + iconInfo.path;
                                    var el = document.createElement('div');
                                    el.className = 'marker';
                                    el.style.backgroundImage = 'url(' + previewIcon + ')';
                                    el.style.width = "30px";
                                    el.style.height = "30px";
                                    el.style.backgroundSize = "contain";
                                    new mapboxgl.Marker(el)
                                        .setLngLat(k)
                                        .addTo(map);
                                } else {
                                    new mapboxgl.Marker({color: 'yellow'})
                                        .setLngLat(k)
                                        .addTo(map);
                                }
                            });

                                map.addLayer({
                                "id": "route2",
                                "type": "line",
                                "source": {
                                    "type": "geojson",
                                    "data": {
                                        "type": "Feature",
                                        "properties": {},
                                        "geometry": {
                                            "type": "LineString",
                                            "coordinates": args
                                        }
                                    }
                                },
                                "layout": {
                                    "line-join": "round",
                                    "line-cap": "round"
                                },
                                "paint": {
                                    "line-color": "#FFFF00",
                                    "line-width": 5
                                }
                            });

                        });
    
                        }
    
                    });


                     map.on('load', function() {
    
                                poi_args.forEach(function(k, v) {
    
                                    var _data = pois[v];
                                    if (_data.icon) {
                                        let iconInfo = JSON.parse(_data.icon);
                                        var previewIcon = apiUrl + '/assets' + iconInfo.path;
                                        var el = document.createElement('div');
                                        el.className = 'marker';
                                        el.style.backgroundImage = 'url(' + previewIcon + ')';
                                        el.style.width = "30px";
                                        el.style.height = "30px";
                                        el.style.backgroundSize = "contain";
                                        new mapboxgl.Marker(el)
                                            .setLngLat(k)
                                            .addTo(map);
                                    } else {
                                        new mapboxgl.Marker({
                                                color: 'blue'
                                            })
                                            .setLngLat(k)
                                            .addTo(map);
                                    }
                                });
                            });
    
    
    
    
    
    
    
                });
    
    
    
            },
        }
    }
</script>
 