<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Android Version
        </label>
        <div class="col-lg-9">
            <select title="android-select" class="form-control" v-model="currentAndroid" v-if="versions.length > 0">
                <option value="">All Android Version</option>
                <option v-for="android in versions" :value="android.code">
                    {{ android.version }}
                </option>
            </select>
            <span class="m-form__help">Please choose Android Version</span>
        </div>
    </div>
</template>

<script>
    import versionApi from '../../api/version';

    export default {
        name: "android-select",
        data() {
            return {
                currentAndroid: null,
                page: 0,
                versions: []
            }
        },
        props: ['selectedAndroid'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedAndroid(val) {
                console.log(val);
                this.currentAndroid = val;
                this.$emit('selectAndroid', val);
            },
            currentAndroid(val) {
                this.$emit('selectAndroid', val);
            }
        },
        methods: {
            getList() {
                versionApi.getAndroidSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>