<template>
  <div>
    <div>
      <div class="row">
        <div class="col col-12 col-md-2">
          <select
            title="platform-select"
            class="form-control"
            v-model="search.type"
          >
            <option value="year">Year</option>
            <option value="month">Month</option>
            <option value="day">Day</option>
          </select>
        </div>
        <div class="col col-12 col-md-4 pt-3 pt-md-0">
          <date-range-picker ref="picker" v-model="search.dateRange">
          </date-range-picker>
        </div>
        <div class="col col-12 col-md-6 m--align-right pt-3 pt-md-0">
            <button
              class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
              @click="exportPdf"
            >
              Export PDF
            </button>
            <download-excel
              class=" btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill"
              :name="formatExportName"
              :fetch="fetchDataExcel"
              :fields="jsonFields"
              type="csv"
            >
              Export Excel
            </download-excel>
        </div>
      </div>
    </div>
    <div id="user-register-chart">
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Moment from "moment";
import UserReport from "../../api/user_reports";
import DownloadExcel from "vue-json-excel";
import { downloadBlobFile } from "../../service/download"
import html2canvas from 'html2canvas';

import {  
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
    DateRangePicker,
    DownloadExcel
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              footer: function(items) {
                let total = items.reduce((a, b) => a + b.parsed.y, 0)
                let user = items[0].parsed.y
                let percent = ((user / total) * 100)
                percent = Math.round(percent * 100) / 100 // format percent to fixed 2
                return `Total: ${total}\n${percent}% users registered`
              }
            }
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
      },
      search: {
        dateRange: {
          startDate: Moment().subtract(1, "months").toDate(),
          endDate: Moment().toDate(),
        },
        type: "day",
      },
      dataChart: {
        dataLabels: [],
        datasets: {
          user: [],
          guest: [],
          // total: [],
        },
      },
      jsonFields: {
        'Date': 'date',
        'User': 'user',
        'Guest': 'guest',
        'Total': 'total',
        '% User Registered': 'percent'
      },
    };
  },
  watch: {
    search: {
      handler(newValue, oldValue) {
        this.getDataChart();
      },
      deep: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.dataChart.dataLabels,
        datasets: [
          {
            label: "User",
            backgroundColor: "#117A65",
            data: this.dataChart.datasets.user,
          },
          {
            label: "Guest",
            backgroundColor: "#2ECC71",
            data: this.dataChart.datasets.guest,
          },
          // {
          //   label: "Total",
          //   backgroundColor: "#2874A6",
          //   data: this.dataChart.datasets.total,
          // },
        ],
      };
    },
    formatExportName() {
      let start = Moment(this.search.dateRange.startDate).format('YYYY-MM-DD');
      let end = Moment(this.search.dateRange.endDate).format('YYYY-MM-DD');
      return `user_registered_${this.search.type}_${start}_${end}.xls`
    },
  },
  created() {
    this.getDataChart();
  },
  methods: {
    getDataChart() {
      UserReport.getDashboardChart(
        this,
        {
          type: this.search.type,
          from: this.search.dateRange.startDate,
          to: this.search.dateRange.endDate,
        },
        (context) => {
          console.log(this.dataChart);
        }
      );
    },
    fetchDataExcel() {
      let excelData = []
      this.dataChart.datasets.user.map((_item, index) => {
        let user = this.dataChart.datasets.user[index]
        let guest = this.dataChart.datasets.guest[index]
        let total = user + guest
        let percent = ((user / total) * 100)
        percent = Math.round(percent * 100) / 100 // format percent to fixed 2
        excelData.push({
          date: this.dataChart.dataLabels[index],
          user: user,
          guest: guest,
          total: total,
          percent: `${percent}%`
        })
      })
      return excelData
    },
    async exportPdf () {
      let self = this
      html2canvas(document.getElementById('user-register-chart')).then(function(canvas) {
        let image = canvas.toDataURL('image/png'); 
        UserReport.getExportPdf({
          type: self.search.type,
          from: self.search.dateRange.startDate,
          to: self.search.dateRange.endDate,
          image
        })
        .then((response) => {
          const fileName = `users-${Date.now()}.pdf`;
          downloadBlobFile(response, fileName);
        });
      });
    }
  },
};
</script>

<style>
.vue-daterange-picker {
  width: 100%;
}
.vue-daterange-picker .reportrange-text {
  padding: 9px 10px;
}
</style>