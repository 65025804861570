const extensionList = ['jpg', 'png', 'jpeg', 'gif','svg','mp3'];

export default {
    getBase64: (file, context, callback) => {
        let extension = file.name.split('.').pop().toLowerCase();  //file extension from input file
        let isSuccess = extensionList.indexOf(extension) > -1;

        if (isSuccess) {
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                context.currentFile = reader.result;
                return callback(context);
            };

            reader.onerror = (error) => {
                callback(false);
            };
        } else {
            return callback(false);
        }
    },
    cropHorizontal: () => {

    }
}