import Vue from './main';

export default {
    user: {
        authenticated: false,
        profile: null
    },
    signIn(context, email, password, callback) {
        Vue.http.post(
            Vue.apiUrl + '/auth/sign-in', {
                email: email,
                password: password
            }
        ).then(response => {
            if (response.data) {


                if(response.data.data.role == 1 || response.data.data.role == 5){
                    Vue.Auth.logged = true;
                    Vue.Auth.type = response.data.data.role;
                    Vue.Auth.id = response.data.data.id;
                    Vue.Auth.email = response.data.data.email;

                    this.user.authenticated = true;
                    this.user.profile = response.data.data;

                    Vue.http.interceptors.push((request, next) => {
                        request.headers.set('Authorization', 'Bearer ' + this.user.profile.token);
                    request.headers.set('Accept', 'application/json');
                    next()
                });

                    localStorage.setItem('token', response.data.data.token);
                    context.error = false;
                    context.message = null;
                    callback(context);

                }else{
                    context.error = true;
                    context.message = "You don't have permission to login!"
                    callback(context);
                }


            }
        }).catch(response => {
            context.error = true;
            context.message = response.data.message;
            callback(context);
        })
    },
    checkApi(context, callback) {
        Vue.http.post(
            Vue.apiUrl + '/auth/checkApi'
        ).then(response => {
            if (response.data.data) {
                this.user.authenticated = true;
                this.user.profile = response.data.data;
                context.user = this.user;
                callback(context);
            } else {
                callback(false);
            }
        }).catch(response => {
            callback(false);
        })
    },
    check(context, callback) {
        let token = localStorage.getItem('token');
        if (token !== null) {
            Vue.http.post(
                Vue.apiUrl + '/auth/check', {
                    token: token
                }
            ).then(response => {
                if (response.data) {
                    Vue.Auth.logged = true;
                    Vue.Auth.type = response.data.data.role;
                    Vue.Auth.id = response.data.data.id;
                    Vue.Auth.email = response.data.data.email;
                    this.user.authenticated = true;
                    this.user.profile = response.data.data;
                    callback(context);
                }
            }).catch(response => {
                context.message = response.data.message;
                callback(context);
            })
        } else {
            callback(false);
        }
    },
    signOut() {
        localStorage.removeItem('token');
        Vue.Auth.logged = false;
        this.user.authenticated = false;
        this.user.profile = null;

        Vue.router.push({
            name: 'login'
        })
    },
    updatePassword(context, id, password, callback) {
        Vue.http.post(
            Vue.apiUrl + '/auth/update-password', {
                id: id,
                password: password
            }
        ).then(response => {
            if (response.data) {
                console.log(response.data);
                context.message = 'success';
                callback(context);
            }
        }).catch(response => {
            context.error = true;
            context.message = response.data.message;
            callback(context);
        })
    },
};