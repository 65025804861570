<template>
    <!--begin::Modal-->

    <b-modal size="lg" id="newLocationModal" v-model="showModal"
             :title="routeId ? 'Update location' : 'Add new location'"
             @hidden="close">
        <b-container>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter name </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Information
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="information"
                                          placeholder="..." maxlength="250"></textarea>
                                <span class="m-form__help">Please enter information (max 250 characters)</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Lat
                            </label>
                            <div class="col-lg-9">
                                <input type="number" step="any" class="form-control m-input" v-model="lat"/>
                                <span class="m-form__help">Please enter lat</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Long
                            </label>
                            <div class="col-lg-9">
                                <input type="number" step="any" class="form-control m-input" v-model="long"/>
                                <span class="m-form__help">Please enter long</span>
                            </div>
                        </div>




                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyLocation">{{ routeId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import LocationApi from '../../api/route';
    import vueDropzone from 'vue2-dropzone'
    import Settings from '../../constant/settings';
    import TrailSelect from '../trail/TrailSelect';
    import FileHelper from '../../service/file';
    import PropertyPreview from '../property/PropertyPreview';

    export default {
        components: {
            TrailSelect,
            vueDropzone,
            PropertyPreview
        },
        name: "location-modal",
        props: ['routeId'],
        data() {
            return {
                oldGallery: null,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.routeId,
                name: null,
                information: null,
                lat: null,
                long: null,
                order: null,
                trailId: null,
                currentTrail: null,
                gallery: null,
                updateGallery: [],
                icon: null,
                sound: null,
                previewIcon: null,
                fileHelper: FileHelper,
                notInRoute: false,
                isPOI: false,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 10,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*",
                }
            }
        },
        watch: {
            routeId(val) {
                if (val) {
                    LocationApi.getLocationDetail(this, val, (context) => {
                        if (context.location) {
                            this.name = context.location.name;
                            this.information = context.location.information;
                            this.lat = context.location.lat;
                            this.long = context.location.long;
                            this.trailId = context.location.trail_id;
                            this.currentTrail = context.location.trail_id;
                            this.distance = context.location.distance;
                            //this.gallery = context.location.gallery;
                            this.order = context.location.order;
                            this.icon = context.location.icon;
                            this.sound = context.location.sound;
                            this.isPOI = !!+context.location.is_poi;
                            this.notInRoute = !!+context.location.not_in_route;
                            this.oldGallery = context.location.gallery;
                            if (context.location.icon) {
                                this.icon = apiUrl + '/assets' + JSON.parse(context.location.icon).path;
                            }

                            if (context.location.sound) {
                                this.sound = apiUrl + '/assets' + JSON.parse(context.location.sound).path;
                            }

                            if (context.location.gallery) {
                                let gallery = JSON.parse(context.location.gallery);
                                gallery.images.forEach((image) => {
                                    let url = apiUrl + '/assets' + image.path;
                                    this.$refs.locationGalleryDropzone.manuallyAddFile(image, url);
                                });
                            }
                        }
                    })
                }
            },
        },
        methods: {
            modifyLocation() {
                this.error = false;
                let data = {
                    name: this.name,
                    information: this.information,
                    lat: this.lat,
                    long: this.long,
                    order: this.order,
                    trail_id: this.trailId,
                    gallery: this.gallery,
                    icon: this.icon,
                    sound: this.sound,
                    is_poi: this.isPOI,
                    not_in_route: this.notInRoute
                };

                if (this.routeId) {
                    Object.keys(data).forEach(item => {
                        console.log(item);
                        if (!data[item] || (data[item] && typeof (data[item]) === 'string' && data[item].includes('uploads'))) {
                            delete (data[item]);
                        }
                    });

                    data.oldGallery = this.oldGallery;

                    LocationApi.updateLocation(this, this.routeId, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    LocationApi.createLocation(this, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.oldGallery = null;
                this.id = null;
                this.name = null;
                this.gallery = null;
                this.lat = null;
                this.long = null;
                this.order = null;
                this.trailId = null;
                this.information = null;
                this.currentTrail = null;
                this.icon = null;
                this.sound = null;
                this.notInRoute = false;
                this.isPOI = false;

            },
            close() {
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            afterDropzoneComplete(file) {
                if (this.gallery) {

                    this.gallery.push(file.dataURL);
                } else {

                    if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {

                    } else {
                        if (typeof file !== 'undefined') {
                            this.gallery = [];
                            this.gallery.push(file.dataURL);
                        }
                    }
                }
            },
            updateTrail(id) {
                this.trailId = id;
            },

            handleFile(id) {

                let file = document.querySelector('#' + id);

                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {

                        if (context) {
                            if (id === 'icon') {
                                this.icon = context.currentFile;

                            }
                            if(id === 'sound'){
                                this.sound = context.currentFile;
                            }
                            if (id !== 'icon' && id !== 'sound')  {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        }
                    });
                } else {
                    this.currentFile = null;
                }
            },
            afterDropzoneRemove(file) {
                if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    let removeFileName = file.name;
                    if (this.oldGallery) {
                        let oldGallery = JSON.parse(this.oldGallery).images;

                        oldGallery.forEach((image, index) => {
                            if (image.name === removeFileName) {
                                oldGallery.splice(index, 1);
                            }
                        });
                        if (oldGallery.length === 0) {
                            this.oldGallery = null;
                        } else {
                            this.oldGallery = JSON.stringify({'images': oldGallery});
                        }
                    }
                } else {
                    let index = this.gallery.indexOf(file.dataURL);
                    if (index > -1) {
                        this.gallery.splice(index, 1);
                    }
                }
            },
        }
    }
</script>