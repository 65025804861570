<template>
  <!--begin::Modal-->
  <b-modal
    size="lg"
    id="newPropertyModal"
    v-model="showModal"
    :title="propertyId ? 'Update property' : 'Add new property'"
    @hidden="close"
  >
    <b-container>
      <loading
        :active.sync="isLoading"
        :z-index="99999"
        :can-cancel="false"
        :is-full-page="true"
      ></loading>
      <!--begin::Form-->
      <form class="m-form m-form--label-align-right">
        <div class="m-portlet__body">
          <div class="m-form__content">
            <div
              class="m-alert m-alert--icon alert alert-danger"
              role="alert"
              v-if="error"
              id="m_form_1_msg"
            >
              <div class="m-alert__icon">
                <i class="la la-warning"></i>
              </div>
              <div class="m-alert__text" v-html="message"></div>
              <div class="m-alert__close">
                <button
                  type="button"
                  class="close"
                  @click="error = false"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div class="m-form__section m-form__section--first">
            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Name </label>
              <div class="col-lg-9">
                <input
                  type="text"
                  required="required"
                  v-model="name"
                  class="form-control m-input"
                />
                <span class="m-form__help"> Please enter property name </span>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Description </label>
              <div class="col-lg-9">
                <textarea
                  class="form-control m-input"
                  v-model="description"
                  placeholder="..."
                ></textarea>
                <span class="m-form__help"
                  >Please enter property description
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Area </label>
              <div class="col-lg-9">
                <input
                  type="text"
                  required="required"
                  v-model="area"
                  class="form-control m-input"
                />
                <span class="m-form__help"> Please enter property area </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Open to Hunting </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="openHunt"
                      :value="openHunt"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Hunting message </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="isHuntingMessage"
                      :value="isHuntingMessage"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Poor Connectivity </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="showText"
                      :value="showText"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Is active </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch&#45;&#45;outline m-switch&#45;&#45;icon m-switch&#45;&#45;brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="isActive"
                      :value="isActive"
                    />
                    <span></span>
                  </label>
                </span> 
              </div> 
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Highlight </label>
              <div class="col-lg-9">
                <span
                  class="m-switch m-switch--outline m-switch--icon m-switch--brand"
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="highLight"
                      :value="highLight"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Color </label>
              <div class="col-lg-9">
                <sketch-picker
                  type="text"
                  title="color-picker"
                  v-model="color"
                  class="form-control m-input"
                ></sketch-picker>
                <span class="m-form__help">
                  Please enter Color (HEX Format)
                </span>
              </div>
            </div>

            <category-select
              @selectCategory="updateCategory"
              v-bind:selectedCategory="currentCategory"
            />

            <organization-multi-select
              @selectOrganization="updateOrganization"
              v-bind:selectedOrganization="currentOrganization"
            />

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Order Position </label>
              <div class="col-lg-9">
                <input
                  type="text"
                  required="required"
                  v-model="or"
                  class="form-control m-input"
                />
                <span class="m-form__help"> Please enter Order Position </span>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label">
                Thumbnail (preferred size: 1200x900)
              </label>
              <div class="col-lg-9">
                <vue-dropzone
                  v-model="thumbnail"
                  class="m-dropzone m-dropzone--primary"
                  ref="propertyDropzone"
                  id="propertyDropzone"
                  :options="dropOptions"
                  :useCustomSlot="true"
                  @vdropzone-complete="afterDropzoneComplete"
                  @vdropzone-removed-file="afterDropzoneRemove"
                  @vdropzone-error="dropzoneError"
                >
                  <div class="m-dropzone__msg dz-message needsclick">
                    <h3 class="m-dropzone__msg-title">
                      Drop file here or click to upload.
                    </h3>
                    <span class="m-dropzone__msg-desc">
                      Only image files are allowed for upload
                    </span>
                  </div>
                </vue-dropzone>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-2 col-form-label"> Logo </label>
              <div class="col-lg-9">
                <vue-dropzone
                  v-model="logo"
                  class="m-dropzone m-dropzone--primary"
                  ref="propertyLogoDropzone"
                  id="propertyLogoDropzone"
                  :options="dropOptions"
                  :useCustomSlot="true"
                  @vdropzone-complete="afterDropzoneLogoComplete"
                  @vdropzone-removed-file="afterDropzoneLogoRemove"
                  @vdropzone-error="dropzoneLogoError"
                >
                  <div class="m-dropzone__msg dz-message needsclick">
                    <h3 class="m-dropzone__msg-title">
                      Drop file here or click to upload.
                    </h3>
                    <span class="m-dropzone__msg-desc">
                      Only image files are allowed for upload
                    </span>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </b-container>
    <div slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click="close">
        Close
      </button>
      <button type="button" class="btn btn-brand" @click="modifyProperty">
        {{ propertyId ? "Update" : "Create" }}
      </button>
    </div>
  </b-modal>
  <!--end::Modal-->
</template>

<script>
import propertyApi from "../../api/property";
import vueDropzone from "vue2-dropzone";
import CategorySelect from "../category/CategorySelect";
import Settings from "../../constant/settings";
import OrganizationMultiSelect from "../organization/OrganizationMultiSelect";
import auth from "../../authentication";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { Sketch } from "vue-color";

export default {
  components: {
    Loading,
    vueDropzone,
    CategorySelect,
    OrganizationMultiSelect,
    "sketch-picker": Sketch,
    OrganizationMultiSelect,
  },
  name: "new-property",
  props: ["propertyId"],
  data() {
    return {
      fullPage: true,
      isLoading: false,
      success: false,
      error: false,
      message: null,
      showModal: false,
      id: this.propertyId,
      name: null,
      area: null,
      description: null,
      categoryId: [],
      currentCategory: [],
      thumbnail: null,
      logo: null,
      or: null,
      color: "#FFF",
      openHunt: false,
      isHuntingMessage: false,
      showText: false,
      highLight: false,
      isActive: false,
      userId: [],
      currentOrganization: [],

      dropOptions: {
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        url: Settings.imageDropzoneUrl,
        maxFilesize: 2, // MB
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "image/*",
      },
    };
  },

  watch: {
    propertyId(val) {
      if (val) {
        propertyApi.getPropertyDetail(this, val, (context) => {
          if (context.property) {
            this.error = false;
            this.name = context.property.name;
            this.area = context.property.area;
            this.description = context.property.description;

            this.openHunt = !!context.property.open_hunt;
            this.isHuntingMessage = !!context.property.is_hunting_message;
            this.showText = !!context.property.show_text;
            this.highLight = !!context.property.highlight;
            this.isActive = !!context.property.active;
            if (context.property.user) {
              context.property.user.forEach((val, key) => {
                this.userId.push(val.id);
              });
              this.currentOrganization = context.userId;
            }

            this.or = context.property.or;
            this.color = context.property.color
              ? context.property.color
              : "#FFF";
            if (context.property.thumbnail) {
              let thumbnailInfo = JSON.parse(context.property.thumbnail);
              let url = apiUrl + "/assets" + thumbnailInfo.path;
              this.$refs.propertyDropzone.manuallyAddFile(thumbnailInfo, url);
            }
            this.thumbnail = context.property.thumbnail;
            if (context.property.category) {
              context.property.category.forEach((val, key) => {
                this.categoryId.push(val.id);
              });
              this.currentCategory = context.categoryId;
            }
            if (context.property.logo) {
              let logoInfo = JSON.parse(context.property.logo);
              let url = apiUrl + "/assets" + logoInfo.path;
              this.$refs.propertyLogoDropzone.manuallyAddFile(logoInfo, url);
            }
            this.logo = context.property.logo;
          }
        });
      }
    },
    showModal(val) {
      if (auth.user.profile.role == 5 && !this.propertyId) {
        this.userId.push(auth.user.profile.id);
        this.currentOrganization = this.userId;
      }
    },
  },
  methods: {
    modifyProperty() {
      this.isLoading = true;
      this.error = false;
      let hunt = this.openHunt ? 1 : 0;
      let is_hunting_message = this.isHuntingMessage ? 1 : 0;
      let show_text = this.showText ? 1 : 0;
      let highlight = this.highLight ? 1 : 0;
      let status = this.isActive ? 1 : 0;

      let data = {
        name: this.name,
        area: this.area,
        description: this.description,
        thumbnail: this.thumbnail,
        logo: this.logo,
        category_id: this.categoryId,
        or: this.or,
        color: this.color.hex,
        open_hunt: hunt,
        is_hunting_message: is_hunting_message,
        show_text: show_text,
        highlight: highlight,
        active: status,
        user_id: this.userId,
      };
      if (this.propertyId) {
        propertyApi.updateProperty(this, this.propertyId, data, (context) => {
          this.isLoading = false;
          if (context.success) {
            this.close();
            this.$emit("show-update-success-alert");
          }
        });
      } else {
        propertyApi.createProperty(this, data, (context) => {
          this.isLoading = false;
          if (context.success) {
            this.close();
            this.$emit("show-create-success-alert");
          }
        });
      }
    },
    resetData() {
      this.message = null;
      this.id = null;
      this.name = null;
      this.area = null;
      this.color = "#FFF";
      this.description = null;
      this.thumbnail = null;
      this.logo = null;
      this.$refs.propertyDropzone.removeAllFiles();
      this.$refs.propertyLogoDropzone.removeAllFiles();
      this.categoryId = [];
      this.currentCategory = [];
      this.or = null;
      this.openHunt = false;
      this.isHuntingMessage = false;
      this.showText = false;
      this.highLight = false;
      this.userId = [];
    },
    close() {
      this.resetData();
      this.showModal = false;
      this.error = false;
      this.$emit("clear-id");
    },
    afterDropzoneComplete(file) {
      if (typeof file !== "undefined") {
        this.thumbnail = file.dataURL;
        if (
          typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true
        ) {
          this.thumbnail = null;
        }
      } else {
        this.thumbnail = null;
      }
    },
    afterDropzoneRemove(file) {
      if (
        this.thumbnail === file.dataURL ||
        (typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true)
      ) {
        this.thumbnail = null;
      }
    },

    dropzoneError(file, message, xhr) {
      alert(message);
      this.$refs.propertyDropzone.removeFile(file)
    },

    dropzoneLogoError(file, message, xhr) {
      alert(message);
      this.$refs.propertyLogoDropzone.removeFile(file)
    },

    afterDropzoneLogoComplete(file) {
      if (typeof file !== "undefined") {
        this.logo = file.dataURL;
        if (
          typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true
        ) {
          this.logo = null;
        }
      } else {
        this.logo = null;
      }
    },
    afterDropzoneLogoRemove(file) {
      if (
        this.logo === file.dataURL ||
        (typeof file.manuallyAdded !== "undefined" &&
          file.manuallyAdded === true)
      ) {
        this.logo = null;
      }
    },
    updateCategory(id) {
      this.categoryId = id;
    },
    updateOrganization(id) {
      this.userId = id;
    },
  },
};
</script>

<style scoped></style>
