import Vue from '../main';

export default {
    //Tour Dashboard
    getDashboard(context, callback) {
        Vue.http.get(Vue.apiUrl + '/tour/dashboard')
            .then(response => {
                context.error = false;
                context.dataDashboard = response.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    }
}