<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Property
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'property' }" class="m-nav__link"><span class="m-nav__link-text">Property</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
    
            </div>
        </div>
    
    
    

    
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Property Area Point
                                <small>
                                                                                                                Map of all points
                                                                                                            </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">

                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button> New Property created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button> Property updated successfully
                    </div>
                    <div id="map"></div>
    
                    <b-container>
                        <!--begin::Form-->
                        <form class="m-form m-form--label-align-right">
                            <div class="m-portlet__body">
                                <div class="m-form__content">
                                    <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error" id="m_form_1_msg">
                                        <div class="m-alert__icon">
                                            <i class="la la-warning"></i>
                                        </div>
                                        <div class="m-alert__text" v-html="message">
                                        </div>
    
                                    </div>
                                </div>
                                <div class="m-form__section m-form__section--first">
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">
                                            Input geojson
                                                                                                </label>
                                        <div class="col-lg-9">
                                            <textarea class="form-control m-input" v-model="json" placeholder="..."></textarea>
                                            <input type="hidden" title="about-sub_title" required="required" v-model="propertyID" class="form-control m-input">
                                            <span class="m-form__help">-Please input geojson taken from page http://geojson.io/ as this following example:<br/>
"coordinates": [<br/>
          [<br/>
            106.69441223144531,<br/>
            10.77367815586651<br/>
          ],<br/>
          [<br/>
            106.69617176055907,<br/>
            10.774858602342633<br/>
          ],<br/>
          [<br/>
            106.69310331344604,<br/>
            10.7773881149201<br/>
          ]<br/>
        ]<br/>
-Must copy exactly the same structure as our sample data<br/>
-Each time submitting a new geojson, all the new points will added to the end of the current list<br/>
-For the purpose of correct display, please make sure all POIs' longitude and latitude are in the intended property with points<br/>
-To see the the overview map, admin need to insert at least all the points and POI or either POI not-in-route to active the overview map</span>
    

                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </form>
    
                        <div class="col-xl-12 order-1 order-xl-2 m--align-right">
    
                            <button type="button" class="btn btn-brand " @click="modifyPoint">{{ 'Add new Point(s) Area'}}
                                                                                
                                                                                                            </button>
                        </div>
    
    
    
                        <!--end::Form-->
                    </b-container>
    
    
                </div>
                <div class="table-responsive">
                <table class="table table-inverse" v-if="locations.length > 0">
                    <thead>
                        <tr>
                            <th>#</th>
    
    
    
                            <th>Coordinates</th>
    
                            <th>Property</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="location,index in this.locations">
                            <td>{{index + 1}}</td>
    
                            <td>lat: {{ location.lat}} <br> long: {{ location.long }}
                            </td>
    
                            <td>{{ location.property.name }}</td>
                            <td>
                                <span class="m-badge m-badge--wide {activeClass}" v-bind:class="[ location.active ? 'm-badge--success' : 'm-badge--danger' ]">{{ location.active ? "Active" : "Inactive" }}
                                                                                </span>
                            </td>
                            <td style="width: 100px;">
                                <!-- <button title="Edit details" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" v-b-modal="'newLocationModal'" @click="updateLocation(location.id)">
                                                                                    <i class="la la-edit"></i>
                                                                                </button> -->
                                <button title="Delete" @click="deleteLocation(location.id, location.name)" class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                                                                    <i class="la la-trash"></i>
                                                                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total" v-model="page" :per-page="pageSize">
                </b-pagination>
            </div>
    
            <location-modal @clear-id="clearId" @clicked-reload="getList" @show-create-success-alert="showCreateSuccessAlert" @show-update-success-alert="showUpdateSuccessAlert" v-bind:locationId="locationId" />

            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
    
            <!-- <MglMap id="map-container" :accessToken="accessToken" :mapStyle="mapStyle" :center="coordinates" @load="onMapLoaded" /> -->
        </div>
    </div>
</template>

<style>
    #map {
        height: 500px;
        width: 100%;
    }
</style>
 

<script>
    import LocationApi from '../../api/area';
    import LocationModal from './PointModal';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            LocationModal,
            Loading
    
        },
    
        mounted: function() {},
        name: "listarea",
        data() {
            return {
                fullPage: true,
                isLoading: false,
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 1500,
                locationId: null,
                locations: [],
                json: '',
                points: [],
                propertyID: '',
                createdAlert: "New location created successfully",
                updatedAlert: "Location updated successfully"
            }
        },
        watch: {
    
    
        },
        beforeMount() {
    
            var id = this.$route.params.id;
            this.propertyID = id;
            this.getList()
        },
        methods: {
    
            updateLocation(id) {
                this.locationId = id;
            },
            deleteLocation(id, name) {
                let approve = confirm(`Do you want to remove Point of Area ?`);
    
                if (approve !== false) {
                    LocationApi.removeLocation(this, id, (context) => {
    
                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.locationId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },
    
            stripLongText(text) {
                if (text) {
                    if (text.length > 100) {
                        return text.substring(0, 100) + '...';
                    } else {
                        return text;
                    }
                }
                return '';
            },
            modifyPoint() {
                this.isLoading = true;
                this.error = false;
                var json = this.json.replace(/\s/g, '');
                if (!json){
                    this.isLoading = false                                                                                     ;
                    return false;
                }

                let propertyID = this.$route.params.id;
                let data = {
                    json: "{" + json + "}",
                    propertyID: propertyID,
                    type: 'points'
                };
                LocationApi.addPointsByProperty(this, data, (context) => {
                    this.isLoading = false;
                    if (context.success) {
                        this.close();
                        this.$emit('show-create-success-alert');
                        this.getList();
                    }
                })
    
            },
            resetData() {
                this.json = null;
    
    
            },
            close() {
    
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
    
            getList() {
                var property = this.$route.params.id;
                LocationApi.getLocationByProperty(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    property: property,

                }, (context) => {


                    mapboxgl.accessToken = 'pk.eyJ1IjoibWluaGRvODkiLCJhIjoiY2pvdmNpb3kwMWxraTNwcGludDk3amYycCJ9.SIaD_il5TcBB8YA9-vFV0Q';
                    var map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: [0, 0],
                        
                        zoom: 15
                    });
                    var args = [];
                    var coords = [];
                    var coordinates = "coordinates=";
                    if (context.locations && context.locations.length > 0) {
                        
    
                        context.locations.forEach(function(element, k) {
                            if (k != context.locations.length - 1)
                                coordinates = coordinates + element.long + '%2C' + element.lat + '%3B';
                            else {
                                coordinates = coordinates + element.long + '%2C' + element.lat;
                            }
                            var point = [element.long, element.lat];
    
                            args.push(point);
    
                        })
    
                        map.flyTo({

                            center: args[0]
                        });

    
    
    
                        map.on('load', function() {

                             args.forEach(function(k, v) {
                              
                                  
    
                                    new mapboxgl.Marker()
                                        .setLngLat(k)
                                        .addTo(map);
                                
                            });


                          

                                 map.addLayer({
                                "id": "route",
                                "type": "line",
                                "source": {
                                    "type": "geojson",
                                    "data": {
                                        "type": "Feature",
                                        "properties": {},
                                        "geometry": {
                                            "type": "LineString",
                                            "coordinates": args
                                        }
                                    }
                                },
                                "layout": {
                                    "line-join": "round",
                                    "line-cap": "round"
                                },
                                "paint": {
                                    "line-color": "#888",
                                    "line-width": 5
                                }
                            });

    
                           
                         });
    
    
    

    
                    }
    
    
    
                })
            },
        }
    }
</script>
 