<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Terrain
        </label>
        <div class="col-lg-9">
            <multi-select :multiple="true" track-by="name" label="name"
                          v-model="value" :options="terrains"></multi-select>
            <span class="m-form__help">Please choose category</span>
        </div>
    </div>
</template>

<script>
    import terrainApi from '../../api/terrain';
    import MultiSelect from 'vue-multiselect'

    export default {
        name: "terrain-select",
        components: {
            MultiSelect
        },
        data() {
            return {
                currentTerrain: [],
                page: 0,
                terrains: []
            }
        },
        props: ['selectedTerrain'],
        computed: {
            value: {
                get() {
                    return this.terrains.filter(
                        terrain => this.currentTerrain.includes(terrain.id)
                    )
                },
                set(newSelectedTerrains) {
                    this.currentTerrain = newSelectedTerrains.map(terrain => terrain.id)
                }
            }
        },
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedTerrain(val) {
                if (val) {
                    this.currentTerrain = val;
                    this.$emit('selectTerrain', val);
                }
            },
            currentTerrain(val) {
                this.$emit('selectTerrain', val);
            }
        },
        methods: {
            getList() {
                terrainApi.getTerrainSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>