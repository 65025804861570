<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Push custom notification
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'notification' }" class="m-nav__link"><span
                                    class="m-nav__link-text">Notification</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Content
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">

                            <li class="m-portlet__nav-item">
                                <button type="button" :disabled="sending" class="btn btn-brand" @click="pushNotify()">
                                    Send
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>
                <form class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__content">
                            <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                                 id="m_form_1_msg">
                                <div class="m-alert__icon">
                                    <i class="la la-warning"></i>
                                </div>
                                <div class="m-alert__text" v-html="message">
                                </div>
                                <div class="m-alert__close">
                                    <button type="button" class="close" @click="error=false"
                                            aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="title" class="col-lg-2 col-form-label">
                                    Title
                                </label>
                                <div class="col-lg-6">
                                    <input :disabled="sending" v-model="form.title" type="text" id="title"
                                           class="form-control m-input">
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="content" class="col-lg-2 col-form-label">
                                    Content
                                </label>
                                <div class="col-lg-6">
                                    <input :disabled="sending" v-model="form.content" type="text" id="content"
                                           class="form-control m-input">
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label for="url" class="col-lg-2 col-form-label">
                                    Url
                                </label>
                                <div class="col-lg-6">
                                    <input :disabled="sending" v-model="form.url" type="text" id="url"
                                           class="form-control m-input">
                                </div>
                            </div>
                        </div>
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <div class="col-lg-12">
                                    <user-select :disabled="sending"
                                                 @selectUser="updateUser"
                                                 v-bind:selectedUser="currentUser"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import UserSelect from './UserSelect'
    import userApi from '../../api/user';

    export default {
        components: {UserSelect},
        name: "Notification",
        data() {
            return {
                error: false,
                message: '',
                sending: false,
                currentUser: [],
                userId: [],
                form: {
                    title: '',
                    content: '',
                    url: ''
                }
            }
        },
        methods: {
            updateUser(id) {
                this.userId = id;
            },
            resetForm() {
                this.form = {
                    title: '',
                    content: '',
                    url: ''
                }
            },
            pushNotify() {
                this.sending = true;

                if (this.form.title === '') {
                    this.error = true;
                    this.message = 'Missing title';
                    this.sending = false;
                    return;
                }

                if (this.form.content === '') {
                    this.error = true;
                    this.message = 'Missing content';
                    this.sending = false;
                    return;
                }

                let data = {
                    users: this.userId,
                    data: this.form
                };
                userApi.notifyUser(this, data, (context) => {
                    if (context.success) {
                        this.$toasted.show('Notifications sent!', {
                            theme: "toasted-primary",
                            position: "top-right",
                            duration: 3000
                        });
                        this.sending = false;
                        this.resetForm();
                    } else {
                        this.sending = false;
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>