import auth from '../authentication';
import Vue from '../main';

export default {
    getTrail(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail/get-all', data)
            .then(response => {
                context.error = false;
                context.trails = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getTrailDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/trail/get-detail/' + id)
            .then(response => {
                console.log(response);
                context.error = false;
                //context.message = response.data.message;
                context.trail = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getTrailSelect(context, callback) {
        let data = {};
        if(auth.user.profile.role == 5) {
            data.user_id = auth.user.profile.id;
        }
        Vue.http.post(Vue.apiUrl + '/trail/get-select', data)
            .then(response => {
                context.error = false;
                context.trails = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createTrail(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/trail/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.trail = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateTrail(context, id, data ,callback) {
        console.log(data);
        Vue.http.post(Vue.apiUrl + '/trail/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.trail = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeTrail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/trail/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};