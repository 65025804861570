<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newInformationModal" v-model="showModal"
             :title="informationId ? 'Update event' : 'Add new event'"
             @hidden="close">
        <b-container>
            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Title
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="title" class="form-control m-input">
                                <span class="m-form__help"> Please enter event title </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Icon (preferred size: 128x128 and .png transparent background)
                            </label>
                            <div class="col-lg-9">
                                <input type="file" id="icon" name="icon"
                                       v-on:change="handleFile('icon')"/>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Description
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="description"
                                          placeholder="..."></textarea>
                                <span class="m-form__help">Please enter event description </span>
                            </div>
                        </div>
                        
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Link
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="link"
                                       class="form-control m-input">
                                <span class="m-form__help"> Please enter registration url </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyEvent">{{ informationId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import informationApi from '../../api/information';
    import vueDropzone from 'vue2-dropzone';
    import TrailSelect from '../trail/TrailSelect';
    import Settings from '../../constant/settings';
    import VueDatePicker from 'vuejs-datepicker';
    import Moment from 'moment';
    import FileHelper from '../../service/file';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            vueDropzone,
            TrailSelect,
            VueDatePicker,
            Loading
        },
        name: "information-modal",
        props: ['informationId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.informationId,
                title: null,
                description: null,
                link: null,
                created_at: null,
                icon: null,
                fileHelper: FileHelper,
                
            }
        },

        watch: {
            informationId(val) {
                if (val) {
                    informationApi.getInformationDetail(this, val, (context) => {
                        if (context.event) {
                            this.error = false;
                            this.title = context.event.title;
                            this.description = context.event.description;
                            this.link = context.event.link;
                            this.created_at = context.event.created_at;
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyEvent() {
                this.isLoading = true;
                this.error = false;
                let data = {
                        title: this.title,
                        description: this.description,
                        link: this.link,
                    icon: this.icon,
                    }
                ;
                if (this.informationId) {
                    informationApi.updateInformation(this, this.informationId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    informationApi.createInformation(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.message = null;
                this.id = null;
                this.title = null;
                this.description = null;
                this.icon = null;
                this.link = null;
                this.created_at = null;
            },
            close() {
                //this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            },
            handleFile(id) {
                let file = document.querySelector('#' + id);
                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'icon') {
                                this.icon = context.currentFile;
                            } else {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        } else {
                            let file = document.querySelector('#' + id);
                    file.value = null;
                }
                });
                } else {
                    this.icon = null;
                    this.active_icon = null;
                    this.currentFile = null;
                }
            },
        }
    }
</script>

<style scoped>

</style>