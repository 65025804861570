import auth from '../authentication';
import Vue from '../main';

export default {
    getEvent(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/event/get-all', data)
            .then(response => {
                context.error = false;
                context.events = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getEventSelect(context, callback) {
        let data = {};
        if(auth.user.profile.role == 5) {
            data.user_id = auth.user.profile.id;
        }
        Vue.http.post(Vue.apiUrl + '/event/get-select', data)
            .then(response => {
                context.error = false;
                context.events = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getEventDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/event/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createEvent(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/event/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateEvent(context, id, data ,callback) {
        Vue.http.post(Vue.apiUrl + '/event/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.event = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeEvent(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/event/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};