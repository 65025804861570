<template>
    <div class="form-group m-form__group row">
        <label class="col-lg-2 col-form-label">
            Ios Version
        </label>
        <div class="col-lg-9">
            <select title="ios-select" class="form-control" v-model="currentIos" v-if="versions.length > 0">
                <option value="">All Ios Version</option>
                <option v-for="ios in versions" :value="ios.code">
                    {{ ios.version }}
                </option>
            </select>
            <span class="m-form__help">Please choose Ios Version</span>
        </div>
    </div>
</template>

<script>
    import versionApi from '../../api/version';

    export default {
        name: "ios-select",
        data() {
            return {
                currentIos: null,
                page: 0,
                versions: []
            }
        },
        props: ['selectedIos'],
        beforeMount() {
            this.getList();
        },
        watch: {
            selectedIos(val) {
                console.log(val);
                this.currentIos = val;
                this.$emit('selectIos', val);
            },
            currentIos(val) {
                this.$emit('selectIos', val);
            }
        },
        methods: {
            getList() {
                versionApi.getIosSelect(this, (context) => {
                })
            }
        }
    }
</script>

<style scoped>

</style>