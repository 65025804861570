import auth from '../authentication';
import Vue from '../main';

export default {
    getBus(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/bus/get-all', data)
            .then(response => {
                context.error = false;
                context.buss = response.data.data;
                context.total = response.data.total;
                context.message = response.data.message;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                context.total = 0;
                callback(context);
            })
    },
    getBusDetail(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/bus/get-detail/' + id)
            .then(response => {
                context.error = false;
                context.message = response.data.message;
                context.bus = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    getBusSelect(context, callback) {
        Vue.http.post(Vue.apiUrl + '/bus/get-select')
            .then(response => {
                context.error = false;
                context.buss = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.message = response.data.message;
                callback(context);
            })
    },
    createBus(context, data, callback) {
        Vue.http.post(Vue.apiUrl + '/bus/add-new', data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.bus = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    updateBus(context, id, data ,callback) {
        console.log(data);
        Vue.http.post(Vue.apiUrl + '/bus/update-detail/' + id, data)
            .then(response => {
                context.error = false;
                context.success = true;
                context.bus = response.data.data;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                context.message = response.data.message;
                callback(context);
            })
    },
    removeBus(context, id, callback) {
        Vue.http.post(Vue.apiUrl + '/bus/remove/' + id, {})
            .then(response => {
                context.error = false;
                context.success = true;
                callback(context);
            })
            .catch(response => {
                context.error = true;
                context.success = false;
                callback(context);
            })
    }
};