<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newUserModal1" v-model="showModal"
             :title="userId ? 'Password User' : 'Password User'"
             @hidden="close">
        <b-container>

            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Password
                            </label>
                            <div class="col-lg-9">
                                <input type="password" required="required" v-model="password" class="form-control m-input">
                                <span class="m-form__help"> Please enter password </span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Reenter Password
                            </label>
                            <div class="col-lg-9">
                                <input type="password" required="required" v-model="repassword" class="form-control m-input">
                                <span class="m-form__help"> Please enter Re-Password </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyPasswordEvent">{{ userId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import userApi from '../../api/user';
    import vueDropzone from 'vue2-dropzone'
    import TrailSelect from '../trail/TrailSelect'
    import Settings from '../../constant/settings';
    import VueDatePicker from 'vuejs-datepicker';
    import Moment from 'moment';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            vueDropzone,
            TrailSelect,
            VueDatePicker,
            Loading
        },
        name: "user-modal1",
        props: ['userId'],
        data() {
            return {
                fullPage: true,
                isLoading: false,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.userId,
                password: null,
                repassword: null,
                name: null,
                surname: null,
                zipcode: null,
                phone_number: null,
                created_at: null,
                email:null,
                data:null,
                
            }
        },

        watch: {
            userId(val) {
                if (val) {
                    userApi.getUserDetail(this, val, (context) => {
                        if (context.event) {
                            this.error = false;
                            console.log(context.event);
                            this.data = context.event;
                            //this.password = context.event.password;
                            
                            this.email = context.event.email;
                            this.name = context.event.name;
                            this.zipcode = context.event.zipcode;
                            this.surname = context.event.surname;
                            this.phone_number = context.event.phone_number;
                            this.created_at = context.event.created_at;
                            
                        }
                    })
                }else{
                    this.resetData();
                }
            },
        },
        methods: {
            modifyPasswordEvent() {
                
               
                if(this.repassword != this.password){
                    this.error = true;
                    this.$emit('show-create-success-alert');
                    return false;
                }
                this.isLoading = true;
                this.error = false;
                
                this.data.password = this.password
                let data = {
                        password: this.password,
                       
                       
                    }
                ;
                if (this.userId) {
                    userApi.updateUser(this, this.userId, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    userApi.createUser(this, data, (context) => {
                        this.isLoading = false;
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.message = null;
                this.id = null;
                this.name = null;
                this.surname = null;
                this.zipcode = null;
                this.phone_number = null;
                this.created_at = null;
            },
            close() {
                //this.resetData();
                this.showModal = false;
                this.error = false;
                this.$emit('clear-id');
            }
        }
    }
</script>

<style scoped>

</style>